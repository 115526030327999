import { inject, singleton } from "tsyringe";
import { IScraperConfig, ServerSettings } from './settings/server-settings';

@singleton()
export class ScraperConfig {
  public readonly settings: IScraperConfig;

  constructor(@inject(ServerSettings) serverSettings: ServerSettings) {
    this.settings = serverSettings.settings.scraper;
  }
}
