import { isNumber, isObject, isString, isUndefined } from 'lodash';

export enum ServiceErrorType {
  INVALID_PAYLOAD = 'INVALID_PAYLOAD',
  LOGIC_ERROR = 'LOGIC_ERROR',
  INVALID_USER = 'INVALID_USER',
  UNKNOWN = 'UNKNOWN'
}

export class ServiceErrorDTO {
  constructor(public readonly httpStatusCode: number,
              public readonly serviceErrorType: ServiceErrorType,
              public readonly message?: string) {
  }
}

export function isServiceErrorDTO(x: any): x is ServiceErrorDTO {
  const candidate = x as ServiceErrorDTO;
  if (!isObject(candidate)) {
    return false;
  }
  if (!isUndefined(candidate.message)) {
    if (!isString(candidate.message)) {
      return false;
    }
  }
  return isNumber(candidate.httpStatusCode) && isString(candidate.serviceErrorType);
}
