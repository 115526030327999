export enum ConsolePageType {
  LOGIN = 'login',
  LANDING = 'landing',
  CHILD_AUTH_COMPLETE = 'childAuthComplete',
  WAITING_FOR_AUTH = 'waitingForAuth',
  VALUATION = 'valuation',
  PROPERTY_SEARCH = 'propertySearch',
  PROPERTY_DETAIL = 'propertyDetail',
  AREA_SEARCH = 'areaSearch',
  AREA_DETAIL = 'areaDetail',
  FAVORITES = 'favorites'
}
