import { singleton } from 'tsyringe';
import * as sharedBaseConfig from './shared/base.json';
import * as sharedUnitTestConfig from './shared/unit-test.json';
import * as sharedProductionConfig from './shared/prod.json';
import * as sharedDevConfig from './shared/dev.json';

import { logger } from '@logging';
import { merge } from 'lodash';
import { environment, releaseType } from "../environment/environment";
import { ReleaseType } from '@enum';


export interface ISharedConfig {
  requestQueue?: IRequestQueueConfig;
  extensionAPI?: IExtensionAPIConfig;
  consoleAPI?: IConsoleAPIConfig;
  console?: IConsoleConfig;
}

export interface IRequestQueueConfig {
  minimumPendingRequests: number;
  clientRequestBatchSize: number;
  ignoreRecentRequestsAgeM: number;
  fillLocalBufferBatchSize: number;
}

export interface IEndpointRoute {
  name: string;
  prefix: string;
  restricted?: boolean;
}

export interface IExtensionAPIConfig {
  protocol: string;
  host: string;
  port: number;
  routes: IEndpointRoute[];
}

export interface IConsoleEndpointRoute {
  consolePageType: string,
  route: string
}

export interface IConsoleConfig {
  protocol: string;
  host: string;
  port: number;
  routes: IConsoleEndpointRoute[];
}

export interface IConsoleAPIConfig {
  protocol: string;
  host: string;
  port: number;
  cookieAgeS: number;
  routes: IEndpointRoute[];
}






@singleton()
export class SharedSettings {

  readonly settings: ISharedConfig;

  private readonly sharedConfigs = new Map<string, ISharedConfig>([
    [ReleaseType.development, sharedDevConfig],
    [ReleaseType.production, sharedProductionConfig],
    [ReleaseType.unitTest, sharedUnitTestConfig],
  ]);


  constructor() {
    this.settings = this.mergeConfigSettings<ISharedConfig>(
      sharedBaseConfig, this.sharedConfigs, releaseType);
    logger.info(`Using settings for release: ${releaseType} in environment: ${environment}`);
  }

  private mergeConfigSettings<T>(baseConfig:T, environmentToConfig:Map<string, T>, environment: string): T {
    const override = environmentToConfig.get(environment);
    return merge(baseConfig, override);
  }
}
