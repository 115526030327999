import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DimensionRestrictionDTO } from '@dto';
import { SearchFilterCheckboxConfig } from '../search-filter-item.vo';
import { ReplaySubject, Subject, takeUntil, tap } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'jumbo-search-filter-checkbox-item',
  templateUrl: './search-filter-checkbox-item.component.html',
  styleUrls: ['./search-filter-checkbox-item.component.scss']
})
export class SearchFilterCheckboxItemComponent implements AfterViewInit, OnDestroy {

  public availableValues: number[] = [];
  public selectedIndices: boolean[] = [];
  public indexLabels: string[] = [];
  public indices:number[] = [];

  private destroy$: Subject<any> = new Subject<any>();
  private configChange$: Subject<any> = new Subject<any>();
  private loadRestriction$: ReplaySubject<DimensionRestrictionDTO> = new ReplaySubject<DimensionRestrictionDTO>();
  private _config: SearchFilterCheckboxConfig;

  private UNKNOWN = 'X';


  get config(): SearchFilterCheckboxConfig {
    return this._config;
  }

  getValue<T>(arr:T[], idx:number):T {
    return arr[idx];
  }

  @Input()
  set config(value: SearchFilterCheckboxConfig) {
    this.indices = [...Array(value.max-value.min+1).keys()];
    this.configChange$.next(true);
    this._config = value;
    this.changeDetectorRef.detectChanges();
    const selectedIndices: boolean[] = [];
    const indexLabels: string[] = [];
    const availableValues: number[] = [];
    for (let k= value.min; k <= value.max; k++) {
      selectedIndices.push(false);
      indexLabels.push(k.toFixed(0));
      availableValues.push(k);
    }
    indexLabels[indexLabels.length-1]+='+';
    this.selectedIndices = selectedIndices;
    this.indexLabels = indexLabels;
    this.availableValues = availableValues;
    this._config.restrictionToLoad$.pipe(
        takeUntil(this.destroy$),
        takeUntil(this.configChange$)
    ).subscribe(restriction => this.loadRestriction$.next(restriction));
    this.changeDetectorRef.detectChanges();
  }

  @Output()
  public restrictionChange: EventEmitter<DimensionRestrictionDTO> = new EventEmitter<DimensionRestrictionDTO>();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngAfterViewInit(): void {
    this.loadRestriction$.pipe(
        // distinctUntilChanged((a, b) => a.serialisedRestrictionValue === b.serialisedRestrictionValue),
        takeUntil(this.destroy$),
        map(restriction => this.loadRestriction(restriction)),
        tap(() => this.emitNewRestriction())
    ).subscribe();
    this.emitNewRestriction();
  }

  emitNewRestriction() {
    const selectedIndices = this.selectedIndices.map((value, index) => {return {value, index}})
        .filter(item => item.value).map(item => item.index);
    if (selectedIndices.length == 0) {
      this.restrictionChange.next(this.config.buildRestriction([], this.UNKNOWN+'-'+this.UNKNOWN));
      return;
    }
    let minIndex = selectedIndices[0];
    if ((selectedIndices.length > 1) && (minIndex == 0)) {
      minIndex = null;
    }
    let maxIndex = selectedIndices[selectedIndices.length -1];
    if ((selectedIndices.length > 1) && (maxIndex == this.selectedIndices.length-1)) {
      maxIndex = null;
    }
    const value = {
      min: (minIndex===null)?null:this.availableValues[minIndex],
      max: (maxIndex===null)?null:this.availableValues[maxIndex]
    };
    const serialised =
        ((minIndex!==null)?this.availableValues[minIndex]:this.UNKNOWN)
        +'-'
        +((maxIndex!==null)?this.availableValues[maxIndex]:this.UNKNOWN);
    this.restrictionChange.next(this.config.buildRestriction(value, serialised));
    this.loadFromSerialised(serialised);
  }

  private loadRestriction(restriction: DimensionRestrictionDTO) {
    this.loadFromSerialised(restriction.serialisedRestrictionValue);
  }

  private loadFromSerialised(serialised: string) {
    const values = serialised.split('-');
    const min = values[0]==this.UNKNOWN?NaN:parseInt(values[0]);
    const max = values[1]==this.UNKNOWN?NaN:parseInt(values[1]);

    const selectedIndices: boolean[] = [];
    this.availableValues.forEach((value, idx) => {
      let selected = true;
      if (!isNaN(min) && (value < min)) {
        selected = false;
      }
      if (!isNaN(max) && (value > max)) {
        selected = false;
      }
      selectedIndices[idx] = selected;
    });
    if (isNaN(min) && isNaN(max)) {
      this.availableValues.forEach((value, idx) => {
        selectedIndices[idx] = false;
      });
    }
    this.selectedIndices = selectedIndices;
    this.changeDetectorRef.detectChanges();
  }

  changeSelected(index: number) {
    this.selectedIndices[index] = !this.selectedIndices[index];
    this.emitNewRestriction();
  }
}
