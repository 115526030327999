import { DimensionRestrictionDTO, DimensionRestrictionType } from '@dto';
import { DimensionNames } from '@enum';
import { ReplaySubject } from 'rxjs';

export class SearchFilterItemVO {
  get id(): string {
    return this.config.id;
  }
  constructor(public readonly config: ISearchFilterItemConfig,) {
  }
}

type FilterType = 'dropDown' | 'dropDownNumericRange' | 'numericSlider' | 'numericSliderRange' | 'text' | 'radio' | 'checkbox';

export interface ISearchFilterItemConfig {
  readonly filterType: FilterType;
  readonly dimensionName: DimensionNames;
  readonly restrictionType:DimensionRestrictionType;
  readonly id: string;
  readonly label: string;
  canRepresent(item: DimensionRestrictionDTO): boolean;
  loadFrom(item: DimensionRestrictionDTO);
}

abstract class SearchFilterItemConfig implements ISearchFilterItemConfig{
  get id(): string {
    return this.label+ `_`+this.dimensionName+`_`+this.restrictionType+`_`+this.filterType;
  }
  public restrictionToLoad$:ReplaySubject<DimensionRestrictionDTO> = new ReplaySubject<DimensionRestrictionDTO>(1);

  constructor(public readonly dimensionName: DimensionNames,
              public readonly restrictionType:DimensionRestrictionType,
              public readonly filterType: FilterType,
              public readonly label: string) {
  }

  loadFrom(item: DimensionRestrictionDTO) {
    this.restrictionToLoad$.next(item);
  }

  canRepresent(item: DimensionRestrictionDTO): boolean {
    return (item.restrictionType === this.restrictionType) && (item.dimensionName === this.dimensionName);
  }

  buildRestriction(value: any, serialisedValue: string): DimensionRestrictionDTO {
    return new DimensionRestrictionDTO(
        this.dimensionName,
        this.restrictionType,
        value, serialisedValue);
  }
}

export class SearchFilterDropDownOption<T> {
  constructor(public readonly itemLabel: string,
              public readonly itemValue: T) {
  }
}

export class SearchFilterDropDownConfig extends SearchFilterItemConfig  {
  constructor(label: string,
              dimensionName: DimensionNames,
              restrictionType:DimensionRestrictionType,
              public readonly options:SearchFilterDropDownOption<any>[],
              public readonly defaultSelectedIndex = 0) {
    super(dimensionName, restrictionType, 'dropDown', label);
  }
}

export class SearchFilterDropDownNumericRangeConfig extends SearchFilterItemConfig  {
  constructor(label: string,
              dimensionName: DimensionNames,
              restrictionType:DimensionRestrictionType,
              public readonly options:SearchFilterDropDownOption<number>[],
              public readonly minimumLabel: string,
              public readonly maximumLabel: string) {
    super(dimensionName, restrictionType, 'dropDownNumericRange', label);
  }
}

export class SearchFilterNumericSliderRangeConfig extends SearchFilterItemConfig {
  constructor(label: string,
              dimensionName: DimensionNames,
              restrictionType:DimensionRestrictionType,
              public readonly maxValue,
              public readonly minValue,
              public readonly formatFunction:(value:number)=>string = undefined) {
    super(dimensionName, restrictionType, 'numericSliderRange', label);
  }

  override canRepresent(item: DimensionRestrictionDTO): boolean {
    return (['range', 'eq'].indexOf(item.restrictionType) > -1) && (item.dimensionName === this.dimensionName);
  }

}

export class SearchFilterCheckboxConfig extends SearchFilterItemConfig  {
  constructor(label: string,
              dimensionName: DimensionNames,
              restrictionType:DimensionRestrictionType,
              public readonly min:number,
              public readonly max:number) {
    super(dimensionName, restrictionType, 'checkbox', label);
  }
}

export class SearchFilterNumericSliderConfig extends SearchFilterItemConfig {
  constructor(label: string,
              dimensionName: DimensionNames,
              restrictionType:DimensionRestrictionType,
              public readonly maxValue,
              public readonly defaultValue,
              public readonly stepSize = 1,
              public readonly minValue = 0,
              public readonly formatFunction:(value:number)=>string = undefined) {
    super(dimensionName, restrictionType, 'numericSlider', label);
  }
}

export class SearchFilterTextConfig extends SearchFilterItemConfig {
  constructor(label: string,
              public readonly prompt: string,
              public readonly dimensionName: DimensionNames,
              public readonly restrictionType:DimensionRestrictionType) {
    super(dimensionName, restrictionType, 'text', label);
  }
}

export class SearchFilterRadioOption {
  constructor(public readonly itemLabel: string,
              public readonly itemValue: any) {
  }
}

export class SearchFilterRadioConfig extends SearchFilterItemConfig {
  constructor(label: string,
              dimensionName: DimensionNames,
              restrictionType:DimensionRestrictionType,
              public readonly options:SearchFilterRadioOption[],
              public readonly defaultSelectedIndex = 0) {
    super(dimensionName, restrictionType, 'radio', label);
  }
}
