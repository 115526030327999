import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { DimensionRestrictionDTO, FilterDTO } from "@dto";
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  ReplaySubject,
  Subject,
  takeUntil,
  tap,
  withLatestFrom
} from "rxjs";
import { map } from 'rxjs/operators';
import { SearchFilterItemVO } from './search-filter-item/search-filter-item.vo';
import { logger } from '@logging';

@Component({
  selector: "jumbo-search-filter",
  templateUrl: "./search-filter.component.html",
  styleUrls: ["./search-filter.component.scss"]
})
export class SearchFilterComponent implements OnInit, OnDestroy {


  @Output()
  public filter: EventEmitter<FilterDTO> = new EventEmitter<FilterDTO>();

  @Output()
  public description$: BehaviorSubject<string> = new BehaviorSubject<string>("loading...");

  @Input()
  public title = '';


  @Input()
  public set loadFilter(value:FilterDTO) {
    this.loadFilterSignal$.next(value);
  }

  @Input()
  public set urlObject(value: any) {
    this.urlObject$.next(value);
  }

  @Input()
  set filterItems(value: SearchFilterItemVO[]) {
    logger.info(`Setting filterOptions`);
    this.filterToRestriction = new Map<string, DimensionRestrictionDTO>();
    this.activeItems$.next(value);
    logger.info(`Setting filterOptions complete`);
  }

  public activeItems$: ReplaySubject<SearchFilterItemVO[]> = new ReplaySubject<SearchFilterItemVO[]>(1);

  private loadFilterSignal$:ReplaySubject<FilterDTO> = new ReplaySubject<FilterDTO>(1);
  private restrictionChangeSignal$: Subject<any> = new Subject<any>();

  private destroy$: Subject<any> = new Subject<any>();
  private urlObject$: Subject<any> = new Subject<any>();
  private optionsSortOrder: Map<SearchFilterItemVO, number>;
  private filterToRestriction: Map<string, DimensionRestrictionDTO> = new Map<string, DimensionRestrictionDTO>();
  private isLoadingFilter = false;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  sortCompareFilterItems(a: SearchFilterItemVO, b: SearchFilterItemVO): -1 | 0 | 1 {
    const aOrder = this.optionsSortOrder.get(a);
    const bOrder = this.optionsSortOrder.get(b);
    if (aOrder < bOrder) {
      return -1;
    } else if (aOrder > bOrder) {
      return 1;
    }
    return 0;
  }

  ngOnInit(): void {
    this.loadFilterSignal$.pipe(
        withLatestFrom(this.activeItems$),
        map(([filter, items]) => {
          this.loadFilterInternal(filter, items)
        }),
        takeUntil(this.destroy$)
    ).subscribe();

    combineLatest([this.activeItems$, this.restrictionChangeSignal$])
        .pipe(
            map(([activeItems, _]) => activeItems),
            tap((activeItems) => logger.info(`Active items now: ${activeItems.map(item => item.id).join(`, `)}`)),
            map(activeItems => {return {restrictions: activeItems.map(activeItem => this.filterToRestriction.get(activeItem.id)), activeItems}}),
            map(({restrictions, activeItems}) => restrictions.filter(restriction => !!restriction)),
            tap((restrictions) => {
              logger.info(`restrictions now: ${restrictions.map(restriction => restriction.dimensionName).join(`, `)}`);
              this.description$.next(this.buildDescription(restrictions));
            }),
            map((restrictions) => new FilterDTO(restrictions)),
            tap((filter) => this.filter.next(filter)),
            takeUntil(this.destroy$)
        ).subscribe();

    this.urlObject$.pipe(
        distinctUntilChanged(),
        map(obj => {
          //TODO: remove used items
          this.activeItems$.next(obj.filterItems);
          // obj.restriction
        })
    )
  }

  private buildDescription(restrictions?: DimensionRestrictionDTO[]): string {
    if (!restrictions || restrictions.length == 0) {
      return ''
    }
    return DimensionRestrictionDTO.HumanReadable(restrictions);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  handleRestrictionChange(restriction: DimensionRestrictionDTO, item: SearchFilterItemVO) {
    logger.info(`Setting item ${item.id} to ${DimensionRestrictionDTO.Serialise(restriction)}`);
    this.filterToRestriction.set(item.id, restriction);
    if (!this.isLoadingFilter) {
      this.restrictionChangeSignal$.next(true);
    }
  }

  private loadFilterInternal(filter: FilterDTO, items: SearchFilterItemVO[]) {
    if (!filter) {
      return;
    }
    this.isLoadingFilter = true;
    logger.info(`search-filter.loadFilterInternal start`);
    logger.addPrefix(`search-filter.loadFilterInternal`);

    filter.restrictions.forEach(restriction => {
      const item = items.find(candidate => candidate.config.canRepresent(restriction));
      if (!item) {
        logger.error(`No config can parse represent restriction: ${JSON.stringify(restriction)}`);
        return;
      }
      item.config.loadFrom(restriction);
    })

    logger.popPrefix();
    logger.info(`search-filter.loadFilterInternal end`);

    this.isLoadingFilter = false;
    this.restrictionChangeSignal$.next(true);
  }

}
