import { IConsoleConfig, SharedSettings } from './settings/shared-settings';
import { inject, singleton } from 'tsyringe';
import { ConsolePageType } from '../enum/console-page-type';


@singleton()
export class ConsoleConfig {

  private consoleSettings: IConsoleConfig;
  private pageToRoute:Map<ConsolePageType, string> = new Map<ConsolePageType, string>();

  public getBaseUri(): string {
    let result = `${this.consoleSettings.protocol}://${this.consoleSettings.host}`;
    if (this.consoleSettings.port) {
      result += `:${this.consoleSettings.port}`;
    }
    return result;
  }

  public getURIForPage(page: ConsolePageType) {
    return `${this.getBaseUri()}/${this.getPathForPage(page)}`;
  }

  public getPathForPage(page: ConsolePageType): string {
    const result = this.pageToRoute.get(page);
    if (!result) {
      throw new Error(`Path not defined for page ${page}`);
    }
    return result;
  }

  constructor(@inject(SharedSettings) configSettings: SharedSettings) {
    if (!configSettings.settings.console) {
      throw new Error(`No console settings`);
    }
    this.consoleSettings = configSettings.settings.console;
    this.consoleSettings.routes.forEach((route) => {
      this.pageToRoute.set(ConsolePageType[route.consolePageType], route.route);
    })
  }
}
