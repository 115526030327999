import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { PropertyService } from '../../services/property.service';
import { SaleSearchItemDTO, SearchDTO, SearchResultDTO } from '@dto';
import { BehaviorSubject, combineLatest, from, Observable, shareReplay, Subject, takeUntil, tap } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss'],
  providers: [PropertyService]
})
export class FavoritesComponent implements OnInit, AfterViewInit, OnDestroy {

  public page$: Observable<SearchResultDTO<SaleSearchItemDTO>>;
  public results$: Observable<SaleSearchItemDTO[]>;
  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private pageIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private pageSize$: BehaviorSubject<number> = new BehaviorSubject<number>(10);
  private destroy$: Subject<any> = new Subject<any>();

  constructor(private readonly propertyService: PropertyService) {
  }

  ngAfterViewInit(): void {
    this.page$ = combineLatest([this.pageIndex$, this.pageSize$]).pipe(
        map(([pageIndex, pageSize]) => new SearchDTO<any>(pageIndex, pageSize, {})),
        tap(() => {
          this.isLoading.next(true);
        }),
        switchMap(search => from(this.propertyService.getFavorites(search))),
        tap(() => {
          this.isLoading.next(false);
        }),
        takeUntil(this.destroy$),
        shareReplay(1)
    );

    this.results$ = this.page$.pipe(
        map(page => page.results),
        takeUntil(this.destroy$)
    );
    this.results$.subscribe();
  }


  ngOnInit(): void {
    this.isLoading.next(false);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  pageEvent(event: PageEvent) {
    this.pageSize$.next(event.pageSize);
    this.pageIndex$.next(event.pageIndex);
  }
}
