import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren
} from "@angular/core";
import { BehaviorSubject, combineLatest, Observable, shareReplay, Subject, takeUntil } from "rxjs";
import { PropertyService } from "../../services/property.service";
import { map, switchMap } from "rxjs/operators";
import {
  EstateAgentListingReportDTO,
  EstateAgentPropertyReportRequestDTO,
  PropertyMetadataDTO,
  SaleListingDTO,
  SaleSearchItemDTO,
  SearchResultDTO
} from "@dto";
import { ActivatedRoute } from "@angular/router";
import { AppService } from "../../services/app.service";
import { AreaService } from "../../services/area.service";
import { AreaReportVO } from "../widgets/property-detail/area-report.vo";
import { ImageItem } from 'ng-gallery';
import humanFormat from 'human-format';
import { AgentService } from '../../services/agent.service';

// import * as mapboxgl from 'mapbox-gl';


@Component({
  selector: "app-property-detail",
  templateUrl: "./property-detail.component.html",
  styleUrls: ["./property-detail.component.scss"]
})
export class PropertyDetailComponent implements OnDestroy {

  readonly HEADER_SELECTED_CLASS = 'highlighted-header';
  private destroyed$: Subject<boolean> = new Subject<boolean>();
  public saleListing$: Observable<SaleListingDTO>;
  public areaReport$: Observable<AreaReportVO>;
  public agentReport$: Observable<EstateAgentListingReportDTO>;
  public title$: Observable<string>;
  public images$: Observable<ImageItem[]>;
  public scrolledPastTitle$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public refreshSimilarProperties$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private selectedIndex: number;
  similarProperties$: Observable<SearchResultDTO<SaleSearchItemDTO>>;

  /** Get handle on cmp tags in the template */
  @ViewChildren("section") sections: QueryList<ElementRef>;
  @ViewChild("pageTitle") pageTitle: ElementRef;
  @ViewChildren("header") sectionHeaders: QueryList<ElementRef>;
  @ViewChild("stickyHeader") stickyHeader: ElementRef;


  constructor(public readonly appService: AppService,
              private readonly propertyService: PropertyService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly areaService: AreaService,
              private readonly agentService: AgentService,
              private renderer: Renderer2) {
    this.saleListing$ = activatedRoute.params.pipe(
        map(params => params.id),
        switchMap(id => this.propertyService.load(id)),
        shareReplay(1),
        takeUntil(this.destroyed$)
    );
    this.images$ = this.saleListing$.pipe(
        map(item => item.images.map((i) => new ImageItem({src: i.url, thumb: i.url}))),
        takeUntil(this.destroyed$)
    );

    this.similarProperties$ = combineLatest([this.saleListing$, this.refreshSimilarProperties$]).pipe(
        map(([prop, signal]) => prop.id),
        switchMap(id => this.propertyService.getSimilar(id)),
        shareReplay(1),
        takeUntil(this.destroyed$)
    );
    this.areaReport$ = this.saleListing$.pipe(
        switchMap(item => this.areaService.getAreaReport(
            item.geo, new PropertyMetadataDTO(item.bedrooms, item.propertySubType as any))),
        shareReplay(1),
        takeUntil(this.destroyed$)
    );
    this.agentReport$ = this.saleListing$.pipe(
        takeUntil(this.destroyed$),
        map(item => new EstateAgentPropertyReportRequestDTO(item.id)),
        switchMap(search => this.agentService.getAgentReportFromPropertyId(search)),
        shareReplay(1)
    );
    this.title$ = combineLatest([this.areaReport$, this.saleListing$]).pipe(
        map(([areaReport, item]) => this.getTitle(item, areaReport))
    );
  }

  // @HostListener('scroll', ['$event']) // for scroll events of the current element
  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(event): void {
    if (!this.stickyHeader) {
      return;
    }
    const {height: headerHeight} = this.getDimensions(this.stickyHeader);
    const scrollPosition = window.scrollY + headerHeight;

    const selected = this.sections.find((section, idx) => {
      const ele = section.nativeElement;
      if (ele) {
        const {offsetBottom, offsetTop} = this.getDimensions(section);
        return scrollPosition >= offsetTop && scrollPosition < offsetBottom;
      }
    });
    const newSelectedIndex = this.sections.toArray().indexOf(selected);
    if (newSelectedIndex !== this.selectedIndex) {
      // logger.info(`switching to new idx: ${newSelectedIndex}`);
      const previousSelection = this.sectionHeaders.get(this.selectedIndex);
      const newSelection = this.sectionHeaders.get(newSelectedIndex);
      if (previousSelection) {
        this.renderer.removeClass(previousSelection.nativeElement, this.HEADER_SELECTED_CLASS);
      }
      if (newSelection) {
        this.renderer.addClass(newSelection.nativeElement, this.HEADER_SELECTED_CLASS);
      }
      this.selectedIndex = newSelectedIndex;
    }
    const {offsetTop: headerPosition} = this.getDimensions(this.pageTitle);
    const scrolledPastTitle = scrollPosition >= headerPosition;
    if (this.scrolledPastTitle$.value !== scrolledPastTitle) {
      this.scrolledPastTitle$.next(scrolledPastTitle);
    }
  }

  scrollToSection(idx: number) {
    const {height: headerHeight} = this.getDimensions(this.stickyHeader);
    const {offsetTop: targetTop} = this.getDimensions(this.sections.get(idx));
    window.scrollTo({top: targetTop - headerHeight, behavior: 'smooth'});
  }

  private getDimensions(element: ElementRef) {
    const ele = element.nativeElement;
    const {height} = ele.getBoundingClientRect();
    const offsetTop = ele.offsetTop;
    const offsetBottom = offsetTop + height;

    return {
      height,
      offsetTop,
      offsetBottom,
    };
  }


  public getPrice(value: number): string {
    return humanFormat(Number(value.toFixed(0)));
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  // TODO: use area-search-map.component.ts rebuildMap to build an area map with the property result shown

  private getTitle(item: SaleListingDTO, areaReport: AreaReportVO) {
    let result = ``;
    if (item.bedrooms > 0) {
      result += `${item.bedrooms} bed `;
    }
    result += item.propertySubType;
    if (areaReport && areaReport.postCodeReport) {
      result += ` in ${areaReport.postCodeReport.areaName}`;
    }
    return result;
  }

}
