import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DimensionRestrictionDTO } from '@dto';
import { SearchFilterNumericSliderRangeConfig } from '../search-filter-item.vo';
import { ReplaySubject, Subject, takeUntil, tap } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'jumbo-search-filter-numeric-range-slider-item',
  templateUrl: './search-filter-numeric-range-slider-item.component.html',
  styleUrls: ['./search-filter-numeric-range-slider-item.component.scss']
})
export class SearchFilterNumericRangeSliderItemComponent implements AfterViewInit, OnDestroy {

  private _min: number = 0;
  private _max: number = 0;

  get min(): number {
    return this._min;
  }

  set min(value: number) {
    this._min = value;
  }

  get max(): number {
    return this._max;
  }

  set max(value: number) {
    this._max = value;
  }

  private destroy$: Subject<any> = new Subject<any>();
  private configChange$: Subject<any> = new Subject<any>();
  private loadRestriction$: ReplaySubject<DimensionRestrictionDTO> = new ReplaySubject<DimensionRestrictionDTO>();
  private _config: SearchFilterNumericSliderRangeConfig;

  get config(): SearchFilterNumericSliderRangeConfig {
    return this._config;
  }

  @Input()
  set config(value: SearchFilterNumericSliderRangeConfig) {
    this.configChange$.next(true);
    this._config = value;
    this.changeDetectorRef.detectChanges();
    this._config.restrictionToLoad$.pipe(
        takeUntil(this.destroy$),
        takeUntil(this.configChange$)
    ).subscribe(restriction => this.loadRestriction$.next(restriction))
  }

  @Output()
  public restrictionChange: EventEmitter<DimensionRestrictionDTO> = new EventEmitter<DimensionRestrictionDTO>();

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  commitValue(): void {
    this.emitNewRestriction()
  }

  ngAfterViewInit(): void {
    this.loadRestriction$.pipe(
        takeUntil(this.destroy$),
        map(restriction => this.loadRestriction(restriction)),
        tap(() => this.emitNewRestriction())
    ).subscribe();
    this.emitNewRestriction();
  }

  emitNewRestriction() {
    const value = {min: this.min, max: this.max};
    const serialised = (value.min) + '-' + (value.max);
    this.restrictionChange.next(this.config.buildRestriction(value, serialised));
  }

  private loadRestriction(restriction: DimensionRestrictionDTO) {
    if (restriction.restrictionType == 'range') {
      const serialised = restriction.serialisedRestrictionValue;
      const values = serialised.split('-');
      this._min = ((values[0] == 'X') ? -1 : parseInt(values[0]));
      this._max = ((values[1] == 'X') ? -1 : parseInt(values[1]));
    } else if (restriction.restrictionType == 'eq') {
      const value = parseInt(restriction.serialisedRestrictionValue);
      this._min = value;
      this._max = value;
    }
    this.changeDetectorRef.detectChanges();
  }

}
