import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { AreaSearchListDTO, AreaSearchListItemDTO, DimensionRestrictionDTO, FilterDTO, LocationDTO } from "@dto";
import { AreaService } from "../../services/area.service";
import { MatSort, Sort } from "@angular/material/sort";
import { map } from "rxjs/operators";
import { isNumber, isString } from "lodash";
import { ConfigurationService } from "../../services/configuration.service";
import { Router } from "@angular/router";
import { ConsolePageType, LocationType } from '@enum';

type columnNames = (keyof AreaSearchListItemDTO)|'viewProperties';

@Component({
  selector: "jumbo-area-search-list",
  templateUrl: "./area-search-list.component.html",
  styleUrls: ["./area-search-list.component.scss"]
})
export class AreaSearchListComponent implements OnInit {

  @Input()
  set reportFilter(value: FilterDTO) {
    this._reportFilter = value;
    this.search().then();
  }


  private _reportFilter: FilterDTO = new FilterDTO([]);

  @Input()
  public set filterDescription(value: string) {
    this.filterDescription$.next(value);
  }

  @ViewChild(MatSort) sort: MatSort;

  public filterDescription$: BehaviorSubject<string> = new BehaviorSubject<string>("loading...");

  public rawRows$: BehaviorSubject<AreaSearchListItemDTO[]> = new BehaviorSubject<AreaSearchListItemDTO[]>([]);
  public rows$: Observable<AreaSearchListItemDTO[]>;
  public activeSort$: BehaviorSubject<Sort|null> = new BehaviorSubject<Sort | null>(null);
  public displayedColumns$: Observable<columnNames[]> = new BehaviorSubject([
    'postCode', 'areaName', 'region', 'yieldFraction', 'saleCount', 'letCount', 'timeToSell', 'viewProperties']);

  constructor(private readonly areaService: AreaService,
              private readonly configService: ConfigurationService,
              private readonly router: Router) {
    this.rows$ = combineLatest([this.rawRows$, this.activeSort$]).pipe(
      map(([rows, activeSort]) => {
        if (!activeSort) {
          return rows;
        }
        const orderMultiplier = activeSort.direction === 'asc'?1:-1;
        return rows.sort((a, b) => {
          const aValue = a[activeSort.active];
          const bValue = b[activeSort.active];
          if (aValue === null && bValue === null) {
            return 0;
          }
          if (aValue === null) {
            return 1;
          }
          if (bValue === null) {
            return -1;
          }
          if (isNumber(aValue)) {
            return (aValue>bValue?-1:1)*orderMultiplier;
          } else if (isString(aValue)) {
            if (aValue == bValue) {
              return 0;
            }
            return (aValue.localeCompare(bValue) > 0?1:-1)* orderMultiplier;
          }
          return 0;
        });
      })
    );
  }

  ngOnInit(): void {
  }

  getPercent(fraction: number) {
    if (fraction === null) {
      return '';
    }
    return (Math.round(1000 * fraction) * 0.1).toFixed(1) + '%';
  }

  round(float: number) {
    return Math.round(float);
  }

  announceSortChange(sortState: Sort) {
    this.activeSort$.next(sortState);
  }

  private async search() {
    const result = await this.areaService.loadAreaSearchList(new AreaSearchListDTO(this._reportFilter));
    this.rawRows$.next(result.areaItems);
  }

  viewProperties(row:AreaSearchListItemDTO) {


    const location = new LocationDTO(row.areaName, row.region,
        LocationType.AREA_CODE, row.postCode);

    const path = this.configService.getClientPath(ConsolePageType.PROPERTY_SEARCH);
    const locationRestriction = new DimensionRestrictionDTO('location', 'like', location, LocationDTO.Serialise(location));
    const restrictions = this._reportFilter.restrictions.concat([locationRestriction])
    const filter = FilterDTO.Serialise(new FilterDTO(restrictions));
    this.router.navigate([path], {queryParams:{filter, queryParamsHandling: ''}});
  }
}
