<div class="flex-row flex w-full px-3">
  <mat-slider [min]="config.minValue"
              [max]="config.maxValue"
              [step]="config.stepSize"
              [displayWith]="format"
              class="slider w-full pl-0 pr-0"
              [discrete]="true"
              name="slider"
              (change)="commitValue()">
    <input matSliderThumb [(ngModel)]="value"
           class="pl-0 pr-0 ml-3">
  </mat-slider>
</div>
