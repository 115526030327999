import { inject, singleton } from 'tsyringe';
import { IEndpointRoute, IExtensionAPIConfig, IRequestQueueConfig, SharedSettings } from './settings/shared-settings';
import { ExtensionApiName } from '@enum';
import { logger } from '@logging';

@singleton()
export class ExtensionApiConfig {

  public readonly extensionApiSettings: IExtensionAPIConfig;
  public readonly requestQueueSettings: IRequestQueueConfig;
  private readonly routeByName: Map<string, IEndpointRoute>;

  public getBaseUri(): string {
    return `${this.extensionApiSettings.protocol}://${this.extensionApiSettings.host}:${this.extensionApiSettings.port}`;
  }

  public getUrl(endpointName: ExtensionApiName): string {
    const route = this.routeByName.get(ExtensionApiName[endpointName]);
    if (!route) {
      throw new Error(`Endpoint for ${endpointName} is not configured`);
    }
    return this.getBaseUri()+route.prefix;
  }

  constructor(@inject(SharedSettings) configSettings: SharedSettings) {
    this.extensionApiSettings = configSettings.settings.extensionAPI;
    this.requestQueueSettings = configSettings.settings.requestQueue;
    this.routeByName = new Map(this.extensionApiSettings.routes.map(route => [route.name, route]));
    for (const name in ExtensionApiName) {
      if (!this.routeByName.has(name)) {
        logger.error(`Route for ${name} is not configured and may lead to runtime errors`);
      }
    }

  }
}
