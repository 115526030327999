<div class="flex-col flex">

  <!-- agent name -->
  <div class="flex-col">
    <p>Estate Agent: {{agentReport.agentName}} ({{agentReport.branchName}})</p>
    <!--<a href="{{agentHyperlink}}" target="_blank">Visit website</a>-->
  </div>

  <div class="flex-row flex gap-3">

    <!-- specialisation -->
    <div class="flex-col h-[12rem] w-[12rem]">
      <p>Specialisation</p>
      <jumbo-gauge-chart [chartData]="specialisationData$|async"></jumbo-gauge-chart>
    </div>

    <!-- let vs rental -->

    <div class="flex-col items-center">
      <p>Total Properties</p>
      <p>{{agentReport.currentListingsCount}}</p>
    </div>
    <div class="flex-col items-center">
      <p>Similar Properties</p>
      <p>{{salePrice}}</p>
    </div>

  </div>

</div>


