import { inject, singleton } from 'tsyringe';
import { IDirectScraperCommanderConfig, ServerSettings } from './settings/server-settings';

@singleton()
export class DirectScraperCommanderConfig {

  public readonly settings: IDirectScraperCommanderConfig;

  constructor(@inject(ServerSettings) serverSettings: ServerSettings) {
    this.settings = serverSettings.settings.directScraperCommander;
  }
}
