import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { isServiceErrorDTO } from '@dto';
import * as Sentry from '@sentry/angular';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
        filter(event => {
          if (!(event instanceof HttpResponse)) {
            return true;
          }
          const body = event.body;
          if (!isServiceErrorDTO(body)) {
            return true;
          }
          const error = `Service error code ${body.httpStatusCode} with type ${body.serviceErrorType}: ${body.message}`;
          alert(error);
          Sentry.captureMessage(error);
          return false;
        })
    );
  }
}
