<div class="flex flex-col">
  <h2>Property Details</h2>
  <div class="flex flex-row">

    <div class="flex-row flex gap-3">

      <div class="flex-col items-center h-[12rem] w-[12rem]">
        <h2>Views</h2>
        <h2>{{(saleListing$|async).viewedCount}}</h2>
      </div>

      <div class="flex-col items-center h-[12rem] w-[12rem]">
        <h2>Likes</h2>
        <h2>{{(saleListing$|async).likedCount}}</h2>
      </div>

      <div class="flex-col items-center h-[12rem] w-[12rem]">
        <h2>Bedrooms</h2>
        <h2>{{(saleListing$|async).bedrooms}}</h2>
      </div>

      <div class="flex-col items-center h-[12rem] w-[12rem]">
        <h2>Description</h2>
        <p>{{(saleListing$|async).description}}</p>
      </div>

      <div class="flex-col items-center h-[12rem] w-[12rem]">
        <h2>Address</h2>
        <p>{{(saleListing$|async).address}}</p>
      </div>

    </div>
  </div>
</div>
