import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DimensionRestrictionDTO } from '@dto';
import { SearchFilterNumericSliderConfig } from '../search-filter-item.vo';
import { ReplaySubject, Subject, takeUntil, tap } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'jumbo-search-filter-numeric-slider-item',
  templateUrl: './search-filter-numeric-slider-item.component.html',
  styleUrls: ['./search-filter-numeric-slider-item.component.scss']
})
export class SearchFilterNumericSliderItemComponent implements AfterViewInit, OnDestroy {
  get value(): number {
    return this._value;
  }

  set value(value: number) {
    this._value = value;
  }

  private destroy$: Subject<any> = new Subject<any>();
  private configChange$: Subject<any> = new Subject<any>();
  private loadRestriction$: ReplaySubject<DimensionRestrictionDTO> = new ReplaySubject<DimensionRestrictionDTO>();
  private _config: SearchFilterNumericSliderConfig;

  get config(): SearchFilterNumericSliderConfig {
    return this._config;
  }

  @Input()
  set config(value: SearchFilterNumericSliderConfig) {
    this.configChange$.next(true);
    this._config = value;
    this._value = value.defaultValue;
    this.changeDetectorRef.detectChanges();
    this._config.restrictionToLoad$.pipe(
        takeUntil(this.destroy$),
        takeUntil(this.configChange$)
    ).subscribe(restriction =>this.loadRestriction$.next(restriction))
  }

  @Output()
  public restrictionChange: EventEmitter<DimensionRestrictionDTO> = new EventEmitter<DimensionRestrictionDTO>();

  private _value: number = 0;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  format(value:number): string {
    if (this.config && this.config.formatFunction) {
      return this.config.formatFunction(value);
    }
    return value.toString(10);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  commitValue(): void {
    this.restrictionChange.next(this.config.buildRestriction(this._value, this._value.toString()));
  }

  ngAfterViewInit(): void {
    this.loadRestriction$.pipe(
        takeUntil(this.destroy$),
        map(restriction => this.loadRestriction(restriction)),
        tap(() => this.emitNewRestriction())
    ).subscribe();
    this.emitNewRestriction();
  }

  emitNewRestriction() {
    this.restrictionChange.next( this.config.buildRestriction(this._value, this._value.toString()) );
  }

  private loadRestriction(restriction: DimensionRestrictionDTO) {
    this._value = parseFloat(restriction.serialisedRestrictionValue);
    this.changeDetectorRef.detectChanges();
  }

}
