import { Component, OnDestroy, OnInit } from '@angular/core';
import { ValuationService } from '../../services/valuation.service';
import { ListingType, PropertySubType } from '@enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CandidateEstateAgentReportDTO,
  EstateAgentGeoReportRequestDTO,
  EstateAgentReportDTO,
  isServiceErrorDTO,
  PredictionRequestDTO,
  PredictionResponseDTO,
  PropertyMetadataDTO,
  SaleSearchItemDTO,
  SearchResultDTO,
  SimilarPropertiesRequestDTO
} from '@dto';
import { AppService } from '../../services/app.service';
import { AreaService } from '../../services/area.service';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, shareReplay, Subject, takeUntil, tap } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AreaReportVO } from '../widgets/property-detail/area-report.vo';
import { annualRentToMonthlyHumanReadable, centerPoint, fractionToPercent, humanReadableSalePrice } from '@utils';
import { AgentService } from '../../services/agent.service';
import { isString } from 'lodash';
import { PropertyService } from '../../services/property.service';

const enum ValuationViewStateEnum {
  IDLE = 'idle',
  LOADING = 'loading',
  RESULT = 'result',
  ERROR = 'error'
}

type ValuationViewState =
    ValuationViewStateEnum.IDLE
    | ValuationViewStateEnum.LOADING
    | ValuationViewStateEnum.RESULT
    | ValuationViewStateEnum.ERROR;

@Component({
  selector: 'app-valuation',
  templateUrl: './valuation.component.html',
  styleUrls: ['./valuation.component.scss'],
  providers: [ValuationService]
})
export class ValuationComponent implements OnInit, OnDestroy {

  public readonly buy: ListingType = ListingType.BUY;
  public readonly rent: ListingType = ListingType.RENT;
  protected readonly humanReadableSalePrice = humanReadableSalePrice;
  protected readonly annualRentToMonthlyHumanReadable = annualRentToMonthlyHumanReadable;
  protected readonly fractionToPercent = fractionToPercent;

  public propertySubTypeOptions = [
    PropertySubType.HOUSE,
    PropertySubType.FLAT,
    // PropertySubType.APARTMENT,
    // PropertySubType.STUDIO,
    // PropertySubType.COMMERCIAL_PROPERTY,
  ]

  public serviceError: string | null = null;

  valuationForm = new FormGroup({
    postCode: new FormControl('',  [
        Validators.required,
        Validators.minLength(5),
    ]),
    bedrooms: new FormControl(3, [
        Validators.required,
    ]),
    propertySubType: new FormControl(PropertySubType.FLAT, [
        Validators.required,
    ]),
    bathrooms: new FormControl(1, [
        Validators.pattern('^[0-9]*$'),
    ]),
    squareFt: new FormControl(null, [
      Validators.pattern('^[0-9]*$'),
    ]),
    isStudent: new FormControl(false)
  });

  private destroyed$: Subject<boolean> = new Subject<boolean>();
  private predictionRequest$ = new ReplaySubject<PredictionRequestDTO>(1);
  private isLoading$ = new BehaviorSubject<boolean>(false);
  protected error$ = new BehaviorSubject<string | null>(null);
  protected areaReport$: Observable<AreaReportVO>;
  protected predictionResult$: Observable<PredictionResponseDTO | null>;
  protected agentReportLoading$ = new BehaviorSubject<boolean>(false);
  protected agentReports$ = new BehaviorSubject<CandidateEstateAgentReportDTO | null>(null);
  protected viewState$: Observable<ValuationViewState>;
  protected selectedAgentReport$ = new BehaviorSubject<EstateAgentReportDTO | null>(null);
  protected description$: Observable<string>;
  protected similarProperties$:Observable<SearchResultDTO<SaleSearchItemDTO>>;

  constructor(private readonly valuationService: ValuationService,
              private readonly propertyService: PropertyService,
              public readonly appService: AppService,
              public readonly areaService: AreaService,
              public readonly agentService: AgentService) {
    this.predictionResult$ = this.predictionRequest$.pipe(
        takeUntil(this.destroyed$),
        tap(() => this.isLoading$.next(true)),
        tap(() => this.error$.next(null)),
        tap(() => this.selectedAgentReport$.next(null)),
        tap(() => this.agentReports$.next(null)),
        switchMap(req => this.valuationService.getPrediction(req).catch(e => {
          if (e.error && isServiceErrorDTO(e.error)) {
            this.error$.next(e.error.message);
          } else if (isString(e.message)) {
            this.error$.next(e.message);
          } else {
            this.error$.next('Unknown error');
          }
          return null;
        })),
        tap(() => this.isLoading$.next(false)),
        shareReplay(1)
    );
    this.areaReport$ = this.predictionRequest$.pipe(
        takeUntil(this.destroyed$),
        switchMap(req => this.areaService.getAreaReportByPostCode(
            req.postCode, new PropertyMetadataDTO(req.bedrooms, req.propertySubType))),
        shareReplay(1)
    );
    combineLatest([this.predictionResult$, this.predictionRequest$, this.areaReport$]).pipe(
        takeUntil(this.destroyed$),
        tap(() => this.agentReportLoading$.next(true)),
        map(([prediction, req, area]) => new EstateAgentGeoReportRequestDTO(
            centerPoint(area.postCodeReport.geoJson),
            new PropertyMetadataDTO(req.bedrooms, req.propertySubType),
        )),
        switchMap(req => this.agentService.getAgentReportFromGeo(req)),
        tap(() => this.agentReportLoading$.next(false)),
        tap((agentReports) => {
          this.agentReports$.next(agentReports);
        }),
        shareReplay(1),
    ).subscribe();
    this.description$ = combineLatest([this.predictionRequest$, this.predictionResult$]).pipe(
        takeUntil(this.destroyed$),
        map(([req, prediction]) => {
          return `A ${req.bedrooms} bedroom ${req.propertySubType} in ${req.postCode}`;
        })
    );
    this.viewState$ = combineLatest([this.isLoading$, this.error$, this.predictionResult$]).pipe(
        takeUntil(this.destroyed$),
        map(([loading, error, prediction]) => {
          if (loading) {
            return ValuationViewStateEnum.LOADING;
          } else if (error) {
            return ValuationViewStateEnum.ERROR;
          } else if (prediction) {
            return ValuationViewStateEnum.RESULT;
          }
          return ValuationViewStateEnum.IDLE;
        }),
        shareReplay(1)
    );

    this.similarProperties$ = combineLatest([this.predictionRequest$, this.predictionResult$]).pipe(
        takeUntil(this.destroyed$),
        map(([req, prediction]) => new SimilarPropertiesRequestDTO(
            prediction.geo, new PropertyMetadataDTO(req.bedrooms, req.propertySubType), prediction.sellPrice)),
        switchMap(req => this.propertyService.getSimilarByMetadata(req)),
        shareReplay(1)
    );

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  async makePrediction(): Promise<void> {
    const req = new PredictionRequestDTO(
        this.valuationForm.value.postCode,
        this.valuationForm.value.propertySubType,
        this.valuationForm.value.bedrooms,
        this.valuationForm.value.bathrooms ? this.valuationForm.value.bathrooms : 0,
        this.valuationForm.value.squareFt ? parseInt(this.valuationForm.value.squareFt, 10) : -1,
        this.valuationForm.value.isStudent);
    this.predictionRequest$.next(req);
  }

  selectedAgentReportChange(e: any) {
    this.selectedAgentReport$.next(e.source.value);
  }

}
