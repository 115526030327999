<div *ngIf="(saleListing$|async)" class="flex flex-col p-5 flex-wrap w-full h-full gap-0 content-start w-[72rem]">


  <div class="h-[40rem] top-section flex-col">

    <h2 #pageTitle>{{title$|async}}</h2>
    <div class="flex-row flex gap-5 w-[72rem]">
      <gallery [items]="images$|async" class="h-[36rem] min-w-[46rem] grow"
               [thumbHeight]="45" [thumbWidth]="60"
               imageSize="cover" [loop]="true"></gallery>
      <div class="flex-col flex gap-5">
        <div class="text-5xl">
          &pound;{{getPrice((saleListing$|async).price)}}
        </div>
        <app-property-label *ngFor="let label of (saleListing$|async).labels"
                            [label]="label" [detailed]="true"></app-property-label>
        <div>Share</div>
      </div>
    </div>
  </div>

  <div class="shrink-0 h-[5rem]">
    <!-- spacer -->
  </div>

  <div #stickyHeader class="flex flex-row gap-0 sticky top-0 header-background">
    <div *ngIf="scrolledPastTitle$|async"
         class="pt-2 text-lg">{{title$|async}} &pound;{{getPrice((saleListing$|async).price)}}</div>
    <div class="grow"></div>
    <div #header class="cursor-pointer header" (click)="scrollToSection(0)">Property Details</div>
    <div #header class="cursor-pointer header" (click)="scrollToSection(1)">Area Information</div>
    <div #header class="cursor-pointer header" (click)="scrollToSection(2)">Agent Metrics</div>
    <div #header class="cursor-pointer header" (click)="scrollToSection(3)">Investment Insights</div>
  </div>

  <!-- property details -->
  <div #section class="section">
    <jumbo-property-information *ngIf="(saleListing$|async) as saleListing"
                                [saleListing]="saleListing"></jumbo-property-information>
  </div>

  <!-- area details -->
  <div #section class="section">
    <jumbo-area-report *ngIf="(areaReport$|async) as areaReport"
                       [areaReport]="areaReport"
                       [salePrice]="(saleListing$|async).price"></jumbo-area-report>
    <div *ngIf="!(areaReport$|async)">
      Loading area report...
    </div>
  </div>
  <!-- map from areaReport.postCodeReport.geoJson -->
  <!--    <div class="mapbox-container z-0" #mapboxContainer id="mapboxContainer">-->

  <div #section class="section">
    <div *ngIf="agentReport$|async as agentReport">
      <jumbo-estate-agent-report [agentReport]="agentReport.agent"></jumbo-estate-agent-report>
    </div>
    <div *ngIf="!(agentReport$|async)">
      <h2>Loading Estate Agent</h2>
    </div>
  </div>

  <div #section class="section" *ngIf="saleListing$|async">
    <jumbo-investment-report [saleListing]="saleListing$|async"></jumbo-investment-report>
  </div>

  <div class="section p-5 gap-5">
    <h2>Similar Properties</h2>
    <div class="flex flex-row gap-10 shrink pt-5 w-full justify-between"
         *ngIf="(similarProperties$|async) && (similarProperties$|async).results">
      <div *ngFor="let item of (similarProperties$|async).results" class="flex">
        <app-property-search-card [item]="item"></app-property-search-card>
      </div>
    </div>
    <button mat-button (click)="refreshSimilarProperties$.next(true)">
      <span class="material-icons">refresh</span>Load more
    </button>
  </div>


</div>

<div *ngIf="!(saleListing$|async)">
  <p>No item loaded</p>
</div>
