import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DimensionRestrictionDTO, LocationDTO, LocationSearchDTO } from "@dto";
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  from,
  merge,
  Observable,
  ReplaySubject,
  startWith,
  Subject,
  takeUntil,
  tap
} from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { LocationService } from '../../../../services/location.service';
import { SearchFilterTextConfig } from '../search-filter-item.vo';

type SearchIdAndStatus = { id: string, isSearching: boolean };

@Component({
  selector: 'jumbo-search-filter-text-item',
  templateUrl: './search-filter-text-item.component.html',
  styleUrls: ['./search-filter-text-item.component.scss']
})
export class SearchFilterTextItemComponent implements AfterViewInit, OnDestroy {

  private _config: SearchFilterTextConfig;

  @Input()
  set config(value: SearchFilterTextConfig) {
    this.configChange$.next(true);
    this._config = value;
    this._config.restrictionToLoad$.pipe(
        takeUntil(this.destroy$),
        takeUntil(this.configChange$)
    ).subscribe(restriction => this.loadRestriction$.next(restriction))
  }

  get config(): SearchFilterTextConfig {
    return this._config;
  }

  @Output()
  public restrictionChange: EventEmitter<DimensionRestrictionDTO> = new EventEmitter<DimensionRestrictionDTO>();

  public searchResults$: Observable<LocationDTO[]>;
  public inputSelection: string;

  private isSearchingSignal$: Subject<SearchIdAndStatus> = new Subject<SearchIdAndStatus>();
  public isSearching$: Observable<boolean>;

  private searchInput$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private clearSearchSignal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loadRestriction$: ReplaySubject<DimensionRestrictionDTO> = new ReplaySubject<DimensionRestrictionDTO>();
  private destroy$: Subject<any> = new Subject<any>();
  private configChange$: Subject<any> = new Subject<any>();

  constructor(private readonly locationService: LocationService, private readonly changeDetectorRef :ChangeDetectorRef) {
    const currentlySearching: Map<string, boolean> = new Map<string, boolean>();
    this.isSearching$ = this.isSearchingSignal$.pipe(
        tap(item => {
          if (item.isSearching) {
            currentlySearching.set(item.id, true);
          } else {
            currentlySearching.delete(item.id);
          }
        }),
        map(() => currentlySearching.size > 0),
        distinctUntilChanged()
    );
    const localResults$ = this.searchInput$.pipe(
        startWith(''),
        tap((text) => this.isSearchingSignal$.next({id: text, isSearching: true})),
        filter(text => text !== ''),
        debounceTime(100),
        map(text => new LocationSearchDTO(text)),
        switchMap(search => from(this.locationService.loadLocations(search)).pipe(
                map(result => {
                      return {text: search.search, result}
                    }
                )
            )
        ),
        tap(({text, result}) => this.isSearchingSignal$.next({id: text, isSearching: false})),
        map(result => result.result.towns)
    );

    const noResults$ = this.clearSearchSignal$.pipe(
        map(() => [] as LocationDTO[])
    );

    this.searchResults$ = merge(localResults$, noResults$);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngAfterViewInit(): void {
    this.loadRestriction$.pipe(
        takeUntil(this.destroy$),
        map(restriction => this.loadRestriction(restriction))
    ).subscribe();
  }

  handleInputChange(e: Event) {
    this.searchInput$.next((e.target as any).value);
  }

  emitNewRestriction(option: LocationDTO) {
    if (option) {
      this.restrictionChange.next(this._config.buildRestriction(option, LocationDTO.Serialise(option)));
    } else {
      this.restrictionChange.next(null);
    }
  }

  private loadRestriction(restriction: DimensionRestrictionDTO) {
    const value = LocationDTO.Parse(restriction.serialisedRestrictionValue);
    if (!value) {
      this.inputSelection = '';
      return;
    }
    this.inputSelection = value.name;
    this.changeDetectorRef.detectChanges();
    this.emitNewRestriction(value);
  }

  public onClear() {
    this.inputSelection = '';
    this.emitNewRestriction(null);
  }
}
