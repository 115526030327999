import { AfterViewInit, Component, Input, OnDestroy } from "@angular/core";
import * as mapboxgl from "mapbox-gl";
import { Point } from "mapbox-gl";
import interpolate from "color-interpolate";
import {
  AreaSearchMapDTO,
  AreaSearchMapResultDTO,
  DimensionRestrictionDTO,
  FilterDTO,
  GeoBoundsDTO,
  LocationDTO,
  PostCodeResult
} from "@dto";
import { AreaService } from "../../services/area.service";
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  from,
  map,
  Observable,
  ReplaySubject,
  Subject,
  switchMap,
  takeUntil,
  tap
} from "rxjs";
import capitalize from "capitalize";
import { AppService } from "../../services/app.service";
import { logger } from "@logging";
import { Router } from "@angular/router";
import { ConfigurationService } from "../../services/configuration.service";
import { ConsolePageType, getPostCodeReportTypeValueFormatFunction, PostCodeReportType } from "@enum";
import * as Sentry from "@sentry/angular";
import { filter, take } from "rxjs/operators";
import {
  LegacyTextOnlySnackBar as TextOnlySnackBar,
  MatLegacySnackBarRef as MatSnackBarRef
} from "@angular/material/legacy-snack-bar";
import { MatSnackBar } from '@angular/material/snack-bar';

type ReportTypeOption = { value: PostCodeReportType, label: string, legendUnit: string };

@Component({
  selector: "jumbo-area-search-map",
  templateUrl: "./area-search-map.component.html",
  styleUrls: ["./area-search-map.component.scss"]
})
export class AreaSearchMapComponent implements AfterViewInit, OnDestroy {
  get reportType(): PostCodeReportType {
    return this._reportType;
  }

  set reportType(value: PostCodeReportType) {
    this._reportType = value;
    this.selectedPostCodeReportType$.next(value);
  }

  //TODO: initialise the view to something nice:
  //If @ryanbaumann map.fitBounds approach is not appropriate for your use case, you can use the excellent viewport-mercator-project. It contains a fitBounds method which returns center and zoom. These can then be used to initialize your map. It also contains handy padding and offset options.
  //https://github.com/mapbox/mapbox-gl-js/issues/1970
  public reportTypeOptions: ReportTypeOption[] = [
    {value: "yield", label: "Yield %", legendUnit: "Yield"},
    {value: "sale_price", label: "Let price", legendUnit: "Let Price"},
    {value: "let_price", label: "Sale price", legendUnit: "Sale Price"},
    {value: "time_to_sell", label: "Time to sell (days)", legendUnit: "Days"}
  ];
  private isFirstClear = true;
  private activeSnackBar: MatSnackBarRef<TextOnlySnackBar>;

  private _reportType: PostCodeReportType = "yield";

  @Input()
  public set reportFilter(value: FilterDTO) {
    this.reportFilter$.next(value);
  }

  public selectedPostCodeReportType$: Subject<PostCodeReportType> = new Subject<PostCodeReportType>();
  public legendLabel$: Observable<string>;

  @Input()
  public set filterDescription(value: string) {
    this.filterDescription$.next(value);
  }

  public humanReadableSearch$: Observable<string>;
  private readonly destroyed$: Subject<boolean> = new Subject<boolean>();

  public gradientBackground;
  public mapBoxLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading$: Observable<boolean>;
  public readonly isDark$: Observable<boolean>;
  public activeArea: PostCodeResult;
  public rootArea: PostCodeResult;
  public filterDescription$: BehaviorSubject<string> = new BehaviorSubject<string>("loading...");
  private reportFilter$: ReplaySubject<FilterDTO> = new ReplaySubject<FilterDTO>(1);

  public min = "...";
  public max = "...";

  private areas = new Map<string, PostCodeResult>();
  private colourPalette = [
    "#e0c696",
    "#949494",
    "#aaccff"
  ];

  private map: mapboxgl.Map;

  private readonly mapAdjustedSignal: Subject<any> = new Subject<any>();

  constructor(private readonly areaService: AreaService,
              private readonly applicationService: AppService,
              private readonly router: Router,
              private readonly configService: ConfigurationService,
              private readonly snackBar: MatSnackBar) {
    this.isDark$ = applicationService.isDarkMode$;

    this.gradientBackground = `linear-gradient(0deg, ${this.colourPalette[0]} 0%, ${this.colourPalette[1]} 35%, ${this.colourPalette[2]} 100%)`;
    this.humanReadableSearch$ = this.filterDescription$;
    const loadingFromServerSignal: Subject<boolean> = new Subject<boolean>();

    this.isLoading$ = combineLatest([loadingFromServerSignal, this.mapBoxLoaded$]).pipe(
        map(([loadingFromServer, mapBoxLoaded]) => loadingFromServer || (!mapBoxLoaded)),
        distinctUntilChanged(),
        tap(() => {

        })
    );

    const report$ = combineLatest([this.mapAdjustedSignal, this.reportFilter$]).pipe(
        map(([mapAdjusted, reportFilter]) => {
          return this.getSearch(reportFilter);
        }),
        filter(search => search !== null),
        debounceTime(500),
        tap(() => loadingFromServerSignal.next(true)),
        switchMap(search => from(this.areaService.loadAreaSearchMap(search))),
        tap(() => loadingFromServerSignal.next(false))
    );

    combineLatest([report$, this.selectedPostCodeReportType$, this.mapBoxLoaded$]).pipe(
        filter(([report, selectedReportType, mapBoxLoaded]) => mapBoxLoaded),
        map(([report, selectedReportType]) => this.rebuildMap(report, selectedReportType)),
        takeUntil(this.destroyed$)
    ).subscribe();

    this.legendLabel$ = this.selectedPostCodeReportType$.pipe(
        map(reportTypeValue => this.reportTypeOptions.find(candidate => candidate.value === reportTypeValue).legendUnit)
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    this.setupMapBox();
    this.onAdjust();
    this.selectedPostCodeReportType$.next(this.reportTypeOptions[0].value);
  }

  onAdjust() {
    this.mapAdjustedSignal.next(true);
  }

  private getSearch(reportFilter: FilterDTO): AreaSearchMapDTO | null {
    const container = document.getElementById("mapboxContainer");
    if (!container) {
      return null;
    }
    const geos = [
      {x: 0, y: 0},
      {x: container.offsetWidth, y: container.offsetHeight},
      {x: 0, y: container.offsetHeight},
      {x: container.offsetHeight, y: 0}
    ].map(pt => this.map.unproject(new Point(pt.x, pt.y)));
    const bounds = new GeoBoundsDTO(
        Math.min(...geos.map(pt => pt.lat)),
        Math.min(...geos.map(pt => pt.lng)),
        Math.max(...geos.map(pt => pt.lat)),
        Math.max(...geos.map(pt => pt.lng)));
    return new AreaSearchMapDTO(bounds, reportFilter);
  }



  rebuildMap(report: AreaSearchMapResultDTO, reportType: PostCodeReportType) {
    const formatFunction = getPostCodeReportTypeValueFormatFunction(reportType);

    const minValue = report.minValues[reportType];
    const maxValue = report.maxValues[reportType];

    this.rootArea = report.rootResult;
    this.clearSources();
    this.min = formatFunction(minValue);
    this.max = formatFunction(maxValue);
    const colorMap = interpolate(this.colourPalette);

    this.areas = new Map<string, PostCodeResult>();
    report.postCodes.forEach(area => {
      this.areas.set(area.postCode, area);
    });
    const features = report.postCodes.map(area => {
      const areaValue = area.values[reportType];
      const range = maxValue - minValue;
      const progress = (areaValue - minValue) / range;
      const data = area.geoJson as any;
      data.properties["heat"] = isNaN(progress) ? "#000000" : colorMap(progress);
      data.properties["text"] = `${data.properties.name}`;
      // data.properties["text"] = `${data.properties.name}\n${area.saleCount} / ${area.letCount}`;
      return data;
    });
    const heatmapDataSource = {
      type: "FeatureCollection",
      features
    } as any;

    // https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#geojson
    this.map.addSource("heatmap", {type: "geojson", data: heatmapDataSource, tolerance: 0.8});
    this.map.addLayer({
      "id": `heatmap-fill`,
      "type": "fill",
      "source": "heatmap", // reference the data source
      "layout": {},
      "paint": {
        "fill-color": ["get", "heat"],
        "fill-opacity": 0.7
      }
    });

    this.map.addLayer({
      "id": `heatmap-text`,
      "type": "symbol",
      "source": "heatmap",
      "layout": {
        "text-field": ["get", "text"],
        "text-font": [
          "Open Sans Semibold",
          "Arial Unicode MS Bold"
        ]
      }
    });

    this.map.on("click", `heatmap-text`, (event) => {
      if (event.features && event.features.length > 0) {
        const name = event.features[0].properties.name;
        logger.info(`Layer ${event} clicked`);
        this.activeArea = this.areas.get(name);
      }
    });

    this.map.on("mouseenter", `heatmap-text`, () => {
      this.map.getCanvas().style.cursor = "pointer";
    });

    this.map.on("mouseleave", `heatmap-text`, () => {
      this.map.getCanvas().style.cursor = "";
    });
  }

  navigateToProperties(location: LocationDTO) {
    const path = this.configService.getClientPath(ConsolePageType.PROPERTY_SEARCH);
    const locationRestriction = new DimensionRestrictionDTO('location', 'like', location, LocationDTO.Serialise(location));
    this.reportFilter$.pipe(
        map((filter) => filter.restrictions.concat([locationRestriction])) ,
        map(restrictions => FilterDTO.Serialise(new FilterDTO(restrictions))),
        take(1),
    ).subscribe(filter => {
      this.router.navigate([path], {queryParams:{filter, queryParamsHandling: ''}});
    });
  }

  clearSources() {
    if (this.isFirstClear) {
      this.isFirstClear = false;
      return;
    }
    try {
      this.map.removeLayer("heatmap-fill");
      this.map.removeLayer("heatmap-text");
      this.map.removeSource("heatmap");
    } catch (e) {
      logger.error(e);
      Sentry.captureMessage(e);
    }
  }

  closeAreaSearch() {
    this.activeArea = null;
  }

  capitalCase(str: String): String {
    return capitalize.words(str);
  }

  private setupMapBox() {
    const bounds:[[number, number], [number, number]] = [
      [-11.693055, 49.198129],
      [2.515823, 61.259073]
    ];
    // @ts-ignore
    mapboxgl.accessToken = "pk.eyJ1IjoiZXN0YXRlc3RhdHMiLCJhIjoiY2w1aXIwZmltMGF0MTNjcGF5MzFoMmhndyJ9.iz2fXPvrKDBakoFvfzs_Aw";
    this.map = new mapboxgl.Map({
      container: "mapboxContainer",
      style: "mapbox://styles/estatestats/cl5iqvxcb008f14o1lx5fas5z",
      bounds,
      maxBounds: bounds,
      attributionControl: false
    });

    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disableRotation();

    this.mapBoxLoaded$.next(false);
    this.map.on("load", () => {
      this.mapBoxLoaded$.next(true);
    });

    // console.log('map loading');
    // this.map.on('click', (e) => this.onMapClick(e));
    this.map.on("zoomend", () => this.onAdjust());
    this.map.on("boxzoomend", () => this.onAdjust());
    this.map.on("dragend", () => this.onAdjust());
    this.map.on("rotateend", () => this.onAdjust());
    this.map.on("zoomend", () => this.onAdjust());
    this.map.on("pitchend", () => this.onAdjust());
    this.map.on("moveend", () => this.onAdjust());
  }

}
