<div class="flex flex-col rounded drop-shadow-xl p-3"
     [class]="getBackgroundClass()|async">
  <div class="text-xl">{{vo.title}}</div>
  <div class="text-xs">{{vo.subtitle}}</div>
  <div class="pt-3" *ngFor="let valueChart of vo.valueCharts">

    <jumbo-value-chart [vo]="valueChart"></jumbo-value-chart>

  </div>
</div>
