import { inject, singleton } from 'tsyringe';
import { ModelApiEndpoints } from '../enum/model-api-endpoints';
import { IModelAPI, ServerSettings } from './settings/server-settings';
import { IEndpointRoute } from './settings/shared-settings';

@singleton()
export class ModelApiConfig {
  private readonly settings: IModelAPI;
  private readonly routeByName: Map<string, IEndpointRoute>;

  public getBaseUri(): string {
    return `${this.settings.protocol}://${this.settings.host}`;
  }

  getHost(): string {
    return this.settings.host;
  }

  public getUrl(endpointName: ModelApiEndpoints): string {
    const route = this.routeByName.get(ModelApiEndpoints[endpointName]);
    if (!route) {
      throw new Error(`Endpoint for ${endpointName} is not configured`);
    }
    return this.getBaseUri()+route.prefix;
  }


  constructor(@inject(ServerSettings) serverSettings: ServerSettings) {
    this.settings = serverSettings.settings.modelAPI;
    this.routeByName = new Map(this.settings.routes.map(route => [route.name, route]));
  }

}
