export enum ConsoleApiEndpoints {
  root = 'root',
  auth_google = 'auth_google',
  auth_google_callback = 'auth_google_callback',
  auth_status = 'auth_status',
  auth_release = 'auth_release',
  property = 'property',
  property_search = 'property_search',
  property_update_favorite = 'property_update_favorite',
  property_favorites = 'property_favorites',
  property_similar = 'property_similar',
  property_similar_metadata = 'property_similar_metadata',
  hot_properties = 'hot_properties',
  property_favorites_count = 'property_favorites_count',
  area = 'area',
  area_search_map = 'area_search_map',
  area_search_by_post_code = 'area_search_by_post_code',
  area_search_list = 'area_search_list',
  predict = 'predict',
  location_search = 'location_search',
  estate_agents_by_geo = 'estate_agents_by_geo',
  estate_agent_report = 'estate_agent_report',
}
