import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, EMPTY, Observable, of } from 'rxjs';
import { isAuthStatusDTO } from '@dto';
import { AccessStatusType, AuthStatusType, ConsoleApiEndpoints } from '@enum';
import { ConfigurationService } from './configuration.service';
import { logger } from '@logging';
import { ActivatedRoute, Router } from '@angular/router';
import { loginFromConfig } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private readonly ignoredPaths: string[];

  constructor(private readonly config: ConfigurationService,
              private readonly router: Router,
              private readonly route: ActivatedRoute) {
    this.ignoredPaths = [
      ConsoleApiEndpoints.auth_google,
      ConsoleApiEndpoints.auth_release,
      ConsoleApiEndpoints.auth_google_callback,
      ConsoleApiEndpoints.auth_status,
    ].map(x => config.getAPIURL(x));
  }

  handleError(err: HttpErrorResponse, originalRequest: HttpRequest<any>): Observable<any> {
    if (this.ignoredPaths.indexOf(originalRequest.url) > -1) {
      logger.debug(`Auth interceptor ignoring path ${originalRequest.url}`);
      return of(null);
    }
    if (err.status === 401 || err.status === 403) {
      const body = err.error;
      if (body) {
        if (isAuthStatusDTO(body)) {
          if (body.accessStatus && (body.accessStatus === AccessStatusType.INSUFFICIENT_RIGHTS)) {
            alert(`You do not have permission to access this resource.`);
          } else if (body.authStatus === AuthStatusType.UNAUTHENTICATED) {
            alert(`You must be logged in to access this resource.`);
            loginFromConfig(this.config, this.router, this.router.url);
          }
        }
      }
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return of(EMPTY); // or EMPTY may be appropriate here
    }
    throw err;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(e => this.handleError(e, req)));
  }

}
