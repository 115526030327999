import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DimensionRestrictionDTO } from '@dto';
import { SearchFilterDropDownNumericRangeConfig, SearchFilterDropDownOption } from '../search-filter-item.vo';
import { ReplaySubject, Subject, takeUntil, tap } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'jumbo-search-filter-drop-down-numeric-range-item',
  templateUrl: './search-filter-drop-down-numeric-range-item.component.html',
  styleUrls: ['./search-filter-drop-down-numeric-range-item.component.scss']
})
export class SearchFilterDropDownNumericRangeItemComponent implements AfterViewInit, OnDestroy {

  private destroy$: Subject<any> = new Subject<any>();
  private configChange$: Subject<any> = new Subject<any>();
  private loadRestriction$: ReplaySubject<DimensionRestrictionDTO> = new ReplaySubject<DimensionRestrictionDTO>();

  get config(): SearchFilterDropDownNumericRangeConfig {
    return this._config;
  }

  @Input()
  set config(value: SearchFilterDropDownNumericRangeConfig) {
    this.configChange$.next(true);
    this._config = value;
    this.minItems = this.buildMinItems(value);
    this.maxItems = this.buildMaxItems(value);
    this.changeDetectorRef.detectChanges();
    this.selectedMin = this.minItems[0].itemValue;
    this.selectedMax = this.maxItems[this.maxItems.length - 1].itemValue;
    this._config.restrictionToLoad$.pipe(
        takeUntil(this.destroy$),
        takeUntil(this.configChange$)
    ).subscribe(restriction => this.loadRestriction$.next(restriction))
  }

  private _config: SearchFilterDropDownNumericRangeConfig;

  @Output()
  public restrictionChange: EventEmitter<DimensionRestrictionDTO> = new EventEmitter<DimensionRestrictionDTO>();

  public selectedMin: any;
  public selectedMax: any;

  public minItems: SearchFilterDropDownOption<Number>[];
  public maxItems: SearchFilterDropDownOption<Number>[];

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  buildMinItems(config: SearchFilterDropDownNumericRangeConfig): SearchFilterDropDownOption<Number>[] {
    const minItem = new SearchFilterDropDownOption(config.minimumLabel, -1);
    return [minItem].concat(config.options);
  }

  buildMaxItems(config: SearchFilterDropDownNumericRangeConfig): SearchFilterDropDownOption<Number>[] {
    const maxItem = new SearchFilterDropDownOption(config.maximumLabel, -1);
    return config.options.concat(maxItem);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  ngAfterViewInit(): void {
    this.loadRestriction$.pipe(
        takeUntil(this.destroy$),
        map(restriction => this.loadRestriction(restriction)),
        tap(() => this.emitNewRestriction())
    ).subscribe();
    this.emitNewRestriction();
  }

  minSelectionChange() {
    if ((this.selectedMax !== -1) && (this.selectedMax <= this.selectedMin)) {
      this.selectedMax = -1;
    }
    this.emitNewRestriction();
  }

  maxSelectionChange() {
    if ((this.selectedMin!==-1) && (this.selectedMin >= this.selectedMax)) {
      this.selectedMin = -1;
    }
    this.emitNewRestriction();
  }

  emitNewRestriction() {
    const value = {
      min: (this.selectedMin==-1)?null:this.selectedMin,
      max: (this.selectedMax==-1)?null:this.selectedMax};
    const serialised = ((this.selectedMin==-1)?'X':value.min)+'-'+((this.selectedMax==-1)?'X':value.max);
    this.restrictionChange.next( this.config.buildRestriction(value, serialised) );
  }

  private loadRestriction(restriction: DimensionRestrictionDTO) {
    const serialised = restriction.serialisedRestrictionValue
    const values = serialised.split('-');
    this.selectedMin = ((values[0] == 'X') ? -1 : parseInt(values[0]));
    this.selectedMax = ((values[1] == 'X') ? -1 : parseInt(values[1]));
    this.changeDetectorRef.detectChanges();
  }
}
