import { inject, singleton } from 'tsyringe';
import { SharedSettings } from './settings/shared-settings';
import { ExtensionApiConfig } from './extension-api-config';
import { ExtensionApiName } from '@enum';

@singleton()
export class ExtensionConfig {

  public getEndpointUrl(endpointName: ExtensionApiName): string {
    return this.extensionApiConfig.getUrl(endpointName);
  }

  constructor(@inject(SharedSettings) configSettings: SharedSettings,
              @inject(ExtensionApiConfig) private readonly extensionApiConfig: ExtensionApiConfig) {
  }
}
