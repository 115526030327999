<div class="relative h-[16rem] w-[14rem] select-none">

  <div class="absolute rounded top-0 left-0 right-0 bottom-0"
       [class]="(appService.isDarkMode$|async)?'background-dark':'background-light'">
  </div>

  <div class="absolute gap-2 flex top-0 left-0 right-0 bottom-0 flex-col cursor-pointer pointer-events-auto"
       (click)="goToProperty(item)">

    <!-- image -->
    <img class="rounded object-cover h-[9rem] w-[14rem] grow-0 shrink-0"
         src="{{item.imageUrl}}" alt="property image"/>

    <div class="grow"></div>

    <!-- text -->
    <div class="flex-col flex w-full gap-1 px-3">
      <span class="pb-1">{{item.bedrooms}} bed {{item.propertySubType}}</span>
      <div>{{item.areaName}} / {{item.areaRegion}}</div>
    </div>

    <!-- values -->
    <div class="flex-row flex px-3 pb-3">
      <div class="text-2xl"
           [class]="highlightSalePrice(item)?((appService.isDarkMode$|async)?'accent-dark':'accent-light'):''">{{getExpectedSaleValue(item)}}</div>
      <div class="grow"></div>
      <div class="text-2xl"
           [class]="highlightYield(item)?((appService.isDarkMode$|async)?'accent-dark':'accent-light'):''">{{getYield(item)}}
        %
      </div>
    </div>

  </div>


  <!-- overlay -->
  <div class="absolute top-0 right-0 bottom-0 left-0 flex-row rounded overlay select-none"
       [ngSwitch]="isFav">

    <div *ngSwitchCase="'PENDING'" class="absolute top-0 right-0 bottom-0 left-0">
      <div class="absolute top-2 right-2 heart-icon-pending material-icons"
            (click)="toggleFavorite()">favorite</div>
    </div>

    <!-- is favorite -->
    <div *ngSwitchCase="'FAVORITE'" class="absolute top-0 right-0 bottom-0 left-0">
      <span class="absolute top-2 right-2 heart-icon material-icons cursor-pointer pointer-events-auto"
            (click)="toggleFavorite()">favorite</span>
    </div>

    <!-- not yet favorite -->
    <div *ngSwitchCase="'NOT_FAVORITE'" class="not-heart absolute top-0 right-0 h-[7rem] w-[7rem] rounded overflow-hidden pointer-events-auto" >
      <div class="triangle h-[6rem] w-[6rem] absolute overflow-hidden">
        <div class="triangle-sheen-1 absolute top-0 right-0 bottom-0 left-0"></div>
        <div class="triangle-sheen-2 absolute top-0 right-0 bottom-0 left-0"></div>
      </div>
      <span class="absolute not-heart-icon material-symbols-outlined top-2 right-2 cursor-pointer"
      (click)="toggleFavorite()">favorite</span>
    </div>
  </div>
</div>
