import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService, loginFromConfig } from '../services/auth.service';
import { logger } from '@logging';
import { ConfigurationService } from '../services/configuration.service';
import { firstValueFrom } from 'rxjs';
import { AuthStatusType } from '@enum';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private readonly authService: AuthService,
              private readonly router: Router,
              private readonly config: ConfigurationService,
              public dialog: MatDialog) {
  }


  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean>  {
    const authStatus = await firstValueFrom(this.authService.authStatus$);
    const isAuthenticated = authStatus && (authStatus.authStatus === AuthStatusType.AUTHENTICATED);
    logger.debug(`Auth guard. Guard status authenticated: ${isAuthenticated}`);
    if (!isAuthenticated) {
      loginFromConfig(this.config, this.router, this.router.url);
      this.authService.login(route.url.join(''));
      return false;
    }
    return true;
  }

}
