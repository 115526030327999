import { Component, Input, OnDestroy } from '@angular/core';
import { AreaReportVO, EMPTY_AREA_REPORT } from '../widgets/property-detail/area-report.vo';
import { humanReadableSalePrice } from '@utils';
import { combineLatest, Observable, ReplaySubject, Subject, takeUntil } from 'rxjs';
import { GaugeChartData } from '../charts/gague-chart/gauge-chart.component';
import { map } from 'rxjs/operators';
import { MultiGaugeChartData } from '../charts/multi-gague-chart/multi-gauge-chart.component';

@Component({
  selector: 'jumbo-area-report',
  templateUrl: './area-report.component.html',
  styleUrls: ['./area-report.component.scss']
})
export class AreaReportComponent implements OnDestroy {

  private salePrice$: ReplaySubject<number> = new ReplaySubject<number>(1);

  private destroyed$: Subject<boolean> = new Subject<boolean>();
  protected readonly EMPTY_AREA_REPORT = EMPTY_AREA_REPORT;
  protected areaReport$: ReplaySubject<AreaReportVO> = new ReplaySubject<AreaReportVO>(1);
  protected letDemandChartData$: Observable<GaugeChartData>;
  protected regionalYieldChartData$: Observable<GaugeChartData>;
  protected isReportValid$: Observable<boolean>;
  protected priceChartData$: Observable<MultiGaugeChartData>;

  @Input() set areaReport(value: AreaReportVO) {
    this.areaReport$.next(value);
  }

  @Input() set salePrice(value: number) {
    this.salePrice$.next(value);
  }

  constructor() {
    this.letDemandChartData$ = this.areaReport$.pipe(
        takeUntil(this.destroyed$),
        map(value => this.buildLetDemandChartData(value))
    );
    this.regionalYieldChartData$ = this.areaReport$.pipe(
        takeUntil(this.destroyed$),
        map(value => this.buildRegionalRelativeYieldChartData(value))
    );
    this.priceChartData$ = combineLatest([this.salePrice$, this.areaReport$]).pipe(
        takeUntil(this.destroyed$),
        map(([salePrice, areaReport]) => this.buildPriceChartData(salePrice, areaReport))
    );
    this.isReportValid$ = this.areaReport$.pipe(
        takeUntil(this.destroyed$),
        map(value => value && (value !== EMPTY_AREA_REPORT))
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  buildLetDemandChartData(report: AreaReportVO): GaugeChartData {
    const localSupply = report.postCodeReport.values.sale_count;
    const localDemand = report.postCodeReport.values.let_count;

    const nationalSupply = report.nationalReport.values.sale_count;
    const nationalDemand = report.nationalReport.values.let_count;

    const relativeSupply = localSupply / nationalSupply * 100_000;
    const relativeDemand = localDemand / nationalDemand * 100_000;

    const label = relativeDemand / relativeSupply * 100;
    return {
      values: [
        {key: 'demand', value: relativeDemand},
        {key: 'supply', value: relativeSupply}
      ],
      label: `${label.toFixed(0)}%`
    };
  }

  protected readonly humanReadableSalePrice = humanReadableSalePrice;

  private buildRegionalRelativeYieldChartData(value: AreaReportVO): GaugeChartData {
    const localYield = value.postCodeReport.values.yield;
    const nationalYield = value.nationalReport.values.yield;
    return {
      values: [
        {key: 'local', value: localYield},
        {key: 'national', value: nationalYield}
      ],
      label: `${(localYield / nationalYield * 100.0).toFixed(0)}%`
    };

  }

  private buildPriceChartData(salePrice:number, value: AreaReportVO): MultiGaugeChartData {
    const propertyPrice = humanReadableSalePrice(salePrice);
    const localPrice = humanReadableSalePrice(value.postCodeReport.values.sale_price);
    const nationalPrice = humanReadableSalePrice(value.nationalReport.values.sale_price);
    const values = [
      {
        label: `Property ${propertyPrice} vs ${value.postCodeReport.areaName} ${localPrice}`,
        ratio: salePrice / value.postCodeReport.values.sale_price,
        scale: value.postCodeReport.values.sale_price
      },
      {
        label: `Property ${propertyPrice} vs National ${nationalPrice}`,
        ratio: salePrice / value.nationalReport.values.sale_price,
        scale: value.nationalReport.values.sale_price
      },
      {
        label: `${value.postCodeReport.areaName} ${localPrice} vs National ${nationalPrice}`,
        ratio: value.postCodeReport.values.sale_price / value.nationalReport.values.sale_price,
        scale: value.nationalReport.values.sale_price
      }
    ]

    return {
      labels: ['price', ''],
      series: values.map(v => {
        return {
          seriesLabel: v.label,
          values: [v.ratio*v.scale, (2*v.scale)-(v.ratio*v.scale)],
        }
      })
    }
  }
}
