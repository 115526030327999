<!-- mini tags -->
<div class="rounded shadow label-rect align-middle flex flex-row"
     [matTooltip]="tooltip$|async" *ngIf="!detailed">
  <div class="label-icon material-icons scale-75">{{icon$|async}}</div>
  <div class="px-1 pt-1">{{description$|async}}</div>
</div>

<!-- big tags -->
<div *ngIf="detailed" class="rounded shadow detailed-container flex flex-row p-5 gap-5 w-[18rem]">
  <div class="text-5xl label-icon material-icons self-center">{{icon$|async}}</div>
  <div class="flex-col gap-5">
    <div class="text-3xl">{{description$|async}}</div>
    <div>{{tooltip$|async}}</div>
  </div>
</div>
