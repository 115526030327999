import { ConfigurationService } from './configuration.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocationSearchDTO, LocationSearchResultDTO } from '@dto';
import { ConsoleApiEndpoints } from '@enum';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private readonly http: HttpClient,
              private readonly config: ConfigurationService) {
  }

  async loadLocations(search: LocationSearchDTO): Promise<LocationSearchResultDTO> {
    const url = this.config.getAPIURL(ConsoleApiEndpoints.location_search);
    const req = this.http.post<LocationSearchResultDTO>(url, search, {withCredentials: true});
    return await firstValueFrom(req);
  }
}
