import { inject, singleton } from "tsyringe";
import { IGeoBuilderConfig, ServerSettings } from './settings/server-settings';

@singleton()
export class GeoBuilderConfig {
  public readonly settings: IGeoBuilderConfig;

  constructor(@inject(ServerSettings) serverSettings: ServerSettings) {
    this.settings = serverSettings.settings.geoBuilder
  }
}
