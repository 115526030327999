import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AppService } from '../../services/app.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { clone } from 'lodash';
import { map } from 'rxjs/operators';

type SectionType = 'tracking'|'stability'|'accuracy'|'final';
type SectionTypeLookup = {[key: string]: boolean};

@Component({
  selector: 'jumbo-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  private allTypes:SectionType[] = ['tracking', 'stability', 'accuracy', 'final'];
  public readTypes$:BehaviorSubject<SectionTypeLookup> = new BehaviorSubject<SectionTypeLookup>({});
  private hasAcceptedTerms$: BehaviorSubject<Boolean> = new BehaviorSubject<Boolean>(false);
  public canSignIn$: Observable<boolean>;
  public showWarning$: Observable<boolean>;

  constructor(public readonly authService: AuthService,
              public readonly appService: AppService) {
    this.canSignIn$ = combineLatest(this.readTypes$, this.hasAcceptedTerms$).pipe(
        map(([readTypes, hasAcceptedTerms]) => {
          if (!hasAcceptedTerms) {
            return false;
          }
          if (this.allTypes.find(candidate => readTypes[candidate] === undefined)) {
            return false;
          }
          return true;
        })
    );
    this.showWarning$ = combineLatest(this.readTypes$, this.hasAcceptedTerms$).pipe(
        map(([readTypes, hasAcceptedTerms]) => {
          if (!hasAcceptedTerms) {
            return false;
          }
          if (this.allTypes.find(candidate => readTypes[candidate] === undefined)) {
            return true;
          }
          return false;
        })
    )
  }

  ngOnInit(): void {
  }

  googleLogin() {
    this.authService.login();
  }

  read(index: SectionType) {
    const newValue = clone(this.readTypes$.value);
    newValue[index] = true;
    this.readTypes$.next(newValue);
  }

  hasRead(index: SectionType) {

  }

  changeAccept(value: Boolean) {
    this.hasAcceptedTerms$.next(value);
  }
}
