import { PropertySubType } from './property-sub-type';

export enum PropertySubTypeGeneralisation {
  FLAT = 'flat',
  HOUSE = 'house',
  OTHER = 'other'
}

const HOUSE_OR_FLAT: Map<PropertySubType, PropertySubTypeGeneralisation> = new Map<PropertySubType, PropertySubTypeGeneralisation>(
    [

      [PropertySubType.BUNGALOW, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.CHARACTER_PROPERTY, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.COTTAGE, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.COUNTRY_HOUSE, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.DETACHED, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.DETACHED_BUNGALOW, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.DETACHED_VILLA, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.END_OF_TERRACE, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.FARM_HOUSE, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.FINCA, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.HOUSE, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.LINK_DETACHED_HOUSE, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.LOG_CABIN, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.MANOR_HOUSE, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.MEWS, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.SEMI_DETACHED, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.SEMI_DETACHED_BUNGALOW, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.SEMI_DETACHED_VILLA, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.STONE_HOUSE, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.TERRACED, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.TERRACED_BUNGALOW, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.TOWN_HOUSE, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.VILLA, PropertySubTypeGeneralisation.HOUSE],
      [PropertySubType.VILLAGE_HOUSE, PropertySubTypeGeneralisation.HOUSE],

      [PropertySubType.TRIPLEX, PropertySubTypeGeneralisation.FLAT],
      [PropertySubType.APARTMENT, PropertySubTypeGeneralisation.FLAT],
      [PropertySubType.DUPLEX, PropertySubTypeGeneralisation.FLAT],
      [PropertySubType.FLAT, PropertySubTypeGeneralisation.FLAT],
      [PropertySubType.FLAT_SHARE, PropertySubTypeGeneralisation.FLAT],
      [PropertySubType.GROUND_FLAT, PropertySubTypeGeneralisation.FLAT],
      [PropertySubType.GROUND_MAISONETTE, PropertySubTypeGeneralisation.FLAT],
      [PropertySubType.MAISONETTE, PropertySubTypeGeneralisation.FLAT],
      [PropertySubType.PENTHOUSE, PropertySubTypeGeneralisation.FLAT],
      [PropertySubType.PRIVATE_HALLS, PropertySubTypeGeneralisation.FLAT],
      [PropertySubType.SERVICED_APARTMENTS, PropertySubTypeGeneralisation.FLAT],
      [PropertySubType.STUDIO, PropertySubTypeGeneralisation.FLAT],

      // [PropertySubType.BARN_CONVERSION, PropertySubTypeGeneralisation.HOUSE],
      // [PropertySubType.BLOCK_OF_APARTMENTS, PropertySubTypeGeneralisation.HOUSE],
      // [PropertySubType.LODGE, PropertySubTypeGeneralisation.HOUSE],
      // [PropertySubType.CHALET, PropertySubTypeGeneralisation.HOUSE],
      // [PropertySubType.CLUSTER_HOUSE, PropertySubTypeGeneralisation.HOUSE],
      // [PropertySubType.COACH_HOUSE, PropertySubTypeGeneralisation.HOUSE],
    ]
);

export function generalisationToPropertySubType(generalisation: PropertySubTypeGeneralisation): PropertySubType[] {
  const allSubTypeStrings: string[] = Object.keys(PropertySubType);
  const allSubTypes: PropertySubType[] = allSubTypeStrings.map(key => PropertySubType[key]);
  if (generalisation === PropertySubTypeGeneralisation.OTHER) {
    return allSubTypes.filter(candidate => !HOUSE_OR_FLAT.has(candidate))
  }
  return allSubTypes.filter(candidate => HOUSE_OR_FLAT.get(candidate) === generalisation)
}

export function getGeneralisation(propertySubType: PropertySubType): PropertySubTypeGeneralisation {
  return HOUSE_OR_FLAT.get(propertySubType);
}
