import { inject, singleton } from 'tsyringe';
import { logger } from '@logging';
import { IDatabaseConfig, ServerSettings } from './settings/server-settings';

@singleton()
export class DatabaseConfig {

  private static count = 0;
  private readonly id;
  private portOverride;

  get URI(): string {
    logger.info(`DB ID: ${this.id}`);
    return this.URIFromConfig(this.settings);
  }

  get host(): string {
    return this.settings.host;
  }

  get databaseName(): string {
    return this.settings.databaseName;
  }

  get port(): number {
    return this.portOverride? this.portOverride : this.settings.port;
  }

  get user(): string {
    return this.settings.user;
  }

  get password(): string {
    return this.settings.password;
  }

  private readonly settings: IDatabaseConfig;

  constructor(@inject(ServerSettings) serverSettings: ServerSettings ) {
    this.id = DatabaseConfig.count++;
    this.settings = serverSettings.settings.database;
    logger.info(`Database config URI initialised to ${this.URI}`);
  }

  overridePort(newPort: number) {
    logger.info(`Overriding database port to ${newPort}`);
    this.portOverride = newPort;
    logger.info(`Database config URI now set to ${this.URI}`);
  }

  private URIFromConfig(config: IDatabaseConfig): string {
    const auth = (config.user && config.password)?`${config.user}:${config.password}@`:'';
    const portNumber = this.portOverride? this.portOverride : config.port;
    const port = portNumber?`:${portNumber}`:'';
    const db = config.databaseName?`${config.databaseName}`:'';
    const options = (config.connectionOptions && config.connectionOptions.length > 0 )
        ?`?${config.connectionOptions.join('&')}`:'';
    const slash = (config.databaseName || config.connectionOptions)?'/':'';
    return `${config.protocol}://${auth}${config.host}${port}${slash}${db}${options}`;
  }

}
