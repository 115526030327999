import { Injectable } from '@angular/core';
import { ConsoleApiEndpoints } from '@enum';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { PredictionRequestDTO, PredictionResponseDTO } from '@dto';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class ValuationService {

  constructor(private readonly http: HttpClient,
              private readonly config:ConfigurationService) {
  }

  async getPrediction(predictionRequest:PredictionRequestDTO): Promise<PredictionResponseDTO> {
    const url = this.config.getAPIURL(ConsoleApiEndpoints.predict);
    const req = this.http.post<PredictionResponseDTO>(url, predictionRequest, {withCredentials: true});
    return await firstValueFrom(req);
  }
}
