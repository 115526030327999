import { Injectable } from '@angular/core';
import {
  AreaSearchByPostCodeDTO,
  AreaSearchByPostCodeResultDTO,
  AreaSearchListDTO,
  AreaSearchListResultDTO,
  AreaSearchMapDTO,
  AreaSearchMapResultDTO,
  DimensionRestrictionDTO,
  FilterDTO,
  GeoBoundsDTO,
  PostCodeResult,
  PropertyMetadataDTO
} from "@dto";
import { ConsoleApiEndpoints } from '@enum';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import { AreaReportVO, EMPTY_AREA_REPORT } from '../components/widgets/property-detail/area-report.vo';
import { pointWithinGeo } from '@utils';

@Injectable({
  providedIn: 'root'
})
export class AreaService {

  constructor(private readonly http: HttpClient,
              private readonly config:ConfigurationService) {
  }

  async loadAreaSearchList(search: AreaSearchListDTO): Promise<AreaSearchListResultDTO> {
    const url = this.config.getAPIURL(ConsoleApiEndpoints.area_search_list);
    const req = this.http.post<AreaSearchListResultDTO>(url, search, {withCredentials: true});
    return await firstValueFrom(req);
  }

  async loadAreaSearchMap(search: AreaSearchMapDTO): Promise<AreaSearchMapResultDTO> {
    const url = this.config.getAPIURL(ConsoleApiEndpoints.area_search_map);
    const req = this.http.post<AreaSearchMapResultDTO>(url, search, {withCredentials: true});
    return await firstValueFrom(req);
  }

  async loadAreaSearchPostCode(search: AreaSearchByPostCodeDTO): Promise<AreaSearchByPostCodeResultDTO> {
    const url = this.config.getAPIURL(ConsoleApiEndpoints.area_search_by_post_code);
    const req = this.http.post<AreaSearchByPostCodeResultDTO>(url, search, {withCredentials: true});
    return await firstValueFrom(req);
  }

  public async getAreaReportByPostCode(postCode: string, propertyMetadata:PropertyMetadataDTO):Promise<AreaReportVO|null> {
    const search = new AreaSearchByPostCodeDTO(postCode, this.getSearchFilter(propertyMetadata));
    const reports = await this.loadAreaSearchPostCode(search);
    if (!reports) {
      return null;
    }
    return new AreaReportVO(reports.postCodeResult, reports.rootResult);
  }

  public async getAreaReport(point:[number, number], propertyMetadata: PropertyMetadataDTO): Promise<AreaReportVO|null> {
    const search = this.getAreaSearch(point, propertyMetadata);
    if (!search) {
      return null;
    }
    const reports = await this.loadAreaSearchMap(search);
    if (!reports) {
      return null;
    }
    return this.buildAreaReport(reports, point);
  }

  private getAreaSearch(point:[number, number], propertyMetadata: PropertyMetadataDTO): AreaSearchMapDTO {
    const bounds = new GeoBoundsDTO(point[1], point[0], point[1], point[0]);
    return new AreaSearchMapDTO(bounds, this.getSearchFilter(propertyMetadata));
  }

  private getSearchFilter(propertyMetadata: PropertyMetadataDTO): FilterDTO {
    return new FilterDTO([
      new DimensionRestrictionDTO("bedrooms", "eq",
          propertyMetadata.bedrooms, propertyMetadata.bedrooms.toFixed(0)),
      //TODO: propertySubType
    ]);
  }

  private buildAreaReport(reports: any, pointCoords?:[number, number]): AreaReportVO|null {
    if (reports.postCodes.length == 0) {
      return EMPTY_AREA_REPORT;
    }
    let postCodeReport: PostCodeResult;
    if (!pointCoords && (reports.postCodes.length == 1)) {
      postCodeReport = reports.postCodes[0];
    } else {
      postCodeReport = reports.postCodes.find(report => {
        return pointWithinGeo(pointCoords, report.geoJson);
      });
    }
    if (!postCodeReport) {
      return EMPTY_AREA_REPORT;
    }
    return new AreaReportVO(postCodeReport, reports.rootResult);
  }

}
