<div class="flex flex-col">
  <!-- heading -->
  <div class="pl-5 pt-5 flex-row flex w-full items-end">
    <div class="flex-grow flex flex-col pb-2 pl-60">
      <h1>{{humanReadableResults$|async}} search results</h1>
      <span>{{searchFilter.description$|async}}</span>
    </div>
    <div class="w-20 flex-col">
      <div>Sort:</div>
      <mat-select [(value)]="selectedSort">
        <mat-option *ngFor="let sortOption of sortTypes"
                    value="{{sortOption.value}}">
          {{sortOption.label}}
        </mat-option>
      </mat-select>
    </div>
    <!--    <mat-button-toggle-group [value]="selectedSort$|async">-->
    <!--      <mat-button-toggle *ngFor="let sortOption of sortTypes" (change)="handleSortChange($event)"-->
    <!--                         value="{{sortOption.value}}">{{sortOption.label}}</mat-button-toggle>-->
    <!--    </mat-button-toggle-group>-->
  </div>

  <div class="flex flex-row">
    <jumbo-search-filter #searchFilter
                         class="w-64"
                         (filter)="filterChange($event)"
                         [loadFilter]="loadFilterSignal$ | async"
                         title="Properties"
                         [filterItems]="filterOptions"></jumbo-search-filter>
    <div class="flex flex-col min-h-100 w-[48rem] pt-5">
      <div class="grow pb-10">
        <div *ngIf="(state$|async)=='results'"
             class="flex flex-col gap-10 shrink">
          <div *ngFor="let item of (results$|async)">
            <app-property-search-item [item]="item"></app-property-search-item>

          </div>
        </div>
        <div *ngIf="(state$|async)=='empty'" class="h-100">
          <h2>No results for your search</h2>
        </div>
        <div *ngIf="(state$|async)=='loading'" class="h-100 w-100 flex place-content-center items-center">
          <div class="flex flex-col w-96 h-30 pb-20">
            <h2>Loading</h2>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
        </div>
        <div *ngIf="(state$|async)=='idle'" class="h-100">
          <h2>Click to perform a search</h2>
        </div>
        <div *ngIf="(state$|async)=='error'" class="h-100">
          <h2>There was an error: {{error$|async}}. Please wait a moment and try again.</h2>
        </div>
      </div>
      <mat-paginator [length]="(page$|async)?.maxResults"
                     class="w-[48rem] rounded-t"
                     [pageSize]="10"
                     [pageSizeOptions]="[10,25,50]"
                     (page)="pageEvent($event)"
                     aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
</div>
