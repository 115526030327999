<div >
  <div *ngIf="(isReportValid$|async)">
    <h2>Area: {{(areaReport$|async).postCodeReport.postCode}}</h2>
    <p>{{(areaReport$|async).postCodeReport.areaName}} ({{(areaReport$|async).postCodeReport.regionName}})</p>

    <div class="flex-row flex gap-3">

      <div class="flex-col items-center h-[12rem] w-[12rem]">
        <p class="self-center">Let demand</p>
        <jumbo-gauge-chart [chartData]="letDemandChartData$|async"></jumbo-gauge-chart>
      </div>
      <div class="flex-col items-center h-[12rem] w-[12rem]">
        <p>Relative Yield</p>
        <jumbo-gauge-chart [chartData]="regionalYieldChartData$|async"></jumbo-gauge-chart>
      </div>
      <div class="flex-col items-center h-[12rem] w-[24rem]">
        <p>Relative Price</p>
        <jumbo-multi-gauge-chart [chartData]="priceChartData$|async"></jumbo-multi-gauge-chart>
      </div>
      <div class="flex-col items-center">
        <p>Time to sell</p>
        <p>{{(areaReport$|async).postCodeReport.values.time_to_sell}}</p>
      </div>

    </div>
  </div>
  <div *ngIf="!(isReportValid$|async)">
    <h2>Area Details Unavailable</h2>
  </div>
</div>
