import { LocationType } from '@enum';
import { isString } from 'lodash';

export class LocationDTO {

  private static SEPARATOR = ';';

  static Serialise(location:LocationDTO): string {
    if (location === null) {
      return null;
    }
    return [location.name, location.subname, location.type, location.id].join(LocationDTO.SEPARATOR);
  }

  static Parse(data: string): LocationDTO | null {
    if (data === null) {
      return null;
    }
    if (!isString(data)) {
      return null;
    }
    const parts = data.split(this.SEPARATOR);
    return new LocationDTO(
        parts[0], parts[1], parts[2] as LocationType, parts[3]
    )
  }

  constructor(public readonly name: string,
              public readonly subname: string,
              public readonly type: LocationType,
              public readonly id: string) {
  }

}
