import humanFormat from 'human-format';
import { isNumber } from 'lodash';

export function fractionToPercent(value: number, decimal = 1): string {
  if (!isNumber(value)) {
    return '';
  }
  return (value*100.0).toFixed(1)+'%';
}

export function humanReadableSalePrice(value: number):string {
  if (!isNumber(value)) {
    return '';
  }
  return '£' + humanFormat(value);
}

export function annualRentToMonthlyHumanReadable(annualRent: number): string {
  if (!isNumber(annualRent)) {
    return '';
  }
  return '£'+humanFormat(annualRent/12.0);
}

export function humanReadableDate(timestamp: number): string {
  const date = new Date(timestamp);

  let f = new Intl.DateTimeFormat('en', {month: 'short', day: 'numeric'});
  let a = f.formatToParts(date);
  return a.join(' ');
}
