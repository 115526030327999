<div [class]="(appModel.isDarkMode$|async)?'item-light':'item-dark'"
     class="rounded flex flex-col shadow p-3 w-full items-stretch">
  <mat-label class="pb-5">{{config.label}}</mat-label>
  <jumbo-search-filter-drop-down-item *ngIf="config.filterType === 'dropDown'"
                                      (restrictionChange)="handleChildRestrictionChange($event)"
                                      [config]="dropDownConfig">
  </jumbo-search-filter-drop-down-item>

  <jumbo-search-filter-numeric-slider-item *ngIf="config.filterType === 'numericSlider'"
                                           class="w-full"
                                           (restrictionChange)="handleChildRestrictionChange($event)"
                                           [config]="numericSliderConfig">
  </jumbo-search-filter-numeric-slider-item>

  <jumbo-search-filter-text-item *ngIf="config.filterType === 'text'"
                                 (restrictionChange)="handleChildRestrictionChange($event)"
                                 [config]="textConfig">
  </jumbo-search-filter-text-item>

  <jumbo-search-filter-radio-item *ngIf="config.filterType === 'radio'"
                                 (restrictionChange)="handleChildRestrictionChange($event)"
                                 [config]="radioConfig">
  </jumbo-search-filter-radio-item>

  <jumbo-search-filter-checkbox-item *ngIf="config.filterType === 'checkbox'"
                                     (restrictionChange)="handleChildRestrictionChange($event)"
                                     [config]="checkboxConfig">
  </jumbo-search-filter-checkbox-item>
  <jumbo-search-filter-drop-down-numeric-range-item *ngIf="config.filterType === 'dropDownNumericRange'"
                                                    (restrictionChange)="handleChildRestrictionChange($event)"
                                                    [config]="dropDownNumericRangeConfig">
  </jumbo-search-filter-drop-down-numeric-range-item>

  <jumbo-search-filter-numeric-range-slider-item *ngIf="config.filterType === 'numericSliderRange'"
                                                    (restrictionChange)="handleChildRestrictionChange($event)"
                                                    [config]="numericSliderRangeConfig">
  </jumbo-search-filter-numeric-range-slider-item>

<!--  <div>-->
<!--    <mat-icon class="cursor-pointer text-sm pt-1" (click)="removeItem()">close</mat-icon>-->
<!--  </div>-->
</div>
