import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppService } from "../../services/app.service";

@Component({
  selector: 'jumbo-value-chart',
  templateUrl: './value-chart.component.html',
  styleUrls: ['./value-chart.component.scss']
})
export class ValueChartComponent implements OnInit {
  @Input()
  vo: ValueChartVO;

  constructor(private readonly appService: AppService) { }

  ngOnInit(): void {
  }

  getBarWidth(vo:ValueChartVO): string {
    return `${vo.fraction * 200}px`;
  }

  getBarClass(vo:ValueChartVO): Observable<string> {
    return this.appService.isDarkMode$.pipe(
      map(isDark => `bar-${vo.colourIndex}-${isDark?'dark':'light'}`)
    );
  }


  getTextClass(vo:ValueChartVO): Observable<string> {
    return this.appService.isDarkMode$.pipe(
      map(isDark => `text-${vo.colourIndex}-${isDark?'dark':'light'}`)
    );
  }

}

export class ValueChartVO {
  constructor(public readonly title: string,
              public readonly valueLabel: string,
              public readonly fraction: number,
              public readonly colourIndex: number) {
  }
}
