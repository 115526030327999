import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';
import {
  FilterDTO,
  SaleListingDTO,
  SaleSearchItemDTO,
  SearchDTO,
  SearchResultDTO,
  SimilarPropertiesRequestDTO,
  UpdateFavoritePropertyDTO
} from '@dto';
import { HttpClient } from '@angular/common/http';
import { ConsoleApiEndpoints } from '@enum';
import { ConfigurationService } from './configuration.service';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  constructor(private readonly http: HttpClient, private readonly config: ConfigurationService) {
  }

  async search(propertySearch: SearchDTO<FilterDTO>): Promise<SearchResultDTO<SaleSearchItemDTO>> {
    const url = this.config.getAPIURL(ConsoleApiEndpoints.property_search);
    const req = this.http.post<SearchResultDTO<SaleSearchItemDTO>>(url, propertySearch, {withCredentials: true});
    return await firstValueFrom(req);
  }

  async load(id: string): Promise<SaleListingDTO> {
    const url = this.config.getAPIURL(ConsoleApiEndpoints.property, {id: id});
    const req = this.http.get<SaleListingDTO>(url, {withCredentials: true});
    return await firstValueFrom(req);
  }

  async markAsFavorite(change:UpdateFavoritePropertyDTO): Promise<boolean> {
    const url = this.config.getAPIURL(ConsoleApiEndpoints.property_update_favorite);
    const req = this.http.post<boolean>(url, change, {withCredentials: true});
    return await firstValueFrom(req);
  }

  getFavorites(favoritesSearch: SearchDTO<any>): Observable<SearchResultDTO<SaleSearchItemDTO>> {
    const url = this.config.getAPIURL(ConsoleApiEndpoints.property_favorites);
    return this.http.post<SearchResultDTO<SaleSearchItemDTO>>(url,favoritesSearch, {withCredentials: true});
  }


  getSimilar(id: string): Observable<SearchResultDTO<SaleSearchItemDTO>> {
    const url = this.config.getAPIURL(ConsoleApiEndpoints.property_similar);
    return this.http.post<SearchResultDTO<SaleSearchItemDTO>>(url, id, {withCredentials: true});
  }

  getSimilarByMetadata(propertyMetadata: SimilarPropertiesRequestDTO): Observable<SearchResultDTO<SaleSearchItemDTO>> {
    const url = this.config.getAPIURL(ConsoleApiEndpoints.property_similar_metadata);
    return this.http.post<SearchResultDTO<SaleSearchItemDTO>>(url, propertyMetadata, {withCredentials: true});
  }

  async getHot():Promise<SearchResultDTO<SaleSearchItemDTO>> {
    const url = this.config.getAPIURL(ConsoleApiEndpoints.hot_properties);
    const req = this.http.get<SearchResultDTO<SaleSearchItemDTO>>(url, {withCredentials: true});
    return await firstValueFrom(req);
  }

  getFavoritesCount():Observable<number> {
    const url = this.config.getAPIURL(ConsoleApiEndpoints.property_favorites_count);
    return this.http.get<number>(url, {withCredentials: true});
  }
}
