import { inject, singleton } from 'tsyringe';
import { IExtractSchedulerConfig, ServerSettings } from './settings/server-settings';

@singleton()
export class ExtractSchedulerConfig {
  public readonly settings: IExtractSchedulerConfig;

  constructor(@inject(ServerSettings) serverSettings: ServerSettings) {
    this.settings = serverSettings.settings.extractScheduler;
  }
}
