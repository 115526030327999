<div class="flex flex-col min-h-[80rem]">

  <div class="relative h-[70rem]">

    <div class="absolute left-0 right-0 top-0 bottom-0 flex-col">
      <div class="h-[60rem] flex" [class]="(appService.isDarkMode$|async)?'bg-img-dark':'bg-img-light'">
      </div>
      <div class="w-full grow flex" style="background-color: var(--background-color)">
      </div>
    </div>

    <h1 class="absolute top-[10rem] left-20 text-6xl font-extrabold" style="color:#000;">Valuate your property</h1>
    <div class="w-[40rem] pl-20 pt-[18rem]">

      <div class="w-full rounded-3xl rect-shadow"
           style="background-color: var(--background-color)">
        <div class="flex-col p-10 gap-5">
          <h2>Do you have or think about buying a property?</h2>
          <div>Find out how much a property is worth in the current market
            conditions.
            We'll be able to give you the estimated property value, and potential yield. See like-for-like properties in
            the vicinity and discover what to do to get the best return-on-investment.
          </div>
          <form class="flex flex-col w-full pt-10 gap-3" [formGroup]="valuationForm">

            <mat-form-field class="" appearance="fill">
              <mat-label>Post Code</mat-label>
              <input matInput placeholder="Full UK Post Code" formControlName="postCode" required>
            </mat-form-field>


            <!--        <mat-form-field class="" appearance="fill">-->
            <mat-label>Bedrooms</mat-label>


            <mat-slider aria-label="unit(s)" min="1" max="6"
                        discrete="true"
                        name="bedroomSlider"
                        aria-label="units"
                        class="slider w-full pl-0 pr-0">
              <input matSliderThumb class="pl-0 pr-0 ml-3" formControlName="bedrooms">
            </mat-slider>

            <!--        <mat-form-field class="" appearance="fill">-->
            <mat-label>Bathrooms</mat-label>


            <mat-slider aria-label="unit(s)" min="0" max="6"
                        discrete="true"
                        name="bathroomsSlider"
                        aria-label="units"
                        class="slider w-full pl-0 pr-0">
              <input matSliderThumb class="pl-0 pr-0 ml-3" formControlName="bathrooms">
            </mat-slider>

            <mat-form-field class="" appearance="fill">
              <mat-label>Square Feet</mat-label>
              <input matInput placeholder="Internal area of the property in square feet" formControlName="squareFt" type="number">
            </mat-form-field>


            <!--        <mat-form-field appearance="fill">-->
            <!--          <div class="py-5 gap-5 flex flex-col">-->
            <!--            <mat-label>Listing type</mat-label>-->
            <!--            <mat-radio-group formControlName="listingType" class="flex flex-col px-5 gap-2">-->
            <!--              <mat-radio-button value="buy">Purchase</mat-radio-button>-->
            <!--              <mat-radio-button value="rent">Rental (per month)</mat-radio-button>-->
            <!--            </mat-radio-group>-->
            <!--          </div>-->
            <!--        </mat-form-field>-->

            <mat-form-field appearance="fill">
              <mat-label>Property Sub-Type</mat-label>
              <mat-select formControlName="propertySubType">
                <mat-option *ngFor="let option of propertySubTypeOptions" value="{{option}}">{{option}}</mat-option>
              </mat-select>
            </mat-form-field>

            <button mat-raised-button color="primary" class="my-10 w-48 right" (click)="makePrediction()">Submit</button>
            <span *ngIf="serviceError">{{serviceError}}</span>
          </form>
        </div>
      </div>
    </div>
  </div>


  <!-- loading -->
  <div *ngIf="(viewState$|async) === 'loading'" class="p-20 flex-col flex">
    <h1 class="pl-10">Loading...</h1>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <!-- error -->
  <div *ngIf="(viewState$|async) === 'error'" class="p-20">
    <h1 class="pl-10">Error...</h1>
    <div>{{error$|async}}</div>
  </div>


  <!-- result -->
  <div *ngIf="(viewState$|async) === 'result'"
       class="flex flex-col p-20 flex-wrap w-full h-full gap-0 content-start w-[72rem]">
    <h1 class="pl-10">Valuation Report</h1>

    <div class="flex flex-col gap-10">

      <div class="flex flex-col gap-5 rounded-3xl p-10" [class]="(appService.isDarkMode$|async)?'bg-neutral-800':'bg-white'">
        <h2>Property Financials</h2>
        <div class="flex flex-row gap-10">
          <div class="flex-col items-center h-[6rem] w-[12rem]">
            <p>Estimated Price</p>
            <h2>{{humanReadableSalePrice((predictionResult$|async).sellPrice)}}</h2>
          </div>
          <div class="flex-col items-center h-[6rem] w-[12rem]">
            <p>Let for</p>
            <h2>{{annualRentToMonthlyHumanReadable((predictionResult$|async).letPrice)}} / month</h2>
          </div>
          <div class="flex-col items-center h-[6rem] w-[12rem]">
            <p>Estimated Yield</p>
            <h2>{{fractionToPercent((predictionResult$|async).letPrice / (predictionResult$|async).sellPrice)}}</h2>
          </div>
        </div>
        <div>{{description$|async}}</div>
      </div>

      <div class="rounded-3xl p-10" [class]="(appService.isDarkMode$|async)?'bg-neutral-800':'bg-white'">
        <jumbo-area-report *ngIf="(areaReport$|async) as areaReport"
                           [areaReport]="areaReport"
                           [salePrice]="(predictionResult$|async).sellPrice"></jumbo-area-report>
        <div *ngIf="!(areaReport$|async)">
          <p>Loading area...</p>
          <mat-progress-bar  mode="indeterminate"></mat-progress-bar>
        </div>
      </div>


      <div class="rounded-3xl p-10" [class]="(appService.isDarkMode$|async)?'bg-neutral-800':'bg-white'">
        <h2>Agents</h2>
        <div *ngIf="(agentReports$|async) as reports" class="pb-3">
          <mat-chip-listbox aria-label="Agent report selection" (change)="selectedAgentReportChange($event)">
            <mat-chip-option *ngFor="let report of reports.agents"
                             [value]="report">{{report.agentName}}</mat-chip-option>
          </mat-chip-listbox>
          <jumbo-estate-agent-report *ngIf="selectedAgentReport$|async"
                                     [agentReport]="selectedAgentReport$|async"></jumbo-estate-agent-report>
        </div>
        <div *ngIf="agentReportLoading$|async">
          <p>Loading agents...</p>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>

      </div>

      <div class="rounded-3xl p-10" [class]="(appService.isDarkMode$|async)?'bg-neutral-800':'bg-white'">
        <h2>Similar properties</h2>
        <div class="flex flex-row gap-10 shrink pt-5 w-full justify-between"
             *ngIf="(similarProperties$|async) && (similarProperties$|async).results">
          <div *ngFor="let item of (similarProperties$|async).results" class="flex">
            <app-property-search-card [item]="item"></app-property-search-card>
          </div>
        </div>
      </div>
    </div>
  </div>




</div>
