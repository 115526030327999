<div class="flex flex-row w-full h-full items-center">
  <canvas baseChart class=""
          [data]="chartData$| async"
          [options]="chartOptions$|async"
          [type]="'doughnut'">
  </canvas>
  <div class="flex-col flex">
    <div *ngFor="let legendItem of legendData$|async"
         class="flex-row flex">
      <div class="w-3 h-3" [style.background-color]="legendItem.colour">

      </div>
      <div class="flex-shrink">
        {{legendItem.label}}
      </div>
    </div>
  </div>

</div>
