import { inject, singleton } from 'tsyringe';
import { IConsolidatorConfig, ServerSettings } from './settings/server-settings';

@singleton()
export class ConsolidatorConfig {

  public readonly settings: IConsolidatorConfig;
  public readonly environment: string;

  constructor(@inject(ServerSettings) configSettings: ServerSettings) {
    this.settings = configSettings.settings.consolidator;
  }
}
