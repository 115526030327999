import { DimensionNames } from '@enum';

export class SearchDTO<T> {
  constructor(public readonly pageIndex: number,
              public readonly pageSize: number,
              public readonly query: T,
              public readonly sortField?: DimensionNames,
              public readonly sortDirection: 1|-1 = 1) {
  }
}
