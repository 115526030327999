  <mat-form-field class="" appearance="fill">
    <mat-label>{{config.prompt}}</mat-label>
    <input matInput (input)="handleInputChange($event)"
           [(ngModel)]="inputSelection"
           name="locationInput"
           [matAutocomplete]="auto">
    <button *ngIf="inputSelection" matSuffix mat-icon-button aria-label="Clear"
            (click)="onClear()">
      <<span class="material-icons">>close</span>
    </button>
    <mat-autocomplete #auto="matAutocomplete"
                      name="locationAuto">
      <mat-option *ngFor="let option of searchResults$ | async" [value]="option.name"
                  (onSelectionChange)="emitNewRestriction(option)">
        <div class="py-0 flex flex-col">
          <div class="text-sm">{{option.name}}</div>
          <div class="text-xs">{{option.subname}}</div>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
