import { Component, Input, OnInit } from "@angular/core";
import { ValueChartVO } from "../value-chart/value-chart.component";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AppService } from "../../services/app.service";

@Component({
  selector: 'jumbo-value-comparison-card',
  templateUrl: './value-comparison-card.component.html',
  styleUrls: ['./value-comparison-card.component.scss']
})
export class ValueComparisonCardComponent implements OnInit {

  @Input()
  public vo:ValueComparisonCardVO

  constructor(private readonly appService: AppService) { }


  getBackgroundClass(): Observable<string> {
    return this.appService.isDarkMode$.pipe(
      map(isDark => `background-${isDark?'dark':'light'}`)
    );
  }

  ngOnInit(): void {
  }

}

export class ValueComparisonCardVO {
  constructor(public readonly title: string,
              public readonly subtitle: string,
              public readonly valueCharts: ValueChartVO[]) {
  }
}
