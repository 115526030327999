import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subscription, throttleTime } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { ChannelMessage, ChannelMessageType, ChannelService } from '../../services/channel.service';
import { filter, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsoleApiEndpoints, ConsolePageType } from '@enum';
import { ConfigurationService } from '../../services/configuration.service';
import { logger } from '@logging';

@Component({
  selector: 'jumbo-waiting-for-auth',
  templateUrl: 'waiting-for-auth.component.html',
  styleUrls: ['waiting-for-auth.component.scss'],
  providers: [ChannelService]
})
export class WaitingForAuthComponent implements OnInit, OnDestroy {
  private messageSubscription: Subscription;
  private destinationPath: string;

  constructor(private readonly authService: AuthService,
              private readonly channelService: ChannelService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly router: Router,
              private readonly config: ConfigurationService,
              private readonly ngZone: NgZone) {
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.pipe(
        take(1)
    ).subscribe(params => {
      this.destinationPath = params.get('dest');
    }, e => logger.error(e));

    this.messageSubscription = this.channelService.subscribeToMessages(ChannelMessageType.CHILD_WINDOW_AUTHENTICATED)
        .pipe(
            throttleTime(2000),
            switchMap(async () => {
              return this.authService.refreshAuthentication();
            }),
            switchMap(() => {
              return this.authService.isAuthenticated$;
            }),
            filter(isAuthenticated => (isAuthenticated == true)),
        )
        .subscribe(() => {
          this.channelService.sendMessage(new ChannelMessage(ChannelMessageType.PARENT_WINDOW_AUTHENTICATED));
          const destination = this.destinationPath ?
              this.router.parseUrl(this.destinationPath) :
              this.config.getClientPath(ConsolePageType.LANDING);
          this.ngZone.run(() => {
            this.router.navigateByUrl(destination);
          });
        }, e => logger.error(e));
    this.openChildWindow();
  }

  ngOnDestroy(): void {
    this.messageSubscription.unsubscribe();
  }

  openChildWindow(): void {
    window.open(this.config.getAPIURL(ConsoleApiEndpoints.auth_google),
        'com_estatestats_child_auth_login', 'height=600,width=600');
  }

}
