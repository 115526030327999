import { AfterViewInit, Component } from '@angular/core';
import { AppService } from './services/app.service';
import BUILD_VERSION from '../build-version.json';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserSettingsService } from './services/user-settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  public get version():string {
    return BUILD_VERSION.version;
  }

  constructor(private readonly snackBar: MatSnackBar,
              public readonly appService: AppService,
              public readonly userSettingsService: UserSettingsService){
  }

  ngAfterViewInit(): void {
    if (!this.userSettingsService.userAcceptsCookies) {
      const message = `Estate Stats uses cookies to aid in navigation. By continuing you consent to the use of this technology.`;
      const snackBarRef = this.snackBar.open(message, 'OK');
      snackBarRef.onAction().subscribe(() => {
        this.userSettingsService.acceptCookies();
      });
    }
  }

}
