import { isString, isUndefined } from 'lodash';
import { SessionUserDTO } from './session-user.dto';
import { AccessStatusType, AuthStatusType } from '@enum';

export class AuthStatusDTO {
  constructor(public readonly authStatus:AuthStatusType,
              public readonly accessStatus?:AccessStatusType,
              public readonly sessionUser?:SessionUserDTO,
              public readonly authURL?:string) {
  }
}

export function isAuthStatusDTO(x: any): x is AuthStatusDTO {
  const candidate = x as AuthStatusDTO;
  if (isUndefined(candidate)) {
    return false;
  }
  if (!isString(candidate.authStatus)) {
    return false;
  }
  return true;
}
