<div class="relative w-100">
  <div class="p-5 flex flex-row gap-5 items-center overflow-x-auto">
    <jumbo-value-comparison-card [vo]="getValueComparison(area, rootArea, '% yield','yield')">
    </jumbo-value-comparison-card>
    <jumbo-value-comparison-card [vo]="getValueComparison(area, rootArea, 'Let price', 'let_price')">
    </jumbo-value-comparison-card>
    <jumbo-value-comparison-card [vo]="getValueComparison(area, rootArea,'Sale price', 'sale_price')">
    </jumbo-value-comparison-card>
    <jumbo-value-comparison-card [vo]="getValueComparison(area, rootArea,'Time to sell (days)', 'time_to_sell')">
    </jumbo-value-comparison-card>

    <button mat-raised-button color="primary"
            (click)="navigateToProperties()"
            class="w-32 min-w-32 p-1">View properties</button>

  </div>
  <span class="material-icons cursor-pointer absolute top-3 right-3"
        (click)="closeView()">close</span>
</div>
