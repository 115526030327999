import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { logger } from '@logging';

@Injectable()
export class ChannelService {

  private channel: BroadcastChannel;

  private messages$:ReplaySubject<ChannelMessage> = new ReplaySubject<ChannelMessage>()

  constructor() {
    this.channel = new BroadcastChannel('extatestats.oauth');
    this.channel.onmessage = (e) => {
      logger.info(`Channel message received`);
      const message = JSON.parse(e.data) as ChannelMessage;
      this.messages$.next(message);
    };
  }

  public subscribeToMessages(messageType:ChannelMessageType):Observable<any> {
    logger.info(`Subscribing to message type ${messageType}`);
    return this.messages$.pipe(
        tap((message) => logger.info(`Received message: ${JSON.stringify(message)}`)),
        filter(message => message.messageType === messageType)
    );
  }

  public sendMessage(message: ChannelMessage) {
    logger.info(`Sending message: ${JSON.stringify(message)}`);
    this.channel.postMessage(JSON.stringify(message));
  }
}

export class ChannelMessage {
  constructor(public readonly messageType: ChannelMessageType,
              public readonly data?:any) {
  }
}

export enum ChannelMessageType {
  CHILD_WINDOW_AUTHENTICATED = 'childWindowAuthenticated',
  PARENT_WINDOW_AUTHENTICATED = 'parentWindowAuthenticated'
}
