import { Component, Input, OnDestroy } from '@angular/core';
import { combineLatest, Observable, ReplaySubject, shareReplay, Subject, takeUntil } from 'rxjs';
import { AppService } from '../../../services/app.service';
import { map } from 'rxjs/operators';
import { ChartData } from 'chart.js';

export type MultiGaugeChartData = { series: { values: number[], seriesLabel: string }[], labels: string[] };
type LegendDataItem = { label: string, colour: string };

@Component({
  selector: 'jumbo-multi-gauge-chart',
  templateUrl: './multi-gauge-chart.component.html',
  styleUrls: ['./multi-gauge-chart.component.scss']
})
export class MultiGaugeChartComponent implements OnDestroy {

  @Input()
  public set chartData(value: MultiGaugeChartData) {
    this.chartValues$.next(value);
  }

  protected chartValues$: Subject<MultiGaugeChartData> = new ReplaySubject<MultiGaugeChartData>(1);
  protected chartData$: Observable<ChartData<"doughnut">>;
  protected chartOptions$: Observable<any>;
  protected legendData$: Observable<LegendDataItem[]>;

  private readonly MAX_VALUES: number = 3;

  private readonly DARK_MODE_COLOURS = [
    '#e08b1b', '#f327c9', '#27f3f3',
  ];
  private readonly LIGHT_MODE_COLOURS = [
    '#e08b1b', '#f327c9', '#27f3f3',
  ];
  private destroyed$: Subject<any> = new Subject<any>();


  constructor(appService: AppService) {
    const latestData$ = combineLatest([appService.isDarkMode$, this.chartValues$]).pipe(
        takeUntil(this.destroyed$),
    shareReplay(1)
  );

    this.chartData$ = latestData$.pipe(
        takeUntil(this.destroyed$),
        map(([isDark, values]) => this.buildChartData(values, isDark))
    );
    this.chartOptions$ = latestData$.pipe(
        takeUntil(this.destroyed$),
        map(([isDark, values]) => this.buildChartOptions(isDark, values))
    );
    this.legendData$ = latestData$.pipe(
        takeUntil(this.destroyed$),
        map(([isDark, values]) => this.buildLegendData(values, isDark))
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private buildLegendData(data: MultiGaugeChartData, isDarkMode: boolean): LegendDataItem[] {
    const colours = isDarkMode ? this.DARK_MODE_COLOURS : this.LIGHT_MODE_COLOURS;
    return data.series.map((series, index) => {
      return {
        label: series.seriesLabel,
        colour: colours[index]
      };
    });
  }

  private buildChartData(data: MultiGaugeChartData, isDarkMode: boolean): ChartData<'doughnut'> {
    const colours = isDarkMode ? this.DARK_MODE_COLOURS : this.LIGHT_MODE_COLOURS;
    const datasets = data.series.map((series, index) => {
      const values = series.values.concat();
      return {
        label: series.seriesLabel,
        borderWidth: 0,
        data: values,
        backgroundColor: [colours[index], 'transparent'],
      };
    });

    return {
      labels: data.labels,
      datasets
    };
  }

  private buildChartOptions(isDarkMode: boolean, data:MultiGaugeChartData): any {
    const cutout = 1.0 - (data.series.length * 0.1);
    return {
      rotation: 270, // start angle in degrees
      circumference: 180, // sweep angle in degrees
      cutout: `${(cutout*100).toFixed(0)}%`
    };
  }
}
