export interface ILogger {
  error(msg: string);
  warn(msg: string);
  info(msg: string);
  debug(msg: string);
}

interface IPrefixedLogger extends ILogger {
  addPrefix(prefix: string);
  clearPrefixes();
}

export class PrefixedLogger<T extends ILogger> implements IPrefixedLogger {
  readonly start:number;
  constructor(private readonly _log: T) {
    this.start = Date.now();
  }

  private prefixes = [];

  addPrefix(prefix: string) {
    this.prefixes.push(prefix);
  }

  popPrefix() {
    this.prefixes.pop();
  }

  clearPrefixes() {
    this.prefixes = [];
  }

  error(msg: string) {
    this._log.error(this.getMessage(msg));
  }

  warn(msg: string) {
    this._log.warn(this.getMessage(msg));
  }

  info(msg: string) {
    this._log.info(this.getMessage(msg));
  }

  debug(msg: string) {
    this._log.debug(this.getMessage(msg));
  }

  private getMessage(msg: string) {
    let result = `(${((Date.now()-this.start) / 1000).toFixed(4)}) `;
    this.prefixes.forEach(prefix => {
      result += `[${prefix}] `;
    });
    return result + msg;
  }

}
