import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppService } from "../../../services/app.service";
import { DimensionRestrictionDTO } from '@dto';
import {
  ISearchFilterItemConfig,
  SearchFilterCheckboxConfig,
  SearchFilterDropDownConfig,
  SearchFilterDropDownNumericRangeConfig,
  SearchFilterItemVO,
  SearchFilterNumericSliderConfig,
  SearchFilterNumericSliderRangeConfig,
  SearchFilterRadioConfig,
  SearchFilterTextConfig
} from './search-filter-item.vo';


@Component({
  selector: 'jumbo-search-filter-item',
  templateUrl: './search-filter-item.component.html',
  styleUrls: ['./search-filter-item.component.scss']
})
export class SearchFilterItemComponent implements OnInit {

  @Output()
  public remove: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public restrictionChange: EventEmitter<DimensionRestrictionDTO> = new EventEmitter<DimensionRestrictionDTO>();

  @Input()
  public item: SearchFilterItemVO;

  public get config(): ISearchFilterItemConfig {
    return this.item.config;
  }

  public get dropDownConfig(): SearchFilterDropDownConfig {
    return this.config as SearchFilterDropDownConfig;
  }

  public get numericSliderConfig(): SearchFilterNumericSliderConfig {
    return this.config as SearchFilterNumericSliderConfig;
  }

  public get textConfig(): SearchFilterTextConfig {
    return this.config as SearchFilterTextConfig;
  }

  public get radioConfig(): SearchFilterRadioConfig {
    return this.config as SearchFilterRadioConfig;
  }

  public get checkboxConfig(): SearchFilterCheckboxConfig {
    return this.config as SearchFilterCheckboxConfig;
  }

  public get dropDownNumericRangeConfig(): SearchFilterDropDownNumericRangeConfig {
    return this.config as SearchFilterDropDownNumericRangeConfig;
  }

  public get numericSliderRangeConfig(): SearchFilterNumericSliderRangeConfig {
    return this.config as SearchFilterNumericSliderRangeConfig;
  }

  constructor(public readonly appModel:AppService) { }

  ngOnInit(): void {
  }

  removeItem(): void {
    this.remove.next(true);
  }

  handleChildRestrictionChange(restriction:DimensionRestrictionDTO) {
    this.restrictionChange.next(restriction);
  }

}
