<div class="h-100 w-100 flex place-content-center items-center">
    <div class="flex flex-col h-30 pb-20 content pt-20">
        <div class="px-5">
            <h1>Login</h1>
            <p>Please read the following conditions before signing in.</p>
        </div>
        <mat-accordion>
            <mat-expansion-panel (opened)="read('tracking')">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h2 class="pt-4">User Tracking & Cookies
                            <mat-icon color="primary" *ngIf="(readTypes$|async)['tracking']">check</mat-icon></h2>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>You are entering into a Beta programme.
                    In order to develop the application further we need to track your interactions within Estate Stats.
                    One of the ways we achieve this is through the use of cookies.</p>
                <p>The tracked interactions will be used for the following purposes:</p>
                    <ol>
                        <li>Help to identify the cause of bugs</li>
                        <li>Measure how the app is used to improve the structure and user experience</li>
                        <li>Inform how to streamline and improve delivered features</li>
                    </ol>
                <p>
                    <strong>Any data we collect will not be sold or revealed to any third party.</strong>
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="read('stability')">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h2 class="pt-4">Stability
                            <mat-icon color="primary" *ngIf="(readTypes$|async)['stability']">check</mat-icon></h2>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Since this product is in Beta, you may experience bugs, system instability and interaction issues.</p>
                <p>Please help us to develop this app further by reporting these issues to us using the Feedback widget.</p>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="read('accuracy')">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h2 class="pt-4">Data Accuracy
                            <mat-icon color="primary" *ngIf="(readTypes$|async)['accuracy']">check</mat-icon></h2>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>Values presented here are not to be taken on face value nor to inform any financial decision.</p>
                <p>You must do your own independent research without relying on any values presented within this
                    app.</p>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="read('final')">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h2 class="pt-4">Beta Period
                            <mat-icon color="primary" *ngIf="(readTypes$|async)['final']">check</mat-icon></h2>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>This product is offered as-is without any obligation to you the user.</p>
                <p> We will do our best to keep you informed on any changes to this platform availability.
                    However we reserve the right to terminate any part of this tool, this app in it's entirety without
                    any prior warning.
                    We also reserve the right to adjust access to the app for beta users.</p>
            </mat-expansion-panel>
        </mat-accordion>

        <div class="px-5 pt-5">
            <mat-checkbox class="pb-5" color="primary" (change)="changeAccept($event.checked)">
                I have read and accept all of the above conditions.
            </mat-checkbox>
            <div *ngIf="showWarning$|async">
                <p>Please read all sections above before continuing</p>
            </div>
            <div *ngIf="canSignIn$|async">
                <p>We currently only support Google authentication. Make sure to use the same Google email address you registered with.</p>
                <!-- google -->
                <img class="doubleSize cursor-pointer"
                     (click)="googleLogin()"
                     *ngIf="appService.isDarkMode$|async"
                     src="assets/google-sign-in/btn_google_signin_dark_normal_web@2x.png"/>
                <img class="doubleSize cursor-pointer"
                     (click)="googleLogin()"
                     *ngIf="!(appService.isDarkMode$|async)"
                     src="assets/google-sign-in/btn_google_signin_light_normal_web@2x.png"/>

                <h2 class="pt-10">Can't Sign in?</h2>
                    <p>This Early Access programme is only for people who have previously registered for it. If you haven't
                        signed up but would like access, you can
                <a href="https://forms.gle/czUH6XgXoeum1wD26" target="_blank" class="cursor-pointer">register for updates</a> on upcoming access programmes.</p>
                        <p>If you signed up but still
                don't have a google account, or you registered the wrong email address please follow the following steps:</p>
                <ol>
                    <li>Make sure you have a Google account. If you don't, you can
                        <a href="https://accounts.google.com/signup" target="_blank">sign up for a Google Account</a></li>
                    <li>Use the 'Feedback' button on the right of this page and click 'Contact us'.</li>
                    <li>In the messages, please send us both the original and your Google account email address.
                        We will need the original email address so we can verify you signed up for the Early Access Programme,
                        and we need the new google email address so we can add you as a member to the system.</li>
                    <li>Once your login is available, we will send a notification to your new Google email address.</li>
                </ol>
            </div>
        </div>
    </div>
</div>


