import { inject, singleton } from "tsyringe";
import { IDBDumperConfig, ServerSettings } from './settings/server-settings';

@singleton()
export class DbDumperConfig {
  private readonly settings: IDBDumperConfig;

  constructor(@inject(ServerSettings) config: ServerSettings) {
    this.settings = config.settings.dbDumper;
  }

  get downloadPath(): string {
    return this.settings.downloadPath;
  }

  get bucketName(): string {
    return this.settings.bucketName;
  }

  get dumpIntervalHours(): number {
    return this.settings.dumpIntervalHours;
  }

}
