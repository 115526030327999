import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import { FilterDTO } from "@dto";
import {
  SearchFilterDropDownConfig,
  SearchFilterDropDownOption,
  SearchFilterItemVO,
  SearchFilterNumericSliderConfig
} from '../search-filter/search-filter-item/search-filter-item.vo';


@Component({
  selector: 'jumbo-area-search',
  templateUrl: './area-search.component.html',
  styleUrls: ['./area-search.component.scss']
})
export class AreaSearchComponent implements OnInit {
  public filterDescription$: BehaviorSubject<string>
      = new BehaviorSubject<string>('loading...');
  public filter$: BehaviorSubject<FilterDTO>
      = new BehaviorSubject<FilterDTO>(new FilterDTO([]));

  public readonly filterOptions: SearchFilterItemVO[];

  constructor() {
    this.filterOptions = [
      new SearchFilterItemVO(
          new SearchFilterNumericSliderConfig('Bedrooms', 'bedrooms', 'eq', 7, 3, 1, 1),
      ),
      new SearchFilterItemVO(
          new SearchFilterDropDownConfig('Type', 'propertySubType', 'eq', [
            new SearchFilterDropDownOption('Flat', 'flat'),
            new SearchFilterDropDownOption('House', 'house'),
            new SearchFilterDropDownOption('Other', 'other')
          ]),
      )
    ];
  }

  ngOnInit(): void {
  }

}
