import { GeoJSON, MultiPolygon, Point, Polygon, Position } from 'geojson';
import { logger } from '@logging';
import { flatten } from 'lodash';
import union from '@turf/union';
import { Feature, point, polygon } from '@turf/helpers';
import booleanContains from '@turf/boolean-contains';
import transformTranslate from '@turf/transform-translate';


export function isWithinUK(long:number, lat:number): boolean {
return (long > -11) && (long < 2) && (lat > 49) && (lat < 61.1);
}

export function centerPoint(geo: GeoJSON): Point {
  if (geo.type === 'Feature') {
    return centerPoint(geo.geometry);
  }
  if (geo.type === 'Polygon') {
    return centerPointFromPositionArray(flatten(geo.coordinates));
  }
  if (geo.type === 'MultiPolygon') {
    return centerPointFromPositionArray(flatten(flatten(geo.coordinates)));
  }
  if (geo.type === 'LineString') {
    return centerPointFromPositionArray(geo.coordinates);
  }
  if (geo.type === 'MultiLineString') {
    return centerPointFromPositionArray(flatten(geo.coordinates));
  }
  if (geo.type === 'GeometryCollection') {
    return centerPoint(geo.geometries[0]);
  }
  if (geo.type === 'MultiPoint') {
    return centerPointFromPositionArray(geo.coordinates);
  }
  if (geo.type === 'FeatureCollection') {
    return centerPoint(geo.features[0]);
  }
  if (geo.type === 'Point') {
    return geo;
  }
  logger.error(`Cannot get center point of GeoJSON ${geo}`);
  return null;
}

function centerPointFromPositionArray(positions: Position[]): Point {
  let x = 0;
  let y = 0;
  positions.forEach(point => {
    x += point[0];
    y += point[1];
  });
  const coordinates = [x/positions.length, y/positions.length];
  return {type: 'Point', coordinates};
}

export function getGeometryFromGeoJSON(combined: GeoJSON):(Polygon|MultiPolygon)[] {
  if ((combined.type === 'Polygon') || (combined.type === 'MultiPolygon')) {
    return [combined];
  }
  else if (combined.type == 'GeometryCollection') {
    return flatten(combined.geometries.map(geo => getGeometryFromGeoJSON(geo)));
  } else if (combined.type === 'Feature') {
    return getGeometryFromGeoJSON(combined.geometry);
  } else if (combined.type === 'FeatureCollection') {
    return flatten(combined.features.map(geo => getGeometryFromGeoJSON(geo)));
  }
  logger.error(`Not adding GeoJSON of type ${combined.type} to polygon`);
  return [];
}

export function buildCircleAround(origin: Point, pointCount:number, radiusKm: number): Feature<Polygon> {
  const points = [];
  for (let k = 0; k<pointCount; k++) {
    const angle = 350.0*k/pointCount;
    const poly = point(origin.coordinates);
    points.push(transformTranslate(poly, radiusKm, angle).geometry.coordinates);
  }
  points.push(points[0]);
  return polygon([points]);
}

export function simplifyGeoJSON(source: GeoJSON):(Polygon|MultiPolygon) {
  const geometries = getGeometryFromGeoJSON(source);
  return mergeGeometries(geometries);
}

export function pointWithinGeo(pointCoords:[number, number], geo:GeoJSON):Boolean {
  if (geo.type !== 'Feature') {
    return false;
  }
  if (!((geo.geometry.type === 'Polygon') || (geo.geometry.type === 'MultiPolygon'))) {
    return false;
  }
  const pin = point(pointCoords);
  if (geo.geometry.type === 'Polygon') {
    return booleanContains(geo.geometry, pin)
  }
  if (geo.geometry.type === 'MultiPolygon') {
    for (let coords of geo.geometry.coordinates) {
      const poly = polygon(coords);
      if (booleanContains(poly, pin)) {
        return true;
      }
    }
  }
  return false;
}


export function mergeGeometries(polygons: (Polygon | MultiPolygon)[]): (Polygon | MultiPolygon) {
  if (polygons.length == 0) {
    return null;
  }
  if (polygons.length == 1) {
    return polygons[0];
  }

  let result: Feature<Polygon | MultiPolygon>;
  polygons.forEach(polygon => {
    if (result) {
      result = union(result, polygon);
    } else {
      result = union(polygon, {type: "Polygon", coordinates: []});
    }
  })
  return result.geometry;

}
