import '@abraham/reflection';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { releaseType } from '@config';
import { ReleaseType } from '@enum';
import { logger } from '@logging';

import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import BUILD_VERSION from './build-version.json';


function getReleaseString(): string {
  return `jumbo@${BUILD_VERSION.version}`;
}

Sentry.init({
  dsn: "https://5834307286df414e89d1f098fedcee56@o477404.ingest.sentry.io/6564806",
  integrations: [
    new BrowserTracing({
      tracingOrigins: ["localhost", "https://console.estatestats.com", "https://api.console.estatestats.com"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  release: getReleaseString(),
  environment: releaseType === ReleaseType.production ? 'production' : 'development',
  tracesSampleRate: 1.0,//(release === ReleaseType.production) ? 0.1 : 1.0,
});

if (releaseType === ReleaseType.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => logger.error(err));
