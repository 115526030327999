import { inject, singleton } from 'tsyringe';
import { IListingOptimiserConfig, ServerSettings } from './settings/server-settings';

@singleton()
export class ListingOptimiserConfig {
  public readonly settings: IListingOptimiserConfig;

  constructor(@inject(ServerSettings) serverSettings: ServerSettings) {
    this.settings = serverSettings.settings.listingOptimiser;
  }
}
