import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { logger } from '@logging';
import { ThemeService } from 'ng2-charts';
import * as Chart from "chart.js";
import * as LuxonAdapter from 'chartjs-adapter-luxon'
import annotationPlugin from 'chartjs-plugin-annotation';
import { UserSettingsService } from './user-settings.service';

@Injectable()
export class AppService {

   public readonly isDarkMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

   constructor(private themeService: ThemeService,
               private userSettingsService: UserSettingsService) {
     //make sure we use the import
     const k = LuxonAdapter;
     this.initCharts();
     logger.info(`App service constructor`);
     this.isDarkMode$.subscribe((dark) => {
       this.setChartMode(dark);
       if (dark) {
         document.body.classList.add('dark-theme');
       } else {
         document.body.classList.remove('dark-theme');
       }
     }, e => logger.error(e))
     this.isDarkMode$.next(this.userSettingsService.darkMode);
   }

   initCharts() {
     Chart.Chart.register(annotationPlugin);
     Chart.defaults.font.family = 'Raleway';
     // Chart.defaults.plugins.legend.display = false;
     // Chart.defaults.color = '#f33';
   }

   setDarkMode(value:boolean) {
     this.isDarkMode$.next(value);
      this.userSettingsService.setDarkMode(value);
   }

   private setChartMode(isDark: boolean) {

     const fontColor = isDark ? 'white' : 'black';
     // Chart.defaults.color = fontColor;

     //
     let overrides: any = {
       // TODO: https://www.chartjs.org/docs/latest/general/colors.html
       color: fontColor,
       plugins: {
         legend: {
           display: false,
           labels: {
             boxWidth: 14,
             boxHeight: 14
           }
         }
       },



       // plugins: {
       //   legend: {
       //     labels: {fontColor}
       //   },
       // },
       // scales: {
       //   xAxes: [{
       //     ticks: {fontColor},
       //     gridLines: {color: lineColor}
       //   }],
       //   yAxes: [{
       //     ticks: {fontColor},
       //     gridLines: {color: lineColor}
       //   }]
       // }
     };
     this.themeService.setColorschemesOptions(overrides);
   }

}
