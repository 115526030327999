import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AppService } from '../../services/app.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ConsolePageType } from '../../../../shared/enum/console-page-type';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ConfigurationService } from '../../services/configuration.service';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { PropertyService } from '../../services/property.service';

@Component({
  selector: 'jumbo-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class HeadingComponent implements OnDestroy {

  @Output() navigate = new EventEmitter<ConsolePageType>();

  get darkModeSelected(): boolean {
    return this._darkModeSelected;
  }

  set darkModeSelected(value: boolean) {
    this._darkModeSelected = value;
    this.appService.setDarkMode(value);
  }

  @ViewChild('darkModeToggle')
  public darkModeToggle: MatSlideToggle;

  public imageUrl$: Observable<string>;
  public isSignedIn$: Observable<boolean>;
  public favoritesCount$: Observable<number>;

  private readonly destroyed$: Subject<boolean>;

  private _darkModeSelected: boolean;

  constructor(public readonly authService: AuthService,
              public readonly appService: AppService,
              private readonly router: Router,
              private readonly config: ConfigurationService,
              private readonly propertyService: PropertyService) {
    this.destroyed$ = new Subject<boolean>();
    this.imageUrl$ = authService.user$.pipe(
        map(user =>user ? user.profileImageUrl :''),
        takeUntil(this.destroyed$)
    );
    this.isSignedIn$ = authService.isAuthenticated$.pipe(
        takeUntil(this.destroyed$)
    );
    appService.isDarkMode$.subscribe(value => {
      this._darkModeSelected = value;
    });
    this.favoritesCount$ = propertyService.getFavoritesCount();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  login() {
    this.navigateTo(ConsolePageType.LOGIN);
  }

  logout() {
    this.authService.logout();
  }

  valuationClick() {
    this.navigateTo(ConsolePageType.VALUATION);
  }

  propertySearchClick() {
    this.navigateTo(ConsolePageType.PROPERTY_SEARCH);
  }

  areaSearchClick() {
    this.navigateTo(ConsolePageType.AREA_SEARCH);
  }

  favoritesClick() {
    this.navigateTo(ConsolePageType.FAVORITES);
  }

  iconClick() {
    this.navigateTo(ConsolePageType.LANDING);
  }

  navigateTo(pageType: ConsolePageType) {
    const path = this.config.getClientPath(pageType);
    this.router.navigate([path]);
    this.navigate.next(pageType);
  }

}

