<div class="flex flex-col gap-5 p-5 sticky top-0 overflow-y-auto">
<!--  <div class="text-lg">{{title}}:</div>-->
  <jumbo-search-filter-item *ngFor="let filterItem of activeItems$|async"
                            class="flex flex-row gap-5 items-center"
                            [item]="filterItem"
                            (restrictionChange)="handleRestrictionChange($event, filterItem)"
  >
  </jumbo-search-filter-item>

</div>
