// import * as LogLevelLog from 'loglevel';
import { ILogger, PrefixedLogger } from './prefixed-logger';

class LocalLogger implements ILogger {
  debug(msg: string) {
    console.log(msg);
  }

  error(msg: string) {
    console.log(msg);
  }

  info(msg: string) {
    console.log(msg);
  }

  warn(msg: string) {
    console.log(msg);
  }

}

export class BrowserLogger extends PrefixedLogger<LocalLogger> {
  constructor() {
    // LogLevelLog.setLevel('debug');
    super(new LocalLogger());
    // LogLevelLog.info(`Browser logger init`);
  }
}
