export enum PropertySubType {
  APARTMENT = 'Apartment',
  BARN = 'Barn',
  BARN_CONVERSION = 'Barn Conversion',
  BLOCK_OF_APARTMENTS = 'Block of Apartments',
  BUNGALOW = 'Bungalow',
  CARAVAN = 'Caravan',
  CHALET = 'Chalet',
  CHARACTER_PROPERTY = 'Character Property',
  CLUSTER_HOUSE = 'Cluster House',
  COACH_HOUSE = 'Coach House',
  COMMERCIAL_PROPERTY = 'Commercial Property',
  COTTAGE = 'Cottage',
  COUNTRY_HOUSE = 'Country House',
  DETACHED = 'Detached',
  DETACHED_BUNGALOW = 'Detached Bungalow',
  DETACHED_VILLA = 'Detached Villa',
  DUPLEX = 'Duplex',
  END_OF_TERRACE = 'End of Terrace',
  EQUESTRIAN_FACILITY = 'Equestrian Facility',
  FARM_HOUSE = 'Farm House',
  FARM_LAND = 'Farm Land',
  FINCA = 'Finca',
  FLAT = 'Flat',
  FLAT_SHARE = 'Flat Share',
  GARAGES = 'Garages',
  GITE = 'Gite',
  GROUND_FLAT = 'Ground Flat',
  GROUND_MAISONETTE = 'Ground Maisonette',
  HOTEL_ROOM = 'Hotel Room',
  HOUSE = 'House',
  HOUSE_BOAT = 'House Boat',
  HOUSE_SHARE = 'House Share',
  HOUSE_OF_MULTIPLE_OCCUPATION = 'House of Multiple Occupation',
  LAND = 'Land',
  LIGHT_INDUSTRIAL = 'Light Industrial',
  LINK_DETACHED_HOUSE = 'Link Detached House',
  LODGE = 'Lodge',
  LOG_CABIN = 'Log Cabin',
  MAISONETTE = 'Maisonette',
  MANOR_HOUSE = 'Manor House',
  MEWS = 'Mews',
  MILL = 'Mill',
  MOBILE_HOME = 'Mobile Home',
  NOT_SPECIFIED = 'Not Specified',
  OFF_PLAN = 'Off-Plan',
  OFFICE = 'Office',
  PARK_HOME = 'Park Home',
  PARKING = 'Parking',
  PENTHOUSE = 'Penthouse',
  PLOT = 'Plot',
  PRIVATE_HALLS = 'Private Halls',
  PUB = 'Pub',
  RETAIL_PROPERTY_HIGH_STREET = 'Retail Property (high street)',
  RETIREMENT_PROPERTY = 'Retirement Property',
  SEMI_DETACHED = 'Semi-Detached',
  SEMI_DETACHED_BUNGALOW = 'Semi-Detached Bungalow',
  SEMI_DETACHED_VILLA = 'Semi-detached Villa',
  SERVICED_APARTMENTS = 'Serviced Apartments',
  SHELTERED_HOUSING = 'Sheltered Housing',
  SMALLHOLDING = 'Smallholding',
  STONE_HOUSE = 'Stone House',
  STUDIO = 'Studio',
  TERRACED = 'Terraced',
  TERRACED_BUNGALOW = 'Terraced Bungalow',
  TOWN_HOUSE = 'Town House',
  TRIPLEX = 'Triplex',
  VILLA = 'Villa',
  VILLAGE_HOUSE = 'Village House'
}
