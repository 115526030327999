import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChildAuthCompleteComponent } from './components/child-auth-complete/child-auth-complete.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { LandingComponent } from './components/landing/landing.component';
import { container } from 'tsyringe';
import { ConsoleConfig } from '@config';
import { ConsolePageType } from '../../shared/enum/console-page-type';
import { WaitingForAuthComponent } from './components/waiting-for-auth/waiting-for-auth.component';
import { ValuationComponent } from './components/valuation/valuation.component';
import { PropertySearchComponent } from './components/property-search/property-search.component';
import { PropertyDetailComponent } from './components/property-detail/property-detail.component';
import { AreaSearchComponent } from './components/area-search/area-search.component';
import { AreaDetailComponent } from './components/area-detail/area-detail.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { FavoritesComponent } from './components/favorites/favorites.component';

const consoleConfig = container.resolve<ConsoleConfig>(ConsoleConfig);

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    title: 'Estate Stats - Home'
  },
  {
    path: consoleConfig.getPathForPage(ConsolePageType.LANDING),
    component: LandingComponent,
    title: 'Estate Stats - Home'
  },

  {
    path: consoleConfig.getPathForPage(ConsolePageType.WAITING_FOR_AUTH),
    component: WaitingForAuthComponent,
    title: 'Estate Stats - Waiting for auth'
  },
  {
    path: consoleConfig.getPathForPage(ConsolePageType.CHILD_AUTH_COMPLETE),
    component: ChildAuthCompleteComponent,
    title: 'Estate Stats - Child auth complete'
  },

  {
    path: consoleConfig.getPathForPage(ConsolePageType.VALUATION),
    component: ValuationComponent,
    title: 'Estate Stats - Valuation',
    // canActivate: [AuthGuard]
  },
  {
    path: consoleConfig.getPathForPage(ConsolePageType.PROPERTY_SEARCH),
    component: PropertySearchComponent,
    title: 'Estate Stats - Property Search',
    // canActivate: [AuthGuard]
  },
  {
    path: consoleConfig.getPathForPage(ConsolePageType.PROPERTY_DETAIL),
    component: PropertyDetailComponent,
    title: 'Estate Stats - Property Details',
    // canActivate: [AuthGuard]
  },
  {
    path: consoleConfig.getPathForPage(ConsolePageType.AREA_SEARCH),
    component: AreaSearchComponent,
    title: 'Estate Stats - Area Search',
    // canActivate: [AuthGuard]
  },
  {
    path: consoleConfig.getPathForPage(ConsolePageType.AREA_DETAIL),
    component: AreaDetailComponent,
    title: 'Estate Stats - Area Details',
    // canActivate: [AuthGuard]
  },
  {
    path: consoleConfig.getPathForPage(ConsolePageType.LOGIN),
    component: LoginComponent,
    title: 'Estate Stats - Login'
  },
  {
    path: consoleConfig.getPathForPage(ConsolePageType.FAVORITES),
    component: FavoritesComponent,
    canActivate: [AuthGuard],
    title: 'Estate Stats - Favorites'
  },

  {
    path: '**',
    component: PageNotFoundComponent,
    title: 'Estate Stats - Page not found'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
