import { Component, Input, OnDestroy } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { SaleListingDTO } from '@dto';

@Component({
  selector: 'jumbo-property-information',
  templateUrl: './property-information.component.html',
  styleUrls: ['./property-information.component.scss']
})
export class PropertyInformationComponent implements OnDestroy {

  protected saleListing$: ReplaySubject<SaleListingDTO> = new ReplaySubject<SaleListingDTO>(1);
  private destroyed$: Subject<boolean> = new Subject<boolean>();


  @Input() set saleListing(value: SaleListingDTO) {
    this.saleListing$.next(value);
  }

  constructor() {
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }
}
