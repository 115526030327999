<div class="flex flex-col min-h-100 w-[48rem]">
  <div class="flex flex-col pt-5 pb-5">
    <div class="flex-row flex w-full">
      <h1>Your Favorites</h1>
    </div>
  </div>
  <div class="grow pb-10">
    <div *ngIf="(results$|async).length > 0"
         class="flex flex-col gap-10 shrink">
      <div *ngFor="let item of (results$|async)">
        <app-property-search-item [item]="item"></app-property-search-item>

      </div>
    </div>
    <div *ngIf="!(results$|async) || (results$|async).length < 1" class="h-100">
      <h2>No results for your search</h2>
    </div>
  </div>
  <mat-paginator [length]="(page$|async)?.maxResults"
                 class="w-[48rem] rounded-t"
                 [pageSize]="10"
                 [pageSizeOptions]="[10,25,50]"
                 (page)="pageEvent($event)"
                 aria-label="Select page">
  </mat-paginator>
</div>
