<div class="h-[18rem] w-100 flex place-content-center items-center">
  <mat-tab-group [selectedIndex]="selectedTabIndex" dynamicHeight
  (selectedTabChange)="handleSelectedTabChange($event)">
    <mat-tab headerless-tabs label="Choose provider">
      <div class="h-[18rem] flex-col">
      <h1>Please sign in</h1>
      <p class="pt-8">You must be signed in to {{reasonForOpen}}.</p>
      <div class="h-[6rem]">
      <img class="doubleSize cursor-pointer"
           (click)="googleLogin()"
           *ngIf="appService.isDarkMode$|async"
           src="assets/google-sign-in/btn_google_signin_dark_normal_web@2x.png"/>
      <img class="doubleSize cursor-pointer"
           (click)="googleLogin()"
           *ngIf="!(appService.isDarkMode$|async)"
           src="assets/google-sign-in/btn_google_signin_light_normal_web@2x.png"/>
      </div>
        <div class="flex-grow">&nbsp;</div>
      <button mat-raised-button
              (click)="cancelDialog()"
              class="w-32 min-w-32 p-1">Cancel</button>
      </div>
    </mat-tab>
    <mat-tab label="Second" >
      <h1>Signing you in</h1>
      <p>please wait...</p>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-tab>
    <mat-tab label="Third">
      <p>Welcome</p>
      <h1>{{(authService.user$|async)?.displayName}}</h1>
    </mat-tab>
  </mat-tab-group>

</div>


