import { Component, Input, OnDestroy } from '@angular/core';
import { combineLatest, Observable, ReplaySubject, Subject, takeUntil } from 'rxjs';
import { AppService } from '../../../services/app.service';
import { map } from 'rxjs/operators';
import { ChartData } from 'chart.js';
import { logger } from '@logging';

export type ChartValue = { key: string, value: number };
export type GaugeChartData = { values: ChartValue[], label: string};

@Component({
  selector: 'jumbo-gauge-chart',
  templateUrl: './gauge-chart.component.html',
  styleUrls: ['./gauge-chart.component.scss']
})
export class GaugeChartComponent implements OnDestroy {

  @Input() public set chartData(value: GaugeChartData) {
    this.chartValues$.next(value);
  }

  protected chartValues$: Subject<GaugeChartData> = new ReplaySubject<GaugeChartData>(1);
  protected chartData$: Observable<ChartData<"doughnut">>;
  protected chartOptions$: Observable<any>;

  private readonly MAX_VALUES: number = 3;

  private readonly DARK_MODE_COLOURS = [
    '#e08b1b', '#f327c9', '#27f3f3',
  ];
  private readonly LIGHT_MODE_COLOURS = [
    '#e08b1b', '#f327c9', '#27f3f3',
  ];
  private destroyed$: Subject<any> = new Subject<any>();


  constructor(appService: AppService) {
    this.chartData$ = combineLatest([appService.isDarkMode$, this.chartValues$]).pipe(
        takeUntil(this.destroyed$),
        map(([isDark, values]) => this.buildChartData(values, isDark))
    );
    this.chartOptions$ = appService.isDarkMode$.pipe(
        takeUntil(this.destroyed$),
        map(isDark => this.buildChartOptions(isDark))
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private buildChartData(data: GaugeChartData, isDarkMode: boolean): ChartData<'doughnut'> {
    let values = data.values.concat();
    if (values.length >= this.MAX_VALUES) {
      logger.error(`Too many values for chart. Max is ${this.MAX_VALUES}. Truncating values.`);
      values = values.slice(0, this.MAX_VALUES);
    }

    const colours = isDarkMode? this.DARK_MODE_COLOURS : this.LIGHT_MODE_COLOURS;

    return {
      labels: values.map(value => value.key),
      datasets:[ {
          data: values.map(value => value.value),
          backgroundColor: colours.slice(0, values.length),
          borderWidth: 0
        }]
    };
  }

  private buildChartOptions(isDarkMode: boolean): any {
    return {
      rotation: 270, // start angle in degrees
      circumference: 180, // sweep angle in degrees
      cutout: "90%"
    };
  }
}
