import humanFormat from 'human-format';


export type PostCodeReportType = 'yield'|'sale_price'|'let_price'|'sale_count'|'let_count'|'time_to_sell';

export function getPostCodeReportTypeValueFormatFunction(reportType: PostCodeReportType):(number) => string {
  switch (reportType) {
    case "yield":
      return formatYield;
    case "time_to_sell":
      return roundToString;
    case "let_price":
    case "sale_price":
      return formatPrice;
  }
  return (x:number) => {return x.toFixed(0)};
}

export function formatYield(value: number): string {
  return humanFormat(value*100)+'%';
}

export function formatPrice(value:number): string {
  if (isNaN(value)) {
    return '';
  }
  return '£'+humanFormat(value);
}

function roundToString(x:number): string {
  if (isNaN) {
    return '';
  }
  return Math.round(x).toString();
}
