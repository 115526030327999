import { Component } from '@angular/core';

@Component({
  selector: 'jumbo-area-detail',
  templateUrl: './area-detail.component.html',
  styleUrls: ['./area-detail.component.scss']
})
export class AreaDetailComponent {


  constructor() { }

}
