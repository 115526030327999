import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { ChannelMessage, ChannelMessageType, ChannelService } from '../../services/channel.service';
import { logger } from '@logging';

@Component({
  selector: 'jumbo-auth',
  templateUrl: 'child-auth-complete.component.html',
  styleUrls: ['child-auth-complete.component.scss'],
  providers: [ChannelService]
})
export class ChildAuthCompleteComponent implements OnInit, OnDestroy {
  public parentAuthenticated = false;
  private messageSubscription: Subscription;

  constructor(private readonly authService: AuthService,
              private readonly channelService: ChannelService) {
  }

  ngOnInit(): void {
    this.messageSubscription = this.channelService.subscribeToMessages(ChannelMessageType.PARENT_WINDOW_AUTHENTICATED)
        .subscribe(() => {
          logger.info(`Authenticated. Closing window`);
          this.parentAuthenticated = true;
          window.close();
        }, (e) => logger.error(e));
    timer(500, 500).subscribe(() => {
          this.sendCompleteMessage();
        }, (e) => logger.error(e)
    );
    this.sendCompleteMessage();
  }

  sendCompleteMessage(): void {
    logger.info(`Sending complete message`);
    this.channelService.sendMessage(new ChannelMessage(ChannelMessageType.CHILD_WINDOW_AUTHENTICATED));
  }

  ngOnDestroy(): void {
    this.messageSubscription.unsubscribe();
  }
}
