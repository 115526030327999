import { inject, singleton } from "tsyringe";
import { ISoldPropertyImporterConfig, ServerSettings } from './settings/server-settings';

@singleton()
export class SoldPropertyImporterConfig {
  public readonly settings: ISoldPropertyImporterConfig;

  constructor(@inject(ServerSettings) configSettings: ServerSettings) {
    this.settings = configSettings.settings.soldPropertyImporter;
  }
}
