  <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="example-radio-group"
          (change)="selectionChange()"
          [ngModel]="selectedItem">
    <div class="flex flex-col gap-0 pb-5">
      <div *ngFor="let option of config.options; let i= index"
           class="flex">
        <mat-radio-button [value]="option.itemValue"
                          class="flex radio"
                          (change)="selectItem(i, $event)">
          {{option.itemLabel}}
        </mat-radio-button>
      </div>
    </div>
  </mat-radio-group>

