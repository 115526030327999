import { DimensionRestrictionDTO } from "./dimension-restriction.dto";
import { isNull } from 'lodash';

const SEPARATOR = ',';

export class FilterDTO {

  static Serialise(filter:FilterDTO): string {
    return filter.restrictions.map(restriction => DimensionRestrictionDTO.Serialise(restriction)).join(SEPARATOR);
  }

  static Parse(value: String): FilterDTO {
    const items = value.split(SEPARATOR).map(item => DimensionRestrictionDTO.Parse(item));
    return new FilterDTO(items.filter(item => !isNull(item)));
  }

  static HumanReadable(value: FilterDTO): string {
    return ``;
  }

  static IsPopulated(value:FilterDTO): boolean {
    return value.restrictions.length !== 0;
  }


  constructor(public readonly restrictions: DimensionRestrictionDTO[]) {
  }

}

