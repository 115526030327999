import { Component, Input } from '@angular/core';
import { SaleSearchItemDTO, UpdateFavoritePropertyDTO } from '@dto';
import { ConsolePageType } from '@enum';
import { Router } from '@angular/router';
import { ConfigurationService } from '../../services/configuration.service';
import { isUndefined } from 'lodash';
import humanFormat from 'human-format';
import { ChartData } from 'chart.js';
import { filter, from, Subject, switchMap } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppService } from '../../services/app.service';
import { AuthService } from '../../services/auth.service';
import { PropertyService } from '../../services/property.service';
import { MatSnackBar } from '@angular/material/snack-bar';

type FavoriteState = 'PENDING'|'FAVORITE'|'NOT_FAVORITE';

@Component({
  selector: 'app-property-search-card',
  templateUrl: './property-search-card.component.html',
  styleUrls: ['./property-search-card.component.scss']
})
export class PropertySearchCardComponent {

  public chartOptions: any;
  private destroyed$: Subject<any> = new Subject<any>();

  public radarChartData: ChartData<'radar'>;

  private _item: SaleSearchItemDTO;

  private favoriteOverride: FavoriteState;

  get item(): SaleSearchItemDTO {
    return this._item;
  }

  get isFav():FavoriteState {
    if (this.favoriteOverride !== undefined) {
      return this.favoriteOverride;
    }
    return this.item.isFavorite?'FAVORITE':'NOT_FAVORITE';
  }

  @Input()
  set item(value: SaleSearchItemDTO) {
    this._item = value;
    this.radarChartData = this.buildChartData();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getHumanPrice(value:number):string {
    return `£${humanFormat(value)}`;
  }

  constructor(private readonly router:Router,
              private readonly config:ConfigurationService,
              public readonly appService:AppService,
              public readonly authService:AuthService,
              public readonly propertyService: PropertyService,
              private _snackBar: MatSnackBar) {
  }

  getRent(item: SaleSearchItemDTO) {
    if (isUndefined(item.estimatedLetPrice)) {
      return '?';
    }
    return this.getHumanPrice(Number((item.estimatedLetPrice/12.0).toPrecision(2)));
  }

  getPrice(item: SaleSearchItemDTO) {
    return this.getHumanPrice(Number(item.price.toPrecision(3)));
  }

  getYield(item: SaleSearchItemDTO): string {
    if (isUndefined(item.estimatedYield)) {
      return '?';
    }
    return (100*item.estimatedYield).toFixed(1);
  }

  goToProperty(item: SaleSearchItemDTO) {
    const base = this.config.getClientPath(ConsolePageType.PROPERTY_DETAIL);
    this.router.navigate([base, {id: item.id}]);
  }

  getTimeToSell(item: SaleSearchItemDTO): string {
    if (isUndefined(item.estimatedDaysToSell)) {
      return '?';
    }
    return item.estimatedDaysToSell.toFixed(0);
  }

  highlightSalePrice(item: SaleSearchItemDTO):boolean {
    const ratio = item.estimatedSalePrice / item.price;
    return ratio < 0.997;
  }

  highlightYield(item: SaleSearchItemDTO):boolean {
    return item.estimatedYield >= 0.065;
  }

  getExpectedSaleValue(item: SaleSearchItemDTO): string {
    if (isUndefined(item.estimatedSalePrice)) {
      return '?';
    }
    return this.getHumanPrice(Number(item.estimatedSalePrice.toPrecision(3)));
  }

  private buildChartData(): ChartData<'radar'> | null {
    const a = Math.random() * 10;
    const b = Math.random() * 10;
    const c = Math.random() * 10;
    return {
      labels: ['', ``, ``],
      datasets: [{
        data: [
          a,
          b,
          c
        ],
        label: '',
        borderWidth: 0,
        backgroundColor: ['#e08b1b', '#f327c9', '#25c5a7']
      }
      ]
    }
  }

  toggleFavorite() {
    if (this.isFav === 'PENDING') {
      return;
    }
    const newFavoriteState = this.isFav !== 'FAVORITE'
    const reason = `${newFavoriteState?'':'un'}favourite this property`;
    this.favoriteOverride = 'PENDING';
    this.authService.ensureLoggedInFromDialog(reason).pipe(
        take(1),
        filter(isLoggedIn => {
          if (!isLoggedIn) {
            this._snackBar.open('Could not add favourite since we could not sign you in.', 'OK', {
              duration: 5000,
            })
            this.favoriteOverride = undefined;
          }
          return isLoggedIn;
        }),
        switchMap(() => {
          return from(this.propertyService.markAsFavorite(
              new UpdateFavoritePropertyDTO(this.item.id, newFavoriteState)));
        }),
        map(isFavorite => {
          this.favoriteOverride = isFavorite?'FAVORITE':'NOT_FAVORITE';
        }),
    ).subscribe();
  }
}
