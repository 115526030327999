<div class="flex flex-row gap-5">
  <mat-select [(value)]="selectedMin"
              (selectionChange)="minSelectionChange()">
    <mat-option *ngFor="let option of minItems"
                [value]="option.itemValue">{{option.itemLabel}}</mat-option>
  </mat-select>

  <mat-select [(value)]="selectedMax"
              (selectionChange)="maxSelectionChange()">
    <mat-option *ngFor="let option of maxItems"
                [value]="option.itemValue">{{option.itemLabel}}</mat-option>
  </mat-select>
</div>
