import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { DimensionRestrictionDTO } from '@dto';
import { SearchFilterDropDownConfig, SearchFilterRadioConfig } from '../search-filter-item.vo';
import { ReplaySubject, Subject, takeUntil, tap } from 'rxjs';
import { logger } from '@logging';
import { map } from 'rxjs/operators';

@Component({
  selector: 'jumbo-search-filter-radio-item',
  templateUrl: './search-filter-radio-item.component.html',
  styleUrls: ['./search-filter-radio-item.component.scss']
})
export class SearchFilterRadioItemComponent implements AfterViewInit, OnDestroy {

  private destroy$: Subject<any> = new Subject<any>();
  private configChange$: Subject<any> = new Subject<any>();
  private loadRestriction$: ReplaySubject<DimensionRestrictionDTO> = new ReplaySubject<DimensionRestrictionDTO>();

  get config(): SearchFilterRadioConfig {
    return this._config;
  }

  @Input()
  set config(value: SearchFilterDropDownConfig) {
    this.configChange$.next(true);
    this._config = value;
    this.changeDetectorRef.detectChanges();
    this.selectedItem = value.options[value.defaultSelectedIndex].itemValue;
    this._config.restrictionToLoad$.pipe(
        takeUntil(this.destroy$),
        takeUntil(this.configChange$)
    ).subscribe(restriction =>this.loadRestriction$.next(restriction))
  }

  private _config: SearchFilterDropDownConfig;

  @Output()
  public restrictionChange: EventEmitter<DimensionRestrictionDTO> = new EventEmitter<DimensionRestrictionDTO>();

  public selectedItem: any;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
      this.destroy$.next(true);
  }

  ngAfterViewInit(): void {
    this.loadRestriction$.pipe(
        takeUntil(this.destroy$),
        map(restriction => this.loadRestriction(restriction)),
        tap(() => this.emitNewRestriction())
    ).subscribe();
    this.emitNewRestriction();
  }

  selectionChange() {
    this.emitNewRestriction();
  }

  selectItem(index: number, e:any):void {
    if (!e.source.checked) {
      return;
    }
    this.selectedItem = this.config.options[index].itemValue;
    this.emitNewRestriction();
  }

  emitNewRestriction() {
    this.restrictionChange.next( this.config.buildRestriction(this.selectedItem, this.selectedItem) );
  }

  private loadRestriction(restriction: DimensionRestrictionDTO) {
    const item = this.config.options.find(
        candidate => candidate.itemValue?
            (candidate.itemValue).toString()==restriction.serialisedRestrictionValue:
            'null' === restriction.serialisedRestrictionValue);
    if (!item) {
      logger.warn(`Cannot select item ${restriction.restrictionValue}`);
      return;
    }
    this.selectedItem = item.itemValue;
    this.changeDetectorRef.detectChanges();
  }
}
