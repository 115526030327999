<div class="relative w-full h-full">
  <div class="absolute top-0 left-0 right-0 bottom-0 items-center justify-center flex flex-row">
    <h2 class=" pt-3 flex grow-0 m-0">
      {{(chartValues$| async).label}}
    </h2>
  </div>
  <canvas baseChart class="absolute top-0 left-0 right-0 bottom-0"
          [data]="chartData$| async"
          [options]="chartOptions$|async"
          [type]="'doughnut'">
  </canvas>
</div>
