import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthStatusDTO, SessionUserDTO } from '@dto';
import { firstValueFrom, from, Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { AuthStatusType, ConsoleApiEndpoints, ConsolePageType } from '@enum';
import { map, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { logger } from '@logging';
import { ConfigurationService } from './configuration.service';
import { LoginDialogComponent, LoginDialogData } from '../components/login-dialog/login-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class AuthService {

  public readonly isAuthenticated$: Observable<boolean>;
  public readonly authStatus$: ReplaySubject<AuthStatusDTO> = new ReplaySubject<AuthStatusDTO>(1);
  public readonly user$: Observable<SessionUserDTO>;

  constructor(private readonly http: HttpClient,
              private readonly router: Router,
              private readonly config:ConfigurationService,
              public dialog: MatDialog) {
    this.isAuthenticated$ = this.authStatus$.pipe(
        map(status => status?.authStatus === AuthStatusType.AUTHENTICATED)
    );
    this.user$ = this.authStatus$.pipe(
        map(status => status?.sessionUser)
    );
    this.refreshAuthentication().then();
  }


  login(destinationPath?: string) {
    loginFromConfig(this.config, this.router, destinationPath)
  }

  async logout() {
    const req = this.http.get<AuthStatusDTO>(this.config.getAPIURL(ConsoleApiEndpoints.auth_release), {withCredentials: true});
    await firstValueFrom(req);
    await this.refreshAuthentication();
  }

  async refreshAuthentication():Promise<boolean> {
    logger.debug(`Refreshing auth`);
    const req = this.http.get<AuthStatusDTO>(this.config.getAPIURL(ConsoleApiEndpoints.auth_status), {withCredentials: true});
    try {
      const result = await firstValueFrom(req);
      this.authStatus$.next(result);
    } catch (e) {
      logger.error(e);
    }
    return await firstValueFrom(this.isAuthenticated$);
  }

  ensureLoggedInFromDialog(reason?: string): Observable<boolean> {
    // return this.loginFromDialog();
    return this.isAuthenticated$.pipe(
        take(1),
        switchMap((isAuthenticated) => {
          if (isAuthenticated) {
            return of(true);
          }
          return this.loginFromDialog(reason);
        })
    );
  }

  private loginFromDialog(reason?: string): Observable<boolean> {
    const data: LoginDialogData = {reasonForOpen: reason};
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      width: '400px',
      data,
      enterAnimationDuration: '300ms',
      exitAnimationDuration: '300ms'
    });

    return dialogRef.afterClosed().pipe(
        switchMap(() => from(this.refreshAuthentication())),
        switchMap(() => this.isAuthenticated$.pipe(take(1)))
    );
  }
}

export function loginFromConfig(config: ConfigurationService, router: Router, destinationPath?: string) {
  const navCommand: any[] = [config.getClientPath(ConsolePageType.WAITING_FOR_AUTH)];
  if (destinationPath) {
    navCommand.push({dest: destinationPath});
  }
  router.navigate(navCommand);
}
