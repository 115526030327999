<div class="p-5 w-100 flex flex-col">
  <h2>{{filterDescription$|async}}</h2>
  <table mat-table [dataSource]="rows$" matSort (matSortChange)="announceSortChange($event)"
         class="rounded">
    <ng-container matColumnDef="postCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number" class="px-3">
        Code
      </th>
      <td mat-cell *matCellDef="let row" class="px-3"> {{row.postCode}} </td>
    </ng-container>
    <ng-container matColumnDef="areaName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number" class="px-3 ">
        Area
      </th>
      <td mat-cell *matCellDef="let row" class="px-3"> {{row.areaName}} </td>
    </ng-container>
    <ng-container matColumnDef="region">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number" class="px-3 ">
        Region
      </th>
      <td mat-cell *matCellDef="let row" class="px-3"> {{row.region}} </td>
    </ng-container>
    <ng-container matColumnDef="yieldFraction">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number"
          class="px-3  text-right">
        Yield
      </th>
      <td mat-cell *matCellDef="let row" class="px-3 text-right"> {{getPercent(row.yieldFraction)}}</td>
    </ng-container>
    <ng-container matColumnDef="nationalYieldRatio">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number"
          class="px-3  text-right">
        Relative yield (national)
      </th>
      <td mat-cell *matCellDef="let row" class="px-3 text-right"> {{getPercent(row.nationalYieldRatio)}}</td>
    </ng-container>
    <ng-container matColumnDef="saleCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number"
          class="px-3  text-right">
        Sale count
      </th>
      <td mat-cell *matCellDef="let row" class="px-3 text-right"> {{row.saleCount}}</td>
    </ng-container>
    <ng-container matColumnDef="letCount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number"
          class="px-3  text-right">
        Let count
      </th>
      <td mat-cell *matCellDef="let row" class="px-3 text-right"> {{row.letCount}}</td>
    </ng-container>
    <ng-container matColumnDef="timeToSell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number"
          class="px-3  text-right">
        Time to sell (days)
      </th>
      <td mat-cell *matCellDef="let row" class="px-3 text-right"> {{round(row.timeToSell)}}</td>
    </ng-container>
    <ng-container matColumnDef="viewProperties">
      <th mat-header-cell *matHeaderCellDef class="px-3 text-right"></th>
      <td mat-cell *matCellDef="let row" class="px-3">
        <a (click)="viewProperties(row)" class="cursor-pointer">View Properties</a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns$|async"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns$|async;"></tr>
  </table>
</div>
