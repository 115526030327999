import { IEndpointRoute } from './shared-settings';
import { EnvironmentType, ReleaseType } from '@enum';
import * as unitTestConfig from './server/unit-test.json';
import * as productionConfig from './server/prod.json';
import * as devConfig from './server/dev.json';
import * as baseConfig from './server/base.json';

import { environment, releaseType } from "../environment/environment";
import { logger } from '@logging';
import { merge } from 'lodash';
import { singleton } from 'tsyringe';

export interface IServerConfig {
  database?: IDatabaseConfig;
  modelAPI?: IModelAPI;

  scraper?: IScraperConfig;
  directScraper?: IDirectScraperConfig;
  directScraperCommander?: IDirectScraperCommanderConfig;
  geoBuilder?: IGeoBuilderConfig;

  consolidator?: IConsolidatorConfig;
  scheduler?:ISchedulerConfig;

  extractScheduler?: IExtractSchedulerConfig;
  soldPropertyImporter?: ISoldPropertyImporterConfig;

  consoleAPISecrets?: IConsoleAPISecretsConfig;


  listingOptimiser?: IListingOptimiserConfig;
  listingFixer?: IListingFixerConfig;
  listingEnricher?: IListingEnricherConfig;
  dbDumper?: IDBDumperConfig;
}

export interface IDatabaseConfig {
  protocol: string;
  host: string;
  port?: number;
  databaseName?: string;
  user?: string;
  password?: string;
  connectionOptions?: string[];
}

export interface IConsoleAPISecretsConfig {
  google: {
    clientId: string,
    clientSecret: string
  }
  betaUsers?: string[];

}


export interface IModelAPI {
  protocol: string;
  host: string;
  routes: IEndpointRoute[];
}

export interface IScraperConfig {
}
export interface IDirectScraperConfig {
  batchSizeWhenNotCommanded: number;
  propertiesPerPage: number;
}


export interface IDirectScraperCommanderConfig {
  pubSubTopic: string;
  maximumSinceLastUpdateMin: number;
  scrapesPerFunction: number;
  maxActiveFunctions: number;
  importChunkSize: number;
  maxMessageDelayS: number;
  maxToleratedScrapeErrors: number;
  maxToleratedFailedErrors: number;
}



export interface ISchedulerConfig {
  targetIterationRuntimeS: number;
  maxFailedRuns: number;
}

export interface IConsolidatorConfig {
  dbIteratorBatchSize: number;
  soldDateMatchWindowDays: number;
}

export interface IGeoBuilderConfig {
  dataDownloadURL?: string;
  dataFilesRootPath: string;
  codePointsFileRoot: string;
  importBatchSize: number;
}


export interface ISoldPropertyImporterConfig {
  monthlyReportUrl: string;
  historicReportUrl: string;
  importBatchSize: number;
  checkIntervalDays: number;
  minimumYear?:number;
}

export interface IPostCodeRankingConfig {
  typeFactor: number,
  staleFactor: number,
  populationFactor: number
  populationDefaultFactor: number
}
export interface IExtractSchedulerConfig {
  minimumToleratedRequests: number;
  minimumAllowedExtractAgeM: number;
  creationBatchSize: number;
  querySelectors: {
    property: string[],
    search: string[]
  }
  postCodeRanking: IPostCodeRankingConfig
}



export interface IListingOptimiserConfig {
  candidateVisibilityChanges: number;
  candidateVisibilityChangesBatchSize: number;
  checkIntervalMinutes: number;
  processingBatchSize: number;
}

export interface IListingFixerConfig {
  batchSize: number;
}

export interface IListingEnricherConfig {
  maxAgeMin: number;
  batchSize: number;
  useAtlas: boolean;
}

export interface IDBDumperConfig {
  downloadPath: string;
  bucketName: string;
  dumpIntervalHours: number;
}

@singleton()
export class ServerSettings {
  readonly settings:IServerConfig

  private readonly serverConfigs = new Map<string, IServerConfig>([
    [ReleaseType.development, devConfig],
    [ReleaseType.production, productionConfig],
    [ReleaseType.unitTest, unitTestConfig],
  ]);

  constructor() {
    if (environment == EnvironmentType.browser) {
      throw new Error("Invalid environment for server settings");
    }
    this.settings = this.mergeConfigSettings<IServerConfig>(
        baseConfig, this.serverConfigs, releaseType);
    logger.info(`Using settings for release: ${releaseType} in environment: ${environment}`);
  }
  private mergeConfigSettings<T>(baseConfig:T, environmentToConfig:Map<string, T>, environment: string): T {
    const override = environmentToConfig.get(environment);
    return merge(baseConfig, override);
  }

}
