
<div class="flex flex-col w-full h-full z-0">
  <div class="map-container z-0">
    <div class="mapbox-container z-0" #mapboxContainer id="mapboxContainer">

    </div>
    <div [ngClass]="'legend '+((isDark$|async)?'legend-dark':'legend-light')"
         class="w-20 h-28 flex flex-col gap-3 p-3">
      <div>{{legendLabel$|async}}</div>
      <div class="flex flex-row grow justify-items-stretch gap-2 flex-nowrap">
        <div class="relative flex flex-col grow fill place-content-center">
          <div class="flex-none">{{max}}</div>
          <div class="grow">&nbsp;</div>
          <div class="flex-none">{{min}}</div>
        </div>
        <div class="w-5 grow" [style.background]="gradientBackground">
          &nbsp;
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="activeArea">
    <app-area-search-area-widget class="w-100" [area]="activeArea"
                                 [rootArea]="rootArea"
                                 [humanReadableSearch]="humanReadableSearch$|async"
                                 (viewProperties)="navigateToProperties($event)"
                                 (close)="closeAreaSearch()">
    </app-area-search-area-widget>
  </div>

</div>
<div class="absolute top-[1rem] right-5 flex flex-row gap-5 flex-row items-center left-[50rem] justify-end z-10">
    <div>{{capitalCase(filterDescription$|async)}}</div>
    <mat-select [(value)]="reportType" class="w-36">
      <mat-option *ngFor="let item of reportTypeOptions" value="{{item.value}}">
        {{item.label}}
      </mat-option>
    </mat-select>
</div>
<div class="absolute bottom-0 left-0 w-full"
     *ngIf="isLoading$|async">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>
