<div class="flex-row flex w-full px-3">
  <mat-slider [min]="config.minValue"
              [max]="config.maxValue"
              [step]="1"
              class="slider w-full pl-0 pr-0"
              [discrete]="true"
              name="slider"
              (change)="commitValue()">
    <input matSliderStartThumb [(ngModel)]="min"
           class="pl-0 pr-0 ml-3">
    <input matSliderEndThumb [(ngModel)]="max"
           class="pl-0 pr-0 ml-3">
  </mat-slider>
</div>
