<jumbo-search-filter id="area-search-filter"
                     title="Area search"
                     [filterItems]="filterOptions"
                     (filter)="filter$.next($event)"
                     (description$)="filterDescription$.next($event)"></jumbo-search-filter>


<div class="flex flex-col w-full h-full tabs">
  <mat-tab-group class="flex h-full" animationDuration="0ms">
    <mat-tab label="Map" class="h-full w-full">
      <ng-template matTabContent>
        <jumbo-area-search-map [filterDescription]="filterDescription$|async"
                               [reportFilter]="filter$|async"
                               class="h-full"></jumbo-area-search-map>
      </ng-template>
    </mat-tab>
    <mat-tab label="List" class="h-full">
      <ng-template matTabContent>
        <jumbo-area-search-list [filterDescription]="filterDescription$|async"
                                [reportFilter]="filter$|async"
                                class="h-full">
        </jumbo-area-search-list>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
