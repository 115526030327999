import { inject, singleton } from 'tsyringe';
import { IConsoleAPIConfig, IEndpointRoute, SharedSettings } from './settings/shared-settings';
import { ConsoleApiEndpoints, EnvironmentType, ReleaseType } from '@enum';
import { isString } from 'lodash';
import { logger } from '@logging';
import { environment, releaseType } from './environment/environment';

@singleton()
export class ConsoleApiConfig {
  public readonly consoleApiSettings: IConsoleAPIConfig;
  private readonly routeByName: Map<string, IEndpointRoute>;



  public get internalPort(): number|null {
    let result;
    if ((environment === EnvironmentType.node)
        && (releaseType === ReleaseType.production)
        // @ts-ignore
        && (process.env.PORT)) {
      //special case where we run in a docker container
      // @ts-ignore
      result = process.env.PORT;
    } else {
      result = this.consoleApiSettings.port;
    }
    return isString(result)?parseInt(result):result;
  }

  public get externalPort(): number|null {
    const result = this.consoleApiSettings.port;
    return isString(result)?parseInt(result):result;
  }

  public getBaseUri(): string {
    let result = `${this.consoleApiSettings.protocol}://${this.consoleApiSettings.host}`;
    if (this.externalPort) {
      result += `:${this.externalPort.toString(10)}`;
    }
    return result;
  }

  public getUrl(endpointName: ConsoleApiEndpoints): string {
    const route = this.routeByName.get(ConsoleApiEndpoints[endpointName]);
    if (!route) {
      throw new Error(`Endpoint for ${endpointName} is not configured`);
    }
    return this.getBaseUri()+route.prefix;
  }

  constructor(@inject(SharedSettings) sharedSettings: SharedSettings){
    if (!sharedSettings.settings.consoleAPI) {
      throw new Error(`consoleAPI not configured`);
    }
    this.consoleApiSettings = sharedSettings.settings.consoleAPI;
    this.routeByName = new Map(this.consoleApiSettings.routes.map(route => [route.name, route]));
    for (const name in ConsoleApiEndpoints) {
      if (!this.routeByName.has(name)) {
        logger.error(`Route for ${name} is not configured and may lead to runtime errors`);
      }
    }

  }
}
