import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { AppService } from '../../services/app.service';
import { delay, distinctUntilChanged, filter, from, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { ConsoleApiEndpoints } from '@enum';
import { ConfigurationService } from '../../services/configuration.service';
import { map, switchMap, take } from 'rxjs/operators';
import { ChannelMessage, ChannelMessageType, ChannelService } from '../../services/channel.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';

export interface LoginDialogData {
  reasonForOpen?: string;
}

@Component({
  selector: 'jumbo-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
  providers: [ChannelService]
})
export class LoginDialogComponent implements OnInit, OnDestroy {

  get reasonForOpen():string {
    if (this.data && this.data.reasonForOpen) {
      return this.data.reasonForOpen;
    }
    return 'perform this operation';
  }

  readonly OPEN_WINDOW_TAB_INDEX = 1;
  readonly openWindowTabActive$: Subject<boolean> = new Subject();
  selectedTabIndex: number = 0;
  private readonly destroyed$: Subject<boolean> = new Subject<boolean>();
  private channelSubscription$: Subscription;

  constructor(public readonly authService: AuthService,
              public readonly appService: AppService,
              private readonly config: ConfigurationService,
              private readonly channelService: ChannelService,
              private readonly changeDetectorRef: ChangeDetectorRef,
              public readonly dialogRef: MatDialogRef<LoginDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: LoginDialogData) {
    this.data = data;
  }

  ngOnInit(): void {
    this.openWindowTabActive$.pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged(),
        map(isActive => this.handleChannelSubscription(isActive))
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  googleLogin() {
    this.selectedTabIndex = 1;
  }

  loginComplete() {
    this.selectedTabIndex = 2;
    this.changeDetectorRef.detectChanges();
    this.dialogRef.close(true);
  }

  cancelDialog() {
    this.dialogRef.close(false);
  }

  handleSelectedTabChange($event: MatTabChangeEvent) {
    const val = $event.index == this.OPEN_WINDOW_TAB_INDEX
    this.openWindowTabActive$.next(val);
  }

  openChildWindow(): void {
    window.open(this.config.getAPIURL(ConsoleApiEndpoints.auth_google),
        'com_estatestats_child_auth_login', 'height=600,width=600');
  }

  private handleChannelSubscription(isActive: boolean) {
    if (isActive) {
      this.subscribeToChannelService();
    } else {
      this.unsubscribeFromChannelService();
    }
  }

  private subscribeToChannelService() {
    const RETRY_ATTEMPTS = 5;
    const attempt$: Subject<boolean> = new Subject<boolean>();
    const concluded$: Subject<boolean> = new Subject<boolean>();

    attempt$.pipe(
        take(RETRY_ATTEMPTS),
        tap(() => {
          this.loginFailed();
          concluded$.next(true);
        })
    );

    this.channelSubscription$ = this.channelService.subscribeToMessages(
        ChannelMessageType.CHILD_WINDOW_AUTHENTICATED).pipe(
        takeUntil(this.destroyed$),
        takeUntil(concluded$),
        tap(() => attempt$.next(true)),
        switchMap((attempt) => {
          return from(this.authService.refreshAuthentication());
        }),
        filter(val => val === true),
        delay(300),
        takeUntil(concluded$),
        tap(() =>
            this.channelService.sendMessage(new ChannelMessage(ChannelMessageType.PARENT_WINDOW_AUTHENTICATED))),
        tap(() => this.loginComplete()),
        tap(() => concluded$.next(true)),
    ).subscribe();
    this.openChildWindow();
  }

  private unsubscribeFromChannelService() {
    this.channelSubscription$.unsubscribe();
  }

  private loginFailed():void {
    this.selectedTabIndex = 1;
    this.dialogRef.close(false);
  }
}
