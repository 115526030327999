<!--<nav class="navbar navbar-light h-full">-->
<div class="w-full flex flex-row gap-4 h-full items-left jumbo-menu p-5"
     [ngClass]="[(appService.isDarkMode$|async)?'menu-dark':'menu-light']">
  <div class="cursor-pointer flex flex-row" (click)="iconClick()"
       matTooltip="Home" matTooltipPosition="below">
    <img *ngIf="appService.isDarkMode$|async" src="assets/logo/landscape-dark.svg" class="h-8 w-48"/>
    <img *ngIf="!(appService.isDarkMode$|async)" src="assets/logo/landscape-light.svg" class="h-8 w-48"/>
  </div>

  <!--        <form class="d-flex input-group w-auto">-->
  <!--            <input-->
  <!--                    type="search"-->
  <!--                    type="search"-->
  <!--                    class="form-control rounded"-->
  <!--                    placeholder="Search"-->
  <!--                    aria-label="Search"-->
  <!--            />-->
  <!--            <span class="input-group-text border-0" id="search-addon">-->
  <!--                <i class="fas fa-search"></i>-->
  <!--              </span>-->
  <!--        </form>-->

  <div class="grow h-full"></div>

  <button mat-button (click)="valuationClick()"
          mat-raised-button
          color="primary"
          class="text-left px-3"
          matTooltip="Value any property"
          matTooltipPosition="below">
    <span class="material-icons">currency_pound</span>
    <span class="pr-3">Valuate your property</span>
  </button>

  <button mat-button (click)="favoritesClick()"
          class="text-left px-3"
          matTooltip="View your favorites"
          matTooltipPosition="below">
        <span matBadge="{{favoritesCount$|async}}"
              matBadgePosition="below after"
              matBadgeColor="primary"
              matBadgeSize="small"
              class="heart-icon material-icons">favorite</span>
    <span class="pl-1">Favourites</span>
  </button>

  <!--    <button mat-button (click)="propertySearchClick()"-->
  <!--            class="text-left px-3"-->
  <!--            matTooltip="Find a property"-->
  <!--            matTooltipPosition="below">-->
  <!--        -->
  <!--        <span>Properties</span>-->
  <!--    </button>-->

  <!--    <button mat-button (click)="areaSearchClick()"-->
  <!--            class="text-left px-3"-->
  <!--            matTooltip="Search and rank areas"-->
  <!--            matTooltipPosition="below">-->
  <!--        <mat-icon>map</mat-icon>-->
  <!--        <span>Areas</span>-->
  <!--    </button>-->

  <div class="flex items-center px-3 py-0 gap-2">

    <button *ngIf="(isSignedIn$|async)" [matMenuTriggerFor]="userMenu">
      <div class="flex-row flex gap-4 align-middle">
        <img class="profile-image cursor-pointer" [src]="(imageUrl$|async)"/>
        <div class="place-self-center">{{(authService.user$|async)?.displayName}}</div>
      </div>
    </button>


    <button *ngIf="!(isSignedIn$|async)" (click)="login()">
      <div class="flex-row flex gap-4">
        <span class="material-icons">account_circle</span>
        <div class="place-self-center">Sign in</div>
      </div>
    </button>

    <mat-menu #userMenu="matMenu">
      <div class="flex flex-col gap-5 w-[15rem] h-[25rem] p-3">
        <button *ngIf="!(isSignedIn$|async)"
                mat-menu-item (click)="login()">Sign in
        </button>
        <div *ngIf="(isSignedIn$|async)">
          <button mat-menu-item (click)="favoritesClick()">
            <span class="material-icons">favorite</span>
            My favorite properties
          </button>
          <button mat-menu-item (click)="propertySearchClick()">
            <span class="material-icons">search</span>
            Search properties
          </button>
          <button mat-menu-item (click)="areaSearchClick()">
            <span class="material-icons">map</span>
            Explore areas
          </button>
        </div>


        <div class="grow"></div>


        <div *ngIf="(isSignedIn$|async)">
          <button mat-menu-item disabled="true" (click)="favoritesClick()">Preferences</button>
        </div>


        <!-- dark mode -->
        <div class="flex items-center px-3 gap-2">
          <span class="material-icons">sunny</span>
          <mat-slide-toggle #darkModeToggle [(ngModel)]="darkModeSelected"
                            [matTooltip]="'Switch to '+((appService.isDarkMode$|async)?'light':'dark')+' mode'"
          ></mat-slide-toggle>
          <span class="material-icons">nightlight</span>
        </div>
        <button *ngIf="(isSignedIn$|async)"
                mat-menu-item (click)="logout()">Sign out
        </button>
      </div>
    </mat-menu>
  </div>

</div>
<!--</nav>-->
