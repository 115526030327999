import { Injectable } from '@angular/core';
import { container } from 'tsyringe';
import { ConsoleApiConfig, ConsoleConfig } from '@config';
import { ConsoleApiEndpoints, ConsolePageType } from '@enum';
import { forOwn } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  private readonly apiConfig: ConsoleApiConfig;
  private clientConfig: ConsoleConfig;

  constructor() {
    this.apiConfig = container.resolve<ConsoleApiConfig>(ConsoleApiConfig);
    this.clientConfig = container.resolve<ConsoleConfig>(ConsoleConfig);
  }

  public getAPIURL(endpoint:ConsoleApiEndpoints, params?:any): string {
    let result = this.apiConfig.getUrl(endpoint);
    if (params) {
      forOwn(params, (value, key) => {
        result = result.replace(':' + key, value);
      });
    }
    return result;
  }

  public getClientPath(client:ConsolePageType): string {
    return this.clientConfig.getPathForPage(client);
  }

  public getClientURL(client:ConsolePageType): string {
    return this.clientConfig.getURIForPage(client);
  }
}
