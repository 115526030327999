<div class="flex flex-col">
  <h2>Investment</h2>
  <div class="flex flex-row">
    <div class="flex-col items-center h-[12rem] w-[12rem]">
      <h2>Price</h2>
      <h2>{{humanReadableSalePrice((saleListing$|async).price)}}</h2>
    </div>
    <div class="flex-col items-center h-[12rem] w-[12rem]">
      <h2>Estimated Price</h2>
      <h2>{{humanReadableSalePrice((saleListing$|async).estimatedSalePrice)}}</h2>
    </div>
    <div class="flex-col items-center h-[12rem] w-[12rem]">
      <h2>Let for</h2>
      <h2>{{annualRentToMonthlyHumanReadable((saleListing$|async).estimatedLetPrice)}} / month</h2>
    </div>
    <div class="flex-col items-center h-[12rem] w-[12rem]">
      <h2>Estimated Yield</h2>
      <h2>{{fractionToPercent((saleListing$|async).estimatedYield)}} / month</h2>
    </div>
  </div>
  <div class="flex-col items-center h-[12rem] w-[24rem]">
    <h2>Price History</h2>
    <div class="flex flex-row w-full h-full items-center">
      <canvas baseChart [type]="'line'" class="w-full h-full"
              [data]="priceHistoryData$|async"
              [options]="priceHistoryOptions$|async"></canvas>
    </div>
  </div>

</div>

