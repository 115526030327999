import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeadingComponent } from './components/heading/heading.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppService } from './services/app.service';
import { LandingComponent } from './components/landing/landing.component';
import { ChildAuthCompleteComponent } from './components/child-auth-complete/child-auth-complete.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { WaitingForAuthComponent } from './components/waiting-for-auth/waiting-for-auth.component';
import { ValuationComponent } from './components/valuation/valuation.component';
import { PropertySearchComponent } from './components/property-search/property-search.component';
import { PropertyDetailComponent } from './components/property-detail/property-detail.component';
import { AreaSearchComponent } from './components/area-search/area-search.component';
import { AreaDetailComponent } from './components/area-detail/area-detail.component';
import { PropertySearchItemComponent } from './components/property-search-item/property-search-item.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatDividerModule } from "@angular/material/divider";
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FrillWidgetComponent } from './components/frill-widget/frill-widget.component';
import { LoginComponent } from './components/login/login.component';
import { AuthInterceptor } from './services/auth.interceptor';
import * as Sentry from "@sentry/angular";
import { Router } from '@angular/router';
import { ConfigurationService } from './services/configuration.service';
import { GALLERY_CONFIG, GalleryModule } from 'ng-gallery';
import { MatExpansionModule } from '@angular/material/expansion';
import { AreaSearchAreaWidgetComponent } from "./components/area-search-area-widget/area-search-area-widget.component";
import { ValueComparisonCardComponent } from "./components/value-comparison-card/value-comparison-card.component";
import { ValueChartComponent } from "./components/value-chart/value-chart.component";
import { AreaSearchListComponent } from "./components/area-search-list/area-search-list.component";
import { AreaSearchMapComponent } from "./components/area-search-map/area-search-map.component";
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { MatSortModule } from "@angular/material/sort";
import { SearchFilterItemComponent } from './components/search-filter/search-filter-item/search-filter-item.component';
import {
  SearchFilterDropDownItemComponent
} from './components/search-filter/search-filter-item/drop-down/search-filter-drop-down-item.component';
import {
  SearchFilterNumericSliderItemComponent
} from './components/search-filter/search-filter-item/numeric-slider/search-filter-numeric-slider-item.component';
import {
  SearchFilterTextItemComponent
} from './components/search-filter/search-filter-item/text/search-filter-text-item.component';
import { NgChartsModule } from 'ng2-charts';
import {
  PropertyPriceWidgetComponent
} from './components/widgets/property-detail/property-price/property-price-widget.component';
import {
  PropertyPhotosWidgetComponent
} from './components/widgets/property-detail/property-photos/property-photos-widget.component';
import {
  PropertyYieldWidgetComponent
} from './components/widgets/property-detail/property-yield/property-yield-widget.component';
import {
  PropertyDescriptionWidgetComponent
} from './components/widgets/property-detail/property-description/property-description-widget.component';
import {
  PropertyTimeToSellWidgetComponent
} from './components/widgets/property-detail/time-to-sell/property-time-to-sell-widget.component';
import {
  SearchFilterRadioItemComponent
} from './components/search-filter/search-filter-item/radio/search-filter-radio-item.component';
import {
  SearchFilterCheckboxItemComponent
} from './components/search-filter/search-filter-item/checkbox/search-filter-checkbox-item.component';
import {
  SearchFilterDropDownNumericRangeItemComponent
} from './components/search-filter/search-filter-item/drop-down-numeric-range/search-filter-drop-down-numeric-range-item.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { PropertySearchCardComponent } from './components/property-search-card/property-search-card.component';
import {
  SearchFilterNumericRangeSliderItemComponent
} from './components/search-filter/search-filter-item/numeric-range-slider/search-filter-numeric-range-slider-item.component';
import { PropertyLabelComponent } from './components/property-label/property-label.component';
import { ErrorInterceptor } from './services/error.interceptor';
import { EstateAgentReportComponent } from './components/estate-agent-report/estate-agent-report.component';
import { UserSettingsService } from './services/user-settings.service';
import { AreaReportComponent } from './components/area-report/area-report.component';
import { GaugeChartComponent } from './components/charts/gague-chart/gauge-chart.component';
import { MultiGaugeChartComponent } from './components/charts/multi-gague-chart/multi-gauge-chart.component';
import { InvestmentReportComponent } from './components/investment-report/investment-report.component';
import { PropertyInformationComponent } from './components/property-information/property-information.component';

@NgModule({
  declarations: [
    AppComponent,
    HeadingComponent,
    LandingComponent,
    LoginComponent,
    LoginDialogComponent,
    PageNotFoundComponent,
    ChildAuthCompleteComponent,
    WaitingForAuthComponent,
    ValuationComponent,
    PropertySearchComponent,
    PropertyDetailComponent,
    AreaSearchComponent,
    AreaSearchAreaWidgetComponent,
    AreaDetailComponent,
    EstateAgentReportComponent,
    GaugeChartComponent,
    MultiGaugeChartComponent,
    AreaReportComponent,
    PropertyInformationComponent,
    InvestmentReportComponent,
    FavoritesComponent,
    PropertySearchItemComponent,
    PropertyLabelComponent,
    PropertySearchCardComponent,
    FrillWidgetComponent,
    ValueComparisonCardComponent,
    ValueChartComponent,
    AreaSearchListComponent,
    AreaSearchMapComponent,
    SearchFilterComponent,
    SearchFilterCheckboxItemComponent,
    SearchFilterItemComponent,
    SearchFilterDropDownItemComponent,
    SearchFilterDropDownNumericRangeItemComponent,
    SearchFilterRadioItemComponent,
    SearchFilterNumericSliderItemComponent,
    SearchFilterNumericRangeSliderItemComponent,
    SearchFilterTextItemComponent,
    PropertyPriceWidgetComponent,
    PropertyPhotosWidgetComponent,
    PropertyYieldWidgetComponent,
    PropertyDescriptionWidgetComponent,
    PropertyTimeToSellWidgetComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    GalleryModule,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: release === ReleaseType.production,
    //   // Register the ServiceWorker as soon as the app is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // }),
    HttpClientModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatDialogModule,
    MatInputModule,
    MatToolbarModule,
    MatIconModule,
    MatSlideToggleModule,
    MatTableModule,
    MatBadgeModule,
    MatButtonModule,
    MatSidenavModule,
    MatDividerModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSortModule,
    MatSliderModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatTabsModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatCheckboxModule,
    NgChartsModule,
    MatButtonToggleModule,
    MatMenuModule,
    MatChipsModule,
  ],
  providers: [
    AuthService,
    AppService,
    ConfigurationService,
    UserSettingsService,
    {
      provide: GALLERY_CONFIG,
      useValue: {
        dots: true,
        imageSize: 'cover'
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
