<div class="relative h-[8rem] w-full select-none">

  <div class="absolute rounded top-0 left-0 right-0 bottom-0"
       [class]="(appService.isDarkMode$|async)?'background-dark':'background-light'">
  </div>

  <div class="absolute gap-5 flex top-0 left-0 right-0 bottom-0 flex-row cursor-pointer pointer-events-auto"
       (click)="goToProperty(item)">

    <img class="rounded-l object-cover h-[8rem] w-[12rem] grow-0 shrink-0"
         src="{{item.imageUrl}}" alt="property image"/>


    <div class="flex-[1_1_25%] flex-col py-5">
      <div class="">Expected</div>
      <div class="text-3xl"
           [class]="highlightSalePrice(item)?((appService.isDarkMode$|async)?'accent-dark':'accent-light'):''">{{getExpectedSaleValue(item)}}</div>
      <span class="pb-1">{{item.bedrooms}} bed {{item.propertySubType}}</span>
      <div>{{item.areaName}} / {{item.areaRegion}}</div>
    </div>

    <div class="flex-[1_1_25%] flex-col py-5">
      <div class="">Listed</div>
      <div class="text-3xl">{{getPrice(item)}}</div>
    </div>

    <div class="flex-[1_1_25%] flex-col py-5">
      <div class="">Yield</div>
      <div class="text-3xl"
           [class]="highlightYield(item)?((appService.isDarkMode$|async)?'accent-dark':'accent-light'):''">{{getYield(item)}}
        %
      </div>
      <div>Let at {{getRent(item)}} / mo</div>
    </div>


    <!--      <div class="flex-row">-->
    <!--        <div class="w-24 h-24">-->
    <!--          <canvas baseChart class="relative top-0 left-0 right-0 bottom-0"-->
    <!--                  [data]="radarChartData"-->
    <!--                  [options]="chartOptions"-->
    <!--                  [type]="'radar'">-->
    <!--          </canvas>-->
    <!--        </div>-->

    <!--      </div>-->
  </div>
  <!-- fav -->
  <div class="absolute top-0 right-0 bottom-0 left-0 flex-row rounded overlay select-none"
       [ngSwitch]="isFav">

    <div *ngSwitchCase="'PENDING'" class="absolute top-0 right-0 bottom-0 left-0">
      <div class="absolute top-2 right-2 heart-icon-pending material-icons"
           (click)="toggleFavorite()">favorite
      </div>
    </div>

    <!-- is favorite -->
    <div *ngSwitchCase="'FAVORITE'" class="absolute top-0 right-0 bottom-0 left-0">
      <span class="absolute top-2 right-2 heart-icon material-icons cursor-pointer pointer-events-auto"
            (click)="toggleFavorite()">favorite</span>
    </div>

    <!-- not yet favorite -->
    <div *ngSwitchCase="'NOT_FAVORITE'"
         class="not-heart absolute top-0 right-0 h-[7rem] w-[7rem] rounded overflow-hidden pointer-events-auto">
      <div class="triangle h-[6rem] w-[6rem] absolute overflow-hidden">
        <div class="triangle-sheen-1 absolute top-0 right-0 bottom-0 left-0"></div>
        <div class="triangle-sheen-2 absolute top-0 right-0 bottom-0 left-0"></div>
      </div>
      <span class="absolute not-heart-icon material-symbols-outlined top-2 right-2 cursor-pointer"
            (click)="toggleFavorite()">favorite</span>
    </div>
  </div>

  <div class="absolute flex justify-end gap-3 w-[12rem] right-2 bottom-2 flex-row overlay select-none">
    <div class="flex" *ngFor="let label of item.labels">
      <app-property-label [label]="label"></app-property-label>
    </div>
  </div>
</div>
