import { DimensionNames, PropertySubTypeGeneralisation } from '@enum';
import { logger } from '@logging';
import { sortBy } from 'lodash';
import humanFormat from 'human-format';

export type DimensionRestrictionType = "ge" | "le" | "gt" | "lt" | "eq" | "in" | 'like' | 'range';

type RangeValue = {min?:number|null, max?:number|null};

const RestrictionTypeToSerialised:[DimensionRestrictionType, string][] = [
    ['ge', ']'],
    ['le', '['],
    ['gt', '>'],
    ['lt', '<'],
    ['eq', ':'],
    ['in', '+'],
    ['like', '~'],
    ['range', '/']
];

export class DimensionRestrictionDTO {
  constructor(public readonly dimensionName: DimensionNames,
              public readonly restrictionType: DimensionRestrictionType,
              public readonly restrictionValue: any,
              public readonly serialisedRestrictionValue: string) {
  }

  static Serialise(restriction: DimensionRestrictionDTO): string {
    if (!restriction) {
      return '';
    }
    let result = restriction.dimensionName;
    result += RestrictionTypeToSerialised.find(([restrictionType, symbol]) => restrictionType == restriction.restrictionType)[1];
    result += restriction.serialisedRestrictionValue;
    return result;
  }

  static Parse(source: string): DimensionRestrictionDTO|null {
    const indices = RestrictionTypeToSerialised.map(([restrictionType, symbol]) => source.indexOf(symbol));
    const sorted = sortBy( indices.filter(k => k > -1) );
    if (sorted.length < 1) {
      logger.warn(`Cannot parse dimension restriction ${source}, no restriction type found`);
      return null;
    }
    const index = sorted[0];
    const dimensionName = source.substring(0, index) as DimensionNames;
    const restrictionValue = source.substring(index+1);
    const symbol = source.substring(index, index+1);
    const restrictionType = RestrictionTypeToSerialised.find(([candidate, needle]) => needle == symbol)[0];
    return new DimensionRestrictionDTO(dimensionName, restrictionType, undefined, restrictionValue);
  }

  static HumanReadable(restrictions: DimensionRestrictionDTO[]): string {
    let result = '';
    result += this.getBedrooms(restrictions);
    result += (result.length> 0? ' ':'all ') + this.getPropertyType(restrictions);
    result += this.getPrice(restrictions);
    result += this.getLocation(restrictions);
    result += this.getYield(restrictions);
    return result;
  }

  private static getBedrooms(restrictions: DimensionRestrictionDTO[]): string {
    const bedrooms = restrictions.filter(restriction => restriction.dimensionName == 'bedrooms');
    if (bedrooms.length == 0) {
      return '';
    }
    const bedsIn = bedrooms.filter(restriction => restriction.restrictionType == 'range');
    if (bedsIn.length == 1) {
      const range = (bedsIn[0].restrictionValue as RangeValue);
      if (range.min && range.max) {
        if (range.min == range.max) {
          return `${range.min} bedroom`
        }
        return `${range.min} - ${range.max} bedroom`;
      }
      if (range.min) {
        return `over ${range.min} bedroom`;
      }
      if (range.max) {
        return `under ${range.max} bedroom`;
      }
    }
    const bedsEq = bedrooms.filter(restriction => restriction.restrictionType == 'eq');
    if (bedsEq.length == 1) {
      return `${bedsEq[0].restrictionValue} bedroom`;
    }
    return '';
  }

  private static getPropertyType(restrictions: DimensionRestrictionDTO[]): string {
    const inAllProperties = 'properties';
    const type = restrictions.filter(restrictions => restrictions.dimensionName == 'propertySubType');
    if (type.length == 0) {
      return inAllProperties;
    }
    switch(type[0].restrictionValue) {
      case PropertySubTypeGeneralisation.FLAT:
        return 'flats';
      case PropertySubTypeGeneralisation.HOUSE:
        return 'houses';
      case PropertySubTypeGeneralisation.OTHER:
        return 'other properties';
      case null:
        return inAllProperties;
    };
    return inAllProperties;
  }

  private static getYield(restrictions: DimensionRestrictionDTO[]):string {
    const yieldRestriction = restrictions.filter(restrictions => restrictions.dimensionName == 'estimatedYield');
    if (yieldRestriction.length == 0) {
      return '';
    }
    if (yieldRestriction[0].restrictionValue == 0) {
      return '';
    }
    return `, yield above ${Math.round(yieldRestriction[0].restrictionValue*100)}%`;
  }

  private static getLocation(restrictions: DimensionRestrictionDTO[]):string {
    const locationDimension = restrictions.filter(res => res.dimensionName == 'location');
    if (locationDimension.length == 0) {
      return '';
    }
    const location:{name?:string, subname?:string} = locationDimension[0].restrictionValue;
    let result: string = location.name as string;
    if (result.length < 5 && location.subname) {
      result += ' ('+location.subname+')';
    }
    return ` within ${result}`;
  }

  private static getPrice(restrictions: DimensionRestrictionDTO[]): string {
    const priceDim = restrictions.filter(res => res.dimensionName == 'price');
    if (priceDim.length == 0) {
      return '';
    }
    const range = priceDim[0].restrictionValue as RangeValue;
    if (range.min && range.max) {
      if (range.min == range.max) {
        return ` at £${humanFormat(range.min)}`
      }
      return ` between £${humanFormat(range.min)} and £${humanFormat(range.max)}`;
    }
    if (range.min) {
      return ` over £${humanFormat(range.min)}`;
    }
    if (range.max) {
      return ` under £${humanFormat(range.max)}`;
    }
    return '';
  }
}
