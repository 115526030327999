import { Injectable } from '@angular/core';

@Injectable()
export class UserSettingsService {

  get userAcceptsCookies():boolean {
    return localStorage.getItem('userAcceptsCookies') === 'true';
  }

  get darkMode():boolean {
    const mode:string|null = localStorage.getItem('darkMode')
    if (mode===null) {
      return false;
    }
    return mode==='true';
  }

  constructor() {
  }

  acceptCookies() {
    localStorage.setItem('userAcceptsCookies', 'true');
  }


  setDarkMode(value: boolean) {
    if (!this.userAcceptsCookies)
      return;
    localStorage.setItem('darkMode', value?'true':'false');
  }

}
