import { Component, Input, OnDestroy } from '@angular/core';
import { SaleSearchItemLabel } from '@dto';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-property-label',
  templateUrl: './property-label.component.html',
  styleUrls: ['./property-label.component.scss']
})
export class PropertyLabelComponent implements OnDestroy {

  public label$: BehaviorSubject<SaleSearchItemLabel> = new BehaviorSubject<SaleSearchItemLabel>('NEW');
  public icon$: Observable<string>;
  public tooltip$: Observable<string>;
  public description$: Observable<string>;

  private labelToIcon: Map<SaleSearchItemLabel, string> = new Map<SaleSearchItemLabel, string>([
    ['NEW', 'sell'],
    ['HIGH_YIELD', 'check'],
    ['DEVELOPMENT', 'construction'],
    ['STUDENT', 'school'],
    ['COMMERCIAL', 'storefront'],
    ['AUCTION', 'gavel'],
  ]);
  private labelToTooltip: Map<SaleSearchItemLabel, string> = new Map<SaleSearchItemLabel, string>([
    ['NEW', 'This property has only recently been listed. You may wish to act quickly on this property.'],
    ['HIGH_YIELD', 'This property has a high yield. It may be a good opportunity for buy-to-let.'],
    ['DEVELOPMENT', 'This property is marked as for development and may need significant work.'],
    ['STUDENT', 'Student property'],
    ['COMMERCIAL', 'Commercial property'],
    ['AUCTION', 'Auction property'],
  ]);
  private labelToDescription: Map<SaleSearchItemLabel, string> = new Map<SaleSearchItemLabel, string>([
    ['NEW', 'new'],
    ['HIGH_YIELD', 'yield'],
    ['DEVELOPMENT', 'development'],
    ['STUDENT', 'student'],
    ['COMMERCIAL', 'commercial'],
    ['AUCTION', 'auction'],
  ]);

  private destroyed$: Subject<any> = new Subject<any>();

  @Input()
  set label(value: SaleSearchItemLabel) {
    this.label$.next(value);
  }

  @Input()
  public detailed: boolean = false;

  constructor() {
    this.icon$ = this.label$.pipe(
        map(label => this.labelToIcon.get(label)),
        takeUntil(this.destroyed$)
    );
    this.tooltip$ = this.label$.pipe(
        map(label => this.labelToTooltip.get(label)),
        takeUntil(this.destroyed$)
    );
    this.description$ = this.label$.pipe(
        map(label => this.labelToDescription.get(label)),
        takeUntil(this.destroyed$)
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }


}
