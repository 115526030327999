import { Component, Input, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subject, takeUntil } from 'rxjs';
import { SaleListingDTO, ValueHistoryEntry } from '@dto';
import { ChartData } from 'chart.js';
import { map } from 'rxjs/operators';
import { annualRentToMonthlyHumanReadable, fractionToPercent, humanReadableSalePrice } from '@utils';

@Component({
  selector: 'jumbo-investment-report',
  templateUrl: './investment-report.component.html',
  styleUrls: ['./investment-report.component.scss']
})
export class InvestmentReportComponent implements OnDestroy {

  protected saleListing$: ReplaySubject<SaleListingDTO> = new ReplaySubject<SaleListingDTO>(1);
  private destroyed$: Subject<boolean> = new Subject<boolean>();

  protected priceHistoryOptions$: Observable<any>;
  protected priceHistoryData$: Observable<ChartData<"line">>;

  @Input() set saleListing(value: SaleListingDTO) {
    this.saleListing$.next(value);
  }

  constructor() {
    this.priceHistoryOptions$ = this.saleListing$.pipe(
        takeUntil(this.destroyed$),
        map(saleListing => this.getPriceHistoryChartOptions())
    );
    this.priceHistoryData$ = this.saleListing$.pipe(
        takeUntil(this.destroyed$),
        map(saleListing => this.getPriceHistoryChartData(saleListing.valueHistory))
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  getPriceHistoryChartOptions(): any {
    return {
      scales: {
        x: {
          type: 'time',
          time: {
            // Luxon format string
            tooltipFormat: 'DD T'
          },
        },
        y: {
          title: {
            display: true,
            text: 'Price (£)'
          }
        }
      }
    }
  }

  getPriceHistoryChartData(priceHistory: ValueHistoryEntry[]): any {
    return {
      datasets: [
        {
          data: priceHistory.map(x => {
            return {x: x.time, y: x.value}
          })
        }
      ]
    };
  }

  protected readonly annualRentToMonthlyHumanReadable = annualRentToMonthlyHumanReadable;
  protected readonly humanReadableSalePrice = humanReadableSalePrice;
  protected readonly fractionToPercent = fractionToPercent;
}
