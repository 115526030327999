import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { PropertyService } from '../../services/property.service';
import { FilterDTO, SaleSearchItemDTO, SearchDTO, SearchResultDTO } from '@dto';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  from,
  Observable,
  ReplaySubject,
  shareReplay,
  Subject,
  takeUntil,
  tap
} from 'rxjs';
import { DimensionNames, PropertySubTypeGeneralisation } from '@enum';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import {
  SearchFilterDropDownNumericRangeConfig,
  SearchFilterDropDownOption,
  SearchFilterItemVO,
  SearchFilterNumericSliderConfig,
  SearchFilterNumericSliderRangeConfig,
  SearchFilterRadioConfig,
  SearchFilterRadioOption,
  SearchFilterTextConfig
} from '../search-filter/search-filter-item/search-filter-item.vo';
import { logger } from '@logging';
import { fractionToPercent } from '@utils';
import { PageEvent } from '@angular/material/paginator';
import humanFormat from 'human-format';

type DisplayState = 'idle' | 'loading' | 'results' | 'empty' | 'error';
type SortType = 'priceAsc' | 'priceDesc' | 'yieldAsc' | 'yieldDesc' | 'ageAsc' | 'ageDesc';

@Component({
  selector: 'app-property-search',
  templateUrl: './property-search.component.html',
  styleUrls: ['./property-search.component.scss'],
  providers: [PropertyService]
})
export class PropertySearchComponent implements OnInit, AfterViewInit, OnDestroy {
  public static readonly FILTER_PARAM = 'filter';
  public static readonly SORT_PARAM = 'sort';

  public loadFilterSignal$: ReplaySubject<FilterDTO> = new ReplaySubject<FilterDTO>(1);
  public state$: Observable<DisplayState>;
  public page$: Observable<SearchResultDTO<SaleSearchItemDTO>>;
  public error$: ReplaySubject<string> = new ReplaySubject<string>();
  private isLoading: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  public results$: Observable<SaleSearchItemDTO[]>;

  private filterChangeSignal$: ReplaySubject<FilterDTO> = new ReplaySubject<FilterDTO>(1);

  private readonly sortTypeToOptions: Map<SortType, { dimensionName: DimensionNames, direction: 1 | -1 }>
    = new Map<SortType, { dimensionName: DimensionNames; direction: 1 | -1 }>(
      [
        ['priceAsc', {dimensionName: 'price', direction: 1}],
        ['priceDesc', {dimensionName: 'price', direction: -1}],
        ['yieldAsc', {dimensionName: 'estimatedYield', direction: 1}],
        ['yieldDesc', {dimensionName: 'estimatedYield', direction: -1}],
        ['ageAsc', {dimensionName: 'firstVisibleDate', direction: 1}],
        ['ageDesc', {dimensionName: 'firstVisibleDate', direction: -1}]
      ]
  )
  public readonly sortTypes: { value: SortType, label: string }[] = [
    {value: 'priceAsc', label: 'Price ↓'},
    {value: 'priceDesc', label: 'Price ↑'},
    {value: 'yieldAsc', label: 'Yield ↓'},
    {value: 'yieldDesc', label: 'Yield ↑'},
    {value: 'ageAsc', label: 'Newest'},
    {value: 'ageDesc', label: 'Oldest'},
  ];
  public readonly filterOptions: SearchFilterItemVO[];


  private _selectedSort: SortType = 'yieldAsc';
  public humanReadableResults$: Observable<string>;
  get selectedSort(): SortType {
    return this._selectedSort;
  }

  set selectedSort(value: SortType) {
    this._selectedSort = value;
    this.selectedSort$.next(value);
  }

  public selectedSort$: BehaviorSubject<SortType> = new BehaviorSubject<SortType>('yieldAsc');
  private pageIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private pageSize$: BehaviorSubject<number> = new BehaviorSubject<number>(10);
  private destroy$: Subject<any> = new Subject<any>();

  constructor(private readonly propertyService: PropertyService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly router: Router) {


    this.filterOptions = [
      // new SearchFilterItemVO(
      //     new SearchFilterDropDownConfig('bedrooms', 'eq', [
      //       new SearchFilterDropDownOption('1', 1),
      //       new SearchFilterDropDownOption('2', 2),
      //       new SearchFilterDropDownOption('3', 3),
      //     ]),
      //     'Bedrooms'
      // ),
      new SearchFilterItemVO(
          new SearchFilterTextConfig('Location', 'City, town or postal code', 'location', 'like'),
      ),
      new SearchFilterItemVO(
          new SearchFilterRadioConfig('Type', 'propertySubType', 'eq', [
            new SearchFilterRadioOption('all', null),
            new SearchFilterRadioOption('house', PropertySubTypeGeneralisation.HOUSE),
            new SearchFilterRadioOption('flat', PropertySubTypeGeneralisation.FLAT),
            new SearchFilterRadioOption('other', PropertySubTypeGeneralisation.OTHER)
          ])
      ),
      new SearchFilterItemVO(
          new SearchFilterNumericSliderRangeConfig('Bedrooms', 'bedrooms', 'range', 6, 0)
      ),
      new SearchFilterItemVO(
          new SearchFilterDropDownNumericRangeConfig('Price', 'price', 'range', [
            new SearchFilterDropDownOption('£10k', 10_000),
            new SearchFilterDropDownOption('£30k', 30_000),
            new SearchFilterDropDownOption('£50k', 50_000),
            new SearchFilterDropDownOption('£70k', 70_000),
            new SearchFilterDropDownOption('£100k', 100_000),
            new SearchFilterDropDownOption('£125k', 125_000),
            new SearchFilterDropDownOption('£150k', 150_000),
            new SearchFilterDropDownOption('£175k', 175_000),
            new SearchFilterDropDownOption('£200k', 200_000),
            new SearchFilterDropDownOption('£250k', 250_000),
            new SearchFilterDropDownOption('£300k', 300_000),
            new SearchFilterDropDownOption('£350k', 350_000),
            new SearchFilterDropDownOption('£400k', 400_000),
            new SearchFilterDropDownOption('£450k', 450_000),
            new SearchFilterDropDownOption('£500k', 500_000),
            new SearchFilterDropDownOption('£600k', 600_000),
            new SearchFilterDropDownOption('£700k', 700_000),
            new SearchFilterDropDownOption('£800k', 800_000),
            new SearchFilterDropDownOption('£900k', 900_000),
            new SearchFilterDropDownOption('£1M', 1_000_000),
            new SearchFilterDropDownOption('£1.5M', 1_500_000),
            new SearchFilterDropDownOption('£2M', 2_000_000),
            new SearchFilterDropDownOption('£3M', 3_000_000),
            new SearchFilterDropDownOption('£5M', 5_000_000),
            new SearchFilterDropDownOption('£10M', 10_000_000),
            new SearchFilterDropDownOption('£20M', 20_000_000),
          ], 'Min', 'Max')
      ),

      new SearchFilterItemVO(
          new SearchFilterNumericSliderConfig('Minimum Yield', 'estimatedYield', 'ge',
              0.15, 0.0, 0.01, 0, fractionToPercent),
      ),
      // new SearchFilterItemVO(
      //     new SearchFilterNumericSliderConfig('Maximum Price', 'expectedSalePrice', 'le',
      //         1300000, 100000, 20000, 10000, humanReadableSalePrice),
      // )
      // new SearchFilterItemVO(
      //     new SearchFilterNumericSliderConfig('Minimum Rent', 'estimatedLetPrice', 'ge',
      //         120000, 12000, 120, 120, annualRentToMonthlyHumanReadable),
      // )
    ];
  }

  formatResultsCount(value:number):string {
    return humanFormat(value);
  }

  ngAfterViewInit(): void {
    const newFilter$ = this.filterChangeSignal$.pipe(
        distinctUntilChanged((a, b) => FilterDTO.Serialise(a) === FilterDTO.Serialise(b)),
        shareReplay(),
        takeUntil(this.destroy$),
    )
    combineLatest([newFilter$, this.selectedSort$]).pipe(
        map(([filter, sort]) => {
          const params = {};
          if (filter && filter.restrictions.length >= 1) {
            params[PropertySearchComponent.FILTER_PARAM] = FilterDTO.Serialise(filter);
          }
          if (sort) {
            params[PropertySearchComponent.SORT_PARAM] = sort;
          }
          return params;
        }),
        takeUntil(this.destroy$)
    ).subscribe(params => this.router.navigate(
        [],
        {
          relativeTo: this.activatedRoute,
          queryParams: params,
          queryParamsHandling: '' // remove to replace all query params by provided
        })
    );
    // newFilter$.pipe(
    //     map(filter => {
    //       const params = filter.restrictions.length > 0 ? {filter: FilterDTO.Serialise(filter)} : {};
    //       logger.info(`Navigating to URL ${JSON.stringify(params)}`);
    //       this.router.navigate(
    //           [],
    //           {
    //             relativeTo: this.activatedRoute,
    //             queryParams: params,
    //             queryParamsHandling: '', // remove to replace all query params by provided
    //           });
    //     }),
    //     takeUntil(this.destroy$)
    // ).subscribe();

    this.page$ = combineLatest([newFilter$, this.pageIndex$, this.pageSize$, this.selectedSort$]).pipe(
        map(([filter, pageIndex, pageSize, sort]) => {
          const sortOptions = this.sortTypeToOptions.get(sort);
          return new SearchDTO<FilterDTO>(pageIndex, pageSize, filter, sortOptions.dimensionName, sortOptions.direction);
        }),
        tap(() => {
          this.isLoading.next(true);
        }),
        switchMap(search => from(this.propertyService.search(search))),
        tap(() => {
          this.isLoading.next(false);
        }),
        takeUntil(this.destroy$),
        shareReplay(1)
    );

    this.results$ = this.page$.pipe(
        map(page => page.results),
        takeUntil(this.destroy$)
    );
    this.results$.subscribe();

    this.state$ = combineLatest([this.isLoading, this.page$, this.error$]).pipe(
        map(([isLoading, page, error]): DisplayState => {
          if (isLoading) {
            return 'loading';
          }
          if (error) {
            return 'error';
          }
          if (page) {
            return page.results?.length > 0 ? 'results' : 'empty';
          }
          return 'idle';
        }),
        takeUntil(this.destroy$)
    );

    this.humanReadableResults$ = this.page$.pipe(
        map(page => page?humanFormat(page.maxResults, {maxDecimals: 0}):'0')
    );
  }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.pipe(
        takeUntil(this.destroy$),
        map(params => (params as ParamMap).get(PropertySearchComponent.FILTER_PARAM)),
        filter((filter) => filter != null),
        map(filterData => FilterDTO.Parse(filterData)),
        tap(filter => logger.info(`property-search loading filter ${FilterDTO.Serialise(filter)}`)),
        tap(filter => this.loadFilterSignal$.next(filter))
    ).subscribe();

    this.activatedRoute.queryParamMap.pipe(
        takeUntil(this.destroy$),
        map(params => (params as ParamMap).get(PropertySearchComponent.SORT_PARAM) as SortType),
        filter((sort) => sort != null),
        tap(sort => {
          this.selectedSort = sort;
          this.selectedSort$.next(sort)
        })
    ).subscribe();

    this.error$.next(null);
    this.isLoading.next(false);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  pageEvent(event: PageEvent) {
    this.pageSize$.next(event.pageSize);
    this.pageIndex$.next(event.pageIndex);
  }

  filterChange(filter: FilterDTO) {
    this.filterChangeSignal$.next(filter);
  }

}
