import { PropertySubType } from '@enum';
import { Point } from 'geojson';

export class EstateAgentPropertyReportRequestDTO {
  constructor(public readonly propertyId: string){
  }

}

export class EstateAgentGeoReportRequestDTO {
  constructor(public readonly point:Point,
              public readonly metadata:PropertyMetadataDTO) {

  }
}

export class SimilarPropertiesRequestDTO {
  constructor(public readonly point:[number, number],
              public readonly propertyMetadata:PropertyMetadataDTO,
              public readonly value: number) {
  }
}

export class PropertyMetadataDTO {
  constructor(public readonly bedrooms: number,
              public readonly propertySubType: PropertySubType,) {
  }
}
