<div class="flex flex-col w-full min-h-full pt-">
    <div class="w-full">
        <jumbo-heading ></jumbo-heading>
    </div>
<!--    -->
    <div class="flex w-full">
        <router-outlet></router-outlet>
    </div>

    <!-- footer-->
    <div class="flex w-full flex-col pt-16 pb-8 px-5" [ngClass]="[(appService.isDarkMode$|async)?'menu-dark':'menu-light']">
        <h1>Estate Stats</h1>
        <p>Estate stats is currently in BETA. The information provided on this page is presented as-is without any guarantee of accuracy or relevance.</p>

        <div class="text-xs select-none" [matTooltip]="'Current version number'"
             matTooltipPosition="below">Build {{version}}</div>
    </div>
</div>
