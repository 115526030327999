import { Component, Input, OnDestroy } from '@angular/core';
import { EstateAgentReportDTO } from '@dto';
import { ReplaySubject, Subject } from 'rxjs';
import { humanReadableSalePrice } from '@utils';
import { GaugeChartData } from '../charts/gague-chart/gauge-chart.component';

@Component({
  selector: 'jumbo-estate-agent-report',
  templateUrl: './estate-agent-report.component.html',
  styleUrls: ['./estate-agent-report.component.scss']
})
export class EstateAgentReportComponent implements OnDestroy {
  private destroyed$: Subject<any> = new Subject<any>();

  private _agentReport: EstateAgentReportDTO;

  public specialisationData$:ReplaySubject<GaugeChartData> = new ReplaySubject<GaugeChartData>(1) ;
  public agentHyperlink: string;

  public salePrice: string;

  @Input()
  public set agentReport(value: EstateAgentReportDTO) {
    this._agentReport = value;
    this.buildValues(value);
  }

  public get agentReport(): EstateAgentReportDTO {
    return this._agentReport;
  }


  constructor() {
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }


  private buildValues(value: EstateAgentReportDTO) {
    if (!value) {
      return;
    }
    this.specialisationData$.next(
        this.buildSpecialisationsData(value.similarListingsCount, value.currentListingsCount));
    this.agentHyperlink = this.getAgentHyperlink(value.agentName, value.branchName)
    this.salePrice = value.averageForSimilarListings?humanReadableSalePrice(value.averageForSimilarListings):'N/A'
  }

  private buildSpecialisationsData(similarListingsCount: number, sellCount: number):
      GaugeChartData {

    const differentCount = sellCount - similarListingsCount;
    const label = (similarListingsCount / sellCount * 100).toFixed(0) + '%';
    const data = [
      {key: 'Similar', value: similarListingsCount},
      {key: 'Different', value: differentCount}
    ];
    return {values: data, label: label};
  }


  private getAgentHyperlink(agentName: string, branchName: string): string {
    const regex = /[^a-zA-Z0-9\s]/g;
    const spaceRegex = /\s+/g;
    const sanitisedAgent = agentName.replace(regex, '');
    const sanitisedBranch = branchName.replace(regex, '');
    const query = `"${sanitisedAgent}" estate agent ${sanitisedBranch}`.replace(spaceRegex, '+');
    return `https://google.co.uk/search?q=${query}&btnI=I%27m+Feeling+Lucky`;
  }
}
