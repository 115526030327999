import { EnvironmentType, ReleaseType } from '@enum';

function getEnvironment(): EnvironmentType {
  return EnvironmentType.browser;
}

function getReleaseType(): ReleaseType {
  return ReleaseType.production;
}

export const environment = getEnvironment();
export const releaseType = getReleaseType();
