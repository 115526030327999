import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LocationDTO, PostCodeResult } from "@dto";
import { ValueComparisonCardVO } from "../value-comparison-card/value-comparison-card.component";
import { ValueChartVO } from "../value-chart/value-chart.component";
import { getPostCodeReportTypeValueFormatFunction, LocationType, PostCodeReportType } from "@enum";
import humanFormat from 'human-format';

@Component({
  selector: "app-area-search-area-widget",
  templateUrl: "./area-search-area-widget.component.html",
  styleUrls: ["./area-search-area-widget.component.scss"]
})
export class AreaSearchAreaWidgetComponent {

  @Input()
  public area: PostCodeResult;

  @Input()
  public rootArea: PostCodeResult;

  @Input()
  public humanReadableSearch: string;

  @Output()
  public viewProperties = new EventEmitter<LocationDTO>();

  @Output()
  public close = new EventEmitter<boolean>();

  constructor() {
  }

  navigateToProperties() {
    const location = new LocationDTO(this.area.areaName, this.area.regionName, LocationType.AREA_CODE, this.area.postCode);
    this.viewProperties.next(location);
  }

  toPercent(value: number) {
    return humanFormat(value * 100.0);
  }

  getValueComparison(area: PostCodeResult, rootArea: PostCodeResult,
                     title: string,
                     reportType:PostCodeReportType): ValueComparisonCardVO {
    const valueFormatFunction = getPostCodeReportTypeValueFormatFunction(reportType);
    const areaValue = area.values[reportType];
    const nationalValue = rootArea.values[reportType];
    const maxValue = Math.max(areaValue, nationalValue);
    return new ValueComparisonCardVO(
      title,
      `for ${this.humanReadableSearch}`,
      [
        new ValueChartVO(`${area.postCode} (${area.areaName})`,
          valueFormatFunction(areaValue),
          areaValue/maxValue,
          0),
        new ValueChartVO('National',
          valueFormatFunction(nationalValue),
          nationalValue/maxValue,
          1)
      ]
    );
  }

  closeView() {
    this.close.next(true);
  }


}


