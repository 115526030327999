<div class="flex flex-col w-full items-center h-full min-h-[60rem]">

  <div class="flex w-full h-[40rem] items-center justify-center"
       [class]="(isDarkMode$|async)?'banner-dark':'banner-light'">

    <div class="w-[50rem] h-[18rem] relative">
      <div class="w-full h-full absolute"
           [class]="(isDarkMode$|async)?'banner-blurred-dark':'banner-blurred-light'"></div>
      <div class="w-full h-full absolute"
           [class]="(isDarkMode$|async)?'banner-transparent-dark':'banner-transparent-light'"></div>
      <div class="p-12 gap-5 flex flex-col w-full h-full absolute">

        <h2>Find the best UK investment properties</h2>

        <div class="flex flex-row gap-4 w-full align-middle justify-center h-[6rem]">
          <div class="flex-col">
            <div class="min-h-[2rem]">Investment type</div>
            <mat-button-toggle-group class="min-h-[3.55rem]">
              <mat-button-toggle class="px-2 pt-1">Flip</mat-button-toggle>
              <mat-button-toggle class="px-2 pt-1">Buy to let</mat-button-toggle>
            </mat-button-toggle-group>
          </div>

          <div class="flex-col grow">
            <div class="min-h-[2rem]">Location</div>
            <jumbo-search-filter-text-item
                    class="grow"
                    (restrictionChange)="handleLocationSelected($event)"
                    [config]="locationConfig">
            </jumbo-search-filter-text-item>
          </div>
          <div class="flex-col">
            <div class="min-h-[2rem]"></div>
            <button mat-button (click)="handleSearchProperties()"
                    [disabled]="!(isFilterValid$|async)"
                    mat-raised-button
                    color="primary"
                    class="text-left px-8 min-h-[3.55rem]"
                    matTooltip="Value any property"
                    matTooltipPosition="below">
              <mat-icon>attach_money</mat-icon>
              <span>Search</span>
            </button>
          </div>
        </div>
        <div class="grow"></div>
        <div class="flex flex-row justify-center w-full">
          <button (click)="navigateToMaps()">
            <div class="flex flex-row justify-center">
              <mat-icon>map</mat-icon>
              <div class="place-self-center pl-1">Explore investment areas</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="pt-10 w-[72rem] flex flex-col align-middle">
    <h2>Investment Opportunities</h2>
    <p>Our AI uses multiple data points to identify properties with high return potentials.</p>
    <div class="flex flex-row gap-10 shrink pt-5 w-full justify-between"
         *ngIf="(hasExamples$|async)">
      <div *ngFor="let item of (examples$|async).results"
           class="flex">
        <app-property-search-card [item]="item"></app-property-search-card>

      </div>
    </div>
    <div class="pt-10 justify-center flex">
      <button mat-button (click)="handleViewNewListings()"
              mat-raised-button
              color="primary"
              class="text-left px-7"
              matTooltip="Value any property"
              matTooltipPosition="below">
        View all new listings
      </button>
    </div>
  </div>


  <div class="py-20 w-[64rem] flex flex-col align-middle gap-10">
    <h1>Purchase, management and sale of rental or real estate properties for profit.</h1>
    <div class="w-full flex flex-row text-lg gap-10 align-middle">
      <div class="w-2/3">
        Estate Stats identifies real-estate objects in the UK with the highest forecasted net
        operating rental income or house flipping uplift. Multiple unique data points of real
        estate from multiple independent government and non-government data sources are
        analysed daily. Our machine-learning algorithms analyse and advise you on potential
        upgrades that will lead to a higher ROI. Reduce risks and avoid the pitfalls that
        novice flippers overlook: Back up your investment with Estate Stats insights.
      </div>
      <div class="flex flex-col w=1/3 gap-5">
        <div class="flex-row flex gap-5">
          <div class="material-icons text-4xl">check</div>
          <div>Daily updated real-estate KPIs for rental management & house flipping</div>
        </div>
        <div class="flex-row flex  gap-5">
          <div class="material-icons text-4xl">check</div>
          <div>Multiple data points on open and proprietary data sources</div>
        </div>
        <div class="flex-row flex gap-5">
          <div class="material-icons text-4xl">check</div>
          <div>Machine learning algorithms identify market opportunities</div>
        </div>
      </div>
    </div>
  </div>

  <!--  <mat-tab-group class="w-full h-full flex">-->
  <!--    -->
  <!--    <mat-tab label="Announcements" class="h-full">-->
  <!--      &lt;!&ndash; announcements dark &ndash;&gt;-->
  <!--      <jumbo-frill-widget *ngIf="isDarkMode$|async" class="h-full"-->
  <!--                          widgetId="133b029d-b69a-4e92-8bcf-2593019c939f"></jumbo-frill-widget>-->
  <!--      &lt;!&ndash; announcements light &ndash;&gt;-->
  <!--      <jumbo-frill-widget *ngIf="!(isDarkMode$|async)" class="h-full"-->
  <!--                          widgetId="feefab9b-76e6-4abe-bf86-be0172de1bd3"></jumbo-frill-widget>-->
  <!--    </mat-tab>-->

  <!--    <mat-tab label="Ideas" class="h-full">-->
  <!--      &lt;!&ndash; Ideas dark &ndash;&gt;-->
  <!--      <jumbo-frill-widget *ngIf="isDarkMode$|async" class="h-full"-->
  <!--                          widgetId="b6c0009a-9188-45e5-b572-9673150f7262"></jumbo-frill-widget>-->
  <!--      &lt;!&ndash; Ideas light &ndash;&gt;-->
  <!--      <jumbo-frill-widget *ngIf="!(isDarkMode$|async)" class="h-full"-->
  <!--                          widgetId="faf1da01-facd-4f6b-b099-93ecff242e05"></jumbo-frill-widget>-->
  <!--    </mat-tab>-->

  <!--    <mat-tab label="Roadmap" class="h-full">-->
  <!--      &lt;!&ndash; Roadmap dark &ndash;&gt;-->
  <!--      <jumbo-frill-widget *ngIf="isDarkMode$|async" class="h-full"-->
  <!--                          widgetId="3ad0bf16-c8cf-4b36-a787-e7088dceea61"></jumbo-frill-widget>-->
  <!--      &lt;!&ndash; Roadmap light &ndash;&gt;-->
  <!--      <jumbo-frill-widget *ngIf="!(isDarkMode$|async)" class="h-full"-->
  <!--                          widgetId="47ce694e-ec36-4244-a888-f680c20724c6"></jumbo-frill-widget>-->
  <!--    </mat-tab>-->

  <!--  </mat-tab-group>-->
  <!--    <div *ngIf="!(isAuthenticated$|async)" class="pt-10 flex flex-col items-center">-->
  <!--        <img src="assets/logo/landscape-light.svg" class="logo-center w-64"/>-->
  <!--        <div class="pt-10">-->
  <!--            <h1>You must be authenticated to access this page</h1>-->
  <!--            <p>Access to this page is restricted to account holders. Login to continue.</p>-->
  <!--            <button mat-button (click)="login()"-->
  <!--                    class="text-left px-3"-->
  <!--                    matTooltip="Login to Estate Stats">-->
  <!--                <mat-icon>login</mat-icon>-->
  <!--                <span>Login</span>-->
  <!--            </button>-->
  <!--        </div>-->
  <!--    </div>-->
</div>
