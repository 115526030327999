<mat-form-field>
  <mat-label>{{config.label}}</mat-label>
  <mat-select #dropDown

              [(ngModel)]="selectedItem"
              (selectionChange)="selectionChange()">
    <mat-option *ngFor="let option of config.options"
                [value]="option.itemValue">{{option.itemLabel}}</mat-option>
  </mat-select>
</mat-form-field>
