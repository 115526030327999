import { HttpClient } from '@angular/common/http';
import { ConfigurationService } from './configuration.service';
import { Injectable } from '@angular/core';
import {
  CandidateEstateAgentReportDTO,
  EstateAgentGeoReportRequestDTO,
  EstateAgentListingReportDTO,
  EstateAgentPropertyReportRequestDTO
} from '@dto';
import { ConsoleApiEndpoints } from '@enum';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AgentService {
  constructor(private readonly http: HttpClient,
              private readonly config:ConfigurationService) {
  }

  async getAgentReportFromPropertyId(agentReq: EstateAgentPropertyReportRequestDTO): Promise<EstateAgentListingReportDTO> {
    const url = this.config.getAPIURL(ConsoleApiEndpoints.estate_agent_report);
    const req = this.http.post<EstateAgentListingReportDTO>(url, agentReq, {withCredentials: true});
    return await firstValueFrom(req);
  }

  async getAgentReportFromGeo(agentReq: EstateAgentGeoReportRequestDTO): Promise<CandidateEstateAgentReportDTO> {
    const url = this.config.getAPIURL(ConsoleApiEndpoints.estate_agents_by_geo);
    const req = this.http.post<CandidateEstateAgentReportDTO>(url, agentReq, {withCredentials: true});
    return await firstValueFrom(req);
  }
}
