import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { from, Observable, ReplaySubject, shareReplay, Subject, takeUntil } from 'rxjs';
import { ConsolePageType } from '@enum';
import { ConfigurationService } from '../../services/configuration.service';
import { Router } from '@angular/router';
import { SearchFilterTextConfig } from '../search-filter/search-filter-item/search-filter-item.vo';
import { DimensionRestrictionDTO, FilterDTO, SaleSearchItemDTO, SearchResultDTO } from '@dto';
import { PropertyService } from '../../services/property.service';
import { AuthService } from '../../services/auth.service';
import { map, take } from 'rxjs/operators';


@Component({
  selector: 'jumbo-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {

  public isDarkMode$: Observable<boolean>;
  public readonly locationConfig = new SearchFilterTextConfig('Location', 'City, town or postal code', 'location', 'like')
  public examples$: Observable<SearchResultDTO<SaleSearchItemDTO>>;
  public hasExamples$: Observable<boolean>;
  public isFilterValid$: Observable<boolean>;
  private destroy$: Subject<any> = new Subject<any>();

  private currentFilter: ReplaySubject<FilterDTO> = new ReplaySubject<FilterDTO>();

  constructor(public readonly authService: AuthService,
              private readonly appService: AppService,
              private readonly router: Router,
              private readonly config: ConfigurationService,
              private readonly propertyService: PropertyService) {
  }

  ngOnInit(): void {
    this.examples$ = from(this.propertyService.getHot())
        .pipe(
            takeUntil(this.destroy$),
            shareReplay(1)
        );
    this.hasExamples$ = this.examples$.pipe(
        takeUntil(this.destroy$),
        map(examples => examples && examples.results && examples.results.length > 0)
    );
    this.isDarkMode$ = this.appService.isDarkMode$.pipe(takeUntil(this.destroy$));
    this.isFilterValid$ = this.currentFilter.pipe(
        takeUntil(this.destroy$),
        map(filter => FilterDTO.IsPopulated(filter))
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }

  login() {
    const path = this.config.getClientPath(ConsolePageType.LOGIN);
    this.router.navigate([path]);
  }

  handleLocationSelected($event: DimensionRestrictionDTO) {
    this.currentFilter.next(new FilterDTO([$event]));
  }

  handleSearchProperties() {
    this.currentFilter.pipe(
        take(1)
    ).subscribe(filter => {
      const serialisedFilter = FilterDTO.Serialise(filter);
      this.router.navigate(
          ['property', 'search'],
          {
            queryParams: {filter: serialisedFilter},
            queryParamsHandling: '', // remove to replace all query params by provided
          });
    })
  }

  handleViewNewListings() {
    const path = this.config.getClientPath(ConsolePageType.PROPERTY_SEARCH);
    this.router.navigate([path]);
  }

  navigateToMaps() {
    const path = this.config.getClientPath(ConsolePageType.AREA_SEARCH);
    this.router.navigate([path]);
  }
}
