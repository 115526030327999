import { PropertySubType } from '@enum';

export class PredictionRequestDTO {
  constructor(public readonly postCode: string,
              public readonly propertySubType: PropertySubType,
              public readonly bedrooms: number,
              public readonly bathrooms?: number,
              public readonly squareFt?: number,
              public readonly students?: boolean) {
  }

}
